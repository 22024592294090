import React from 'react';
import lodash from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    useMediaQuery,
    ListItem,
    IconButton,
    Theme,
} from '@material-ui/core';
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon, Refresh as RefreshIcon } from '@material-ui/icons';
import { formatUSD, formatDate } from '../../helpers';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    drawer: {
        maxWidth: '91vw',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    invalidRow: {
        backgroundColor: '#ef5350',
        color: 'white',
    },
    groupHeaderStart: {
        borderRight: '1.5px solid rgba(224, 224, 224, 1)',
        fontWeight: '600',
    },
    groupHeader: {
        fontWeight: '600',
    },
    expandButtonRow: { textAlign: 'end', display: 'block' },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        marginLeft: 'auto',
        transform: 'rotate(180deg)',
    },
});

// @ts-ignore
const useStyles = makeStyles<Theme>(styles);

const formatToNumber = (value, name, formatToMoney) => {
    // 1. Return if not number
    if ('string' === typeof value) {
        return value;
    }

    // 2. If currency, format as USD
    for (let i = 0; i < formatToMoney.length; i++) {
        if (name === formatToMoney[i]) {
            return formatUSD.format(value);
        }
    }

    // 3. If not, format as number.
    return Intl.NumberFormat().format(value);
};

const FormattedValue: ({
    colValue,
    colName,
    recordLine,
    openPreviewDrawer,
    ...props
}: {
    [x: string]: any;
    colValue?: any;
    colName?: any;
    recordLine?: any;
    openPreviewDrawer?: any;
}) => any = ({ colValue, colName, recordLine, openPreviewDrawer, ...props }) => {
    const { formatToDate, formatToCustom, formatToMoney, formatToDrawerLink } = props;
    const drawerData = lodash.find(formatToDrawerLink, item => item.name === colName);
    const dateData = lodash.find(formatToDate, item => item.name === colName);
    const customData = lodash.find(formatToCustom, item => item.name === colName);

    if ('undefined' === typeof colValue || null === colValue) {
        return '';
    }

    if (drawerData && openPreviewDrawer && 'function' === typeof openPreviewDrawer) {
        const { name, resource } = drawerData;
        const displayField = drawerData.displayField || drawerData.name;
        const idField = recordLine[drawerData.idField] || recordLine[name];
        return openPreviewDrawer(idField, resource, recordLine[displayField]);
    }
    if (dateData) {
        return formatDate(colValue, dateData.format);
    }
    if (customData) {
        return customData.formatter(recordLine);
    }

    return formatToNumber(colValue, colName, formatToMoney);
};

const RenderColumnHeaders: ({
    align,
    columns,
    hasActions,
    hasRemove,
    handleRemoveClick,
    excludeColByTitle,
    canReadRelationFields,
}: {
    align?: any;
    columns?: any;
    hasActions?: any;
    hasRemove?: any;
    handleRemoveClick?: any;
    excludeColByTitle?: any;
    canReadRelationFields?: any;
}) => JSX.Element = ({
    align,
    columns,
    hasActions,
    hasRemove,
    handleRemoveClick,
    excludeColByTitle,
    canReadRelationFields,
}) => {
    const filteredColumnNames = [];
    Object.entries(columns).forEach(([colVal, colTitle]) => {
        if (
            (!excludeColByTitle || !excludeColByTitle.includes(colTitle)) &&
            ((canReadRelationFields && canReadRelationFields.includes(colVal)) || !canReadRelationFields)
        ) {
            filteredColumnNames.push(colTitle);
        }
    });

    return (
        <>
            {hasRemove && handleRemoveClick && 'function' === typeof handleRemoveClick && (
                <TableCell key="remove" align={align} padding="checkbox" />
            )}
            {filteredColumnNames.map(columnName => (
                <TableCell key={columnName} align={align}>
                    {columnName}
                </TableCell>
            ))}
            {hasActions && <TableCell key="actions" align={align} />}
        </>
    );
};

const RenderColumnGroupHeaders = ({ align, columnGroups, classes }) => (
    <TableRow>
        {Object.keys(columnGroups).map((group, i) => {
            if (0 === i) {
                return (
                    <TableCell
                        colSpan={columnGroups[group]}
                        key={group}
                        align={align}
                        className={classes.groupHeaderStart}
                    >
                        {group}
                    </TableCell>
                );
            }
            return (
                <TableCell colSpan={columnGroups[group]} key={group} align={align} className={classes.groupHeader}>
                    {group}
                </TableCell>
            );
        })}
    </TableRow>
);

const RenderActions: ({
    hasActions,
    recordLine,
    lineIndex,
    children,
    ...props
}: {
    [x: string]: any;
    hasActions: any;
    recordLine: any;
    lineIndex: any;
    children?: any;
}) => JSX.Element = ({ hasActions, recordLine, lineIndex, children, ...props }) => {
    if (false === hasActions) {
        return null;
    }

    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, {
            record: recordLine,
            index: lineIndex,
        })
    );

    return <TableCell key={`${recordLine.id}_customAction`}>{childrenWithProps}</TableCell>;
};

const RenderCells: ({
    align,
    filter,
    columns,
    hasActions,
    hasRemove,
    handleRemoveClick,
    data,
    visibleRecords,
    classes,
    excludeColByTitle,
    canReadRelationFields,
    ...props
}: {
    [x: string]: any;
    align?: any;
    filter?: any;
    columns?: any;
    hasActions?: any;
    hasRemove?: any;
    handleRemoveClick?: any;
    data?: any;
    visibleRecords?: any;
    classes?: any;
    excludeColByTitle?: any;
    canReadRelationFields?: any;
}) => any = ({
    align,
    filter,
    columns,
    hasActions,
    hasRemove,
    handleRemoveClick,
    data,
    visibleRecords,
    classes,
    excludeColByTitle,
    canReadRelationFields,
    ...props
}) => {
    const setClassConditional = recordLine => (recordLine.invalid ? classes.invalidRow : null);
    let dataToRender = filter ? filter(data) : data;

    if (false === Array.isArray(data)) {
        dataToRender = [data];
    }

    const filteredColumns = {};
    Object.entries(columns).forEach(([colVal, colTitle]) => {
        if (
            (!excludeColByTitle || !excludeColByTitle.includes(colTitle)) &&
            ((canReadRelationFields && canReadRelationFields.includes(colVal)) || !canReadRelationFields)
        ) {
            filteredColumns[colVal] = colTitle;
        }
    });

    return dataToRender.map((recordLine, lineIndex) => {
        if (visibleRecords && lineIndex + 1 > visibleRecords) {
            return null;
        }
        return (
            <TableRow
                key={`${recordLine.id}-${lineIndex}`}
                className={setClassConditional(recordLine)}
                data-cy="dialogTableRow"
            >
                {hasRemove && handleRemoveClick && 'function' === typeof handleRemoveClick && (
                    <TableCell
                        key={`${recordLine.id}_closeAction`}
                        padding="checkbox"
                        align={align}
                        className={setClassConditional(recordLine)}
                    >
                        <IconButton
                            color="secondary"
                            size="small"
                            disabled={dataToRender && 1 === dataToRender.length}
                            onClick={() => handleRemoveClick(recordLine, lineIndex)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </TableCell>
                )}
                {Object.keys(filteredColumns).map((columnName, i) => {
                    if (0 === i) {
                        return (
                            <TableCell
                                key={recordLine.id + columnName}
                                component="th"
                                scope="row"
                                align={align}
                                className={setClassConditional(recordLine)}
                            >
                                <FormattedValue
                                    recordLine={recordLine}
                                    colValue={recordLine[columnName]}
                                    colName={columnName}
                                    {...props}
                                />
                            </TableCell>
                        );
                    }

                    return (
                        <TableCell
                            key={recordLine.id + columnName}
                            align="right"
                            className={setClassConditional(recordLine)}
                        >
                            <FormattedValue
                                recordLine={recordLine}
                                colValue={recordLine[columnName]}
                                colName={columnName}
                                {...props}
                            />
                        </TableCell>
                    );
                })}
                <RenderActions recordLine={recordLine} lineIndex={lineIndex} hasActions={hasActions} {...props} />
            </TableRow>
        );
    });
};

export const ExpandButtonRow: ({
    fullyClosed,
    fullyExpanded,
    onExpandClick,
    onCollapseClick,
}: {
    fullyClosed?: any;
    fullyExpanded?: any;
    onExpandClick?: any;
    onCollapseClick?: any;
}) => JSX.Element = ({ fullyClosed, fullyExpanded, onExpandClick, onCollapseClick }) => {
    const classes = useStyles();
    return (
        <ListItem className={classes.expandButtonRow}>
            <IconButton
                disabled={fullyClosed || !(onCollapseClick && 'function' === typeof onCollapseClick)}
                onClick={() => onCollapseClick(true)}
                aria-label="refresh"
            >
                <RefreshIcon />
            </IconButton>
            <IconButton
                className={classes.expandOpen}
                disabled={fullyClosed || !(onCollapseClick && 'function' === typeof onCollapseClick)}
                onClick={() => onCollapseClick()}
                aria-label="show less"
            >
                <ExpandMoreIcon />
            </IconButton>
            <IconButton
                className={classes.expand}
                disabled={fullyExpanded || !(onExpandClick && 'function' === typeof onExpandClick)}
                onClick={() => onExpandClick()}
                aria-label="show more"
            >
                <ExpandMoreIcon />
            </IconButton>
        </ListItem>
    );
};

const DialogTableBase: ({
    columnGroups,
    hasActions,
    hasRemove,
    isDrawer,
    hasExpandButton,
    formatToMoney,
    formatToDate,
    size,
    align,
    ...props
}: {
    [x: string]: any;
    columnGroups?: any;
    hasActions?: boolean;
    hasRemove?: boolean;
    isDrawer?: boolean;
    hasExpandButton?: boolean;
    formatToMoney?: string[];
    formatToDate?: {
        name: string;
        format: string;
    }[];
    size?: any;
    align?: string;
}) => JSX.Element = ({
    columnGroups,
    hasActions = false,
    hasRemove = false,
    isDrawer = false,
    hasExpandButton = false,
    formatToMoney = ['rate', 'cost', 'amount', 'cost_rate'],
    formatToDate = [
        { name: 'created_at', format: 'date-time' },
        { name: 'updated_at', format: 'date-time' },
    ],
    size = 'medium',
    align = 'right',
    ...props
}) => {
    const classes = useStyles();
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
    return (
        <Paper className={true === isDrawer || isSmall ? classes.drawer : classes.root} data-cy="dialogTable">
            <Table size={size}>
                <TableHead>
                    {columnGroups && (
                        <RenderColumnGroupHeaders
                            align={align}
                            classes={classes}
                            columnGroups={columnGroups}
                            {...props}
                        />
                    )}
                    <TableRow>
                        <RenderColumnHeaders align={align} hasActions={hasActions} hasRemove={hasRemove} {...props} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    <RenderCells
                        align={align}
                        classes={classes}
                        formatToMoney={formatToMoney}
                        formatToDate={formatToDate}
                        hasActions={hasActions}
                        hasRemove={hasRemove}
                        {...props}
                    />
                </TableBody>
            </Table>
            {hasExpandButton && <ExpandButtonRow {...props} />}
        </Paper>
    );
};

export default DialogTableBase;
