import React from 'react';
import moment, { Moment } from 'moment';
import Cookies from 'universal-cookie';

const useGetSessionExpires = (shouldAdvanceTime = true) => {
    const [sessionExpires, setSessionExpires] = React.useState<Moment>(moment().local().add(60, 'minutes'));
    const [currentTime, setCurrentTime] = React.useState<Moment>(moment().local());
    const [warningTime, setWarningTime] = React.useState<Moment>(moment().local().add(55, 'minutes'));
    const countdown = moment(moment(sessionExpires).diff(moment(currentTime), 'seconds') * 1000).format('m:ss');
    const isMountedRef = React.useRef(false);

    const parsedAPIName =
        process.env.REACT_APP_API_NAME && process.env.REACT_APP_API_NAME.replace(/-/g, '_').toLowerCase();

    const cookies = new Cookies();
    const laravelSessionExpires = cookies.get(`${parsedAPIName}_session_expires`);

    React.useMemo(() => {
        isMountedRef.current = true;

        if (laravelSessionExpires && '0' !== laravelSessionExpires && isMountedRef.current) {
            const localizedExpires = moment(laravelSessionExpires).local().subtract(1, 'minute');
            const warningFromExpires = moment(localizedExpires).local().subtract(5, 'minutes');
            setSessionExpires(localizedExpires);
            setWarningTime(warningFromExpires);
        }

        return () => {
            isMountedRef.current = false;
        };
    }, [laravelSessionExpires]);

    React.useEffect(() => {
        if (currentTime.isAfter(sessionExpires) || !shouldAdvanceTime) {
            return;
        }

        const advanceCurrentTime = setTimeout(() => setCurrentTime(moment().local()), 1000);

        return () => clearInterval(advanceCurrentTime);
    }, [currentTime, sessionExpires, shouldAdvanceTime]);

    return { sessionExpires, currentTime, warningTime, countdown };
};

export default useGetSessionExpires;
