import React from 'react';
import { Layout } from 'react-admin';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import AppBar from './AppBar';
import { MainMenu, ErrorPage } from './index';
import { darkTheme, lightTheme } from './themes';

const setTheme = (name, props) => {
    switch (name) {
        case 'dark':
            return darkTheme(props);
        case 'light':
        default:
            return lightTheme(props);
    }
};

const CustomLayout = props => {
    const theme = useTheme();
    const selectedTheme = useSelector((state: any) => setTheme(state.theme, theme));

    return <Layout {...props} appBar={AppBar} menu={MainMenu} theme={selectedTheme} error={ErrorPage} />;
};

export default CustomLayout;
