import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SimpleFormIterator } from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '1.5em',
        display: 'flex',
        flexWrap: 'wrap',
    },
    line: {
        '&.fade-enter': {
            opacity: 0.01,
            transform: 'translateY(100vh)',
        },
        '&.fade-enter-active': {
            opacity: 1,
            transform: 'translateY(0)',
            transition: 'all 500ms ease-in',
        },
        '&.fade-exit': {
            opacity: 1,
            transform: 'translateY(0)',
        },
        '&.fade-exit-active': {
            opacity: 0.01,
            transform: 'translateY(100vh)',
            transition: 'all 500ms ease-in',
        },
    },
    index: {
        width: '3em',
        paddingTop: '1em',
        [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '52.5vw',
        '& div': {
            marginRight: '2.5px',
            width: '50vw',
        },
        [theme.breakpoints.down('xs')]: { display: 'block' },
    },
    action: {
        paddingTop: '0.5em',
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
}));

const CustomSimpleFormIterator = props => {
    const classes = useStyles();
    return <SimpleFormIterator classes={classes} {...props} />;
};

export default CustomSimpleFormIterator;
