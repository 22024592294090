import React, { useCallback, useEffect, useState, useRef, memo } from 'react';
import moment from 'moment';
import { AppBar, useNotify, useRedirect } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useGetSessionExpires, usePusherEvent } from '../../Components/lib/hooks';
import CustomUserMenu from './UserMenu';
import NotificationBell from './NotificationBell';
import { CustomConfirm as Confirm } from '../../Components/lib/customComponents';
import authProvider, { setLocalStorage } from '../../authProvider';
import { requestGetCurrentUser } from '../../dataProvider/RestClient';

const useStyles = makeStyles({
    userName: {
        padding: '12px 16px',
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    test: {
        flex: 'initial',
    },
});

const CustomAppBar = memo(props => {
    const { sessionExpires, currentTime, warningTime, countdown } = useGetSessionExpires();

    const [showRefreshModal, setShowRefreshModal] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const isMountedRef = useRef(false);

    const userData = React.useMemo(() => JSON.parse(localStorage.getItem('user') || '{}'), []);

    const classes = useStyles();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleRefreshSession = useCallback(() => {
        setIsRefreshing(true);
        requestGetCurrentUser()
            .then(res => {
                setIsRefreshing(false);
                setShowRefreshModal(false);
                setLocalStorage({ user: res });
            })
            .catch(err => {
                setShowRefreshModal(false);
                setIsRefreshing(false);
                notify('No current user found', { type: 'warning' });
                redirect('/login');
            });
    }, [notify, redirect]);

    const handleLogout = useCallback(() => {
        if (true === isMountedRef.current) {
            setShowRefreshModal(false);
            authProvider.logout();
            redirect('/login');
        }
    }, [redirect]);

    const sessionParser = React.useCallback(
        ({ user_id: userID, action }) => {
            if (userID && userData && userData.id === userID) {
                if ('logout' === action) {
                    localStorage.clear();
                    redirect('/login');
                } else if ('refresh' === action) {
                    console.log('[Refresh Action]: ', action, userID);
                    window.location.reload();
                }
            }
        },
        [redirect, userData]
    );

    usePusherEvent(`api.session-changed.users.${userData.id}`, 'SessionChanged', sessionParser);

    useEffect(() => {
        if (currentTime.isAfter(warningTime)) {
            setShowRefreshModal(true);
        } else if (currentTime.isBefore(warningTime) && setShowRefreshModal) {
            setShowRefreshModal(false);
        }
    }, [currentTime, sessionExpires, warningTime]);

    useEffect(() => {
        isMountedRef.current = true;

        if (
            currentTime &&
            sessionExpires &&
            currentTime.isAfter(moment(sessionExpires).local()) &&
            true === isMountedRef.current
        ) {
            authProvider.logout();
            redirect('/login');
        }

        return () => {
            isMountedRef.current = false;
        };
    }, [currentTime, notify, redirect, sessionExpires]);

    return (
        <>
            <Confirm
                isOpen={showRefreshModal}
                title="Session expires soon"
                content={`You will be automatically logged out in ${countdown}, would you like to continue your session?`}
                confirmText="Continue Session"
                cancelText="Logout"
                onConfirm={handleRefreshSession}
                onClose={handleLogout}
                loading={isRefreshing}
            />
            <AppBar {...props} userMenu={<CustomUserMenu classes={classes} />}>
                <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
                {'test' === process.env.APP_ENV && (
                    <Typography variant="caption" color="inherit" className={classes.test} id="react-admin-title">
                        Frac Sand Test
                    </Typography>
                )}
                <NotificationBell />
            </AppBar>
        </>
    );
});

export default CustomAppBar;
