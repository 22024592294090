import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    useMediaQuery,
    Theme,
} from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { CheckCircle as ActionCheck, ErrorOutline as AlertError } from '@material-ui/icons';
import classnames from 'classnames';

const styles = theme => ({
    confirmPrimary: {
        color: theme.palette.primary.main,
    },
    confirmWarning: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    iconPaddingStyle: {
        paddingRight: '0.5em',
    },
    dialogContainer: ({ isSmall, fullWidth, loading }) => ({
        minWidth: '25vw',
        width: fullWidth && !loading ? '100vw' : 'auto',
    }),
});

const useStyles = makeStyles(styles);

const Confirm: ({
    simpleMessage,
    hasConfirm,
    isOpen,
    title,
    content,
    confirmColor,
    onConfirm,
    onClose,
    error,
    errorText,
    children,
    loading,
    customButtons,
    fullWidth,
    confirmText,
    cancelText,
}: {
    simpleMessage?: boolean;
    hasConfirm?: boolean;
    isOpen?: boolean;
    title?: any;
    content?: any;
    confirmColor?: string;
    onConfirm?: any;
    onClose?: any;
    error?: boolean;
    errorText?: string;
    children?: any;
    loading?: boolean;
    customButtons?: any;
    fullWidth?: boolean;
    confirmText?: string;
    cancelText?: string;
}) => JSX.Element = ({
    simpleMessage = false,
    hasConfirm = true,
    isOpen = false,
    title,
    content,
    confirmColor = 'primary',
    onConfirm,
    onClose,
    error = false,
    errorText = 'Records in red will not be sent',
    children,
    loading = false,
    customButtons = null,
    fullWidth = false,
    confirmText = 'confirm',
    cancelText = 'cancel',
}) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    const classes = useStyles({ isSmall, fullWidth, loading });

    return (
        <Dialog
            open={isOpen}
            onClose={(event, reason) => {
                if ('backdropClick' !== reason) {
                    onClose(event, reason);
                }
            }}
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            classes={{
                paperWidthMd: classes.dialogContainer,
            }}
            disableEscapeKeyDown
        >
            <DialogTitle id="alert-dialog-title">{loading ? 'Loading' : title}</DialogTitle>
            <DialogContent>
                {content && (
                    <DialogContentText component="span">
                        {loading ? <CircularProgress size={50} style={{ marginLeft: '45%' }} /> : content}
                    </DialogContentText>
                )}
                {error && !loading && (
                    <DialogContentText color="error" variant="caption" data-cy="errorText">
                        {errorText}
                    </DialogContentText>
                )}
                {!loading && children}
            </DialogContent>
            {!loading && (
                <DialogActions>
                    {customButtons && React.cloneElement(customButtons)}
                    {false === simpleMessage && (
                        <Button onClick={onClose} disabled={loading} data-cy="cancel">
                            <AlertError className={classes.iconPaddingStyle} />
                            {!isSmall && cancelText}
                        </Button>
                    )}
                    {true === hasConfirm && (
                        <Button
                            disabled={error || loading}
                            onClick={onConfirm}
                            className={classnames('ra-confirm', {
                                [classes.confirmWarning]: 'warning' === confirmColor || true === error,
                                [classes.confirmPrimary]: 'primary' === confirmColor,
                            })}
                            autoFocus
                            data-cy="confirm"
                        >
                            {'warning' === confirmColor ? (
                                <AlertError className={classes.iconPaddingStyle} />
                            ) : (
                                <ActionCheck className={classes.iconPaddingStyle} />
                            )}
                            {!isSmall && confirmText}
                        </Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default Confirm;
