export const localDevPermissions = {
    reports: {
        can_create: true,
        can_read: true,
        can_update: true,
        can_delete: false,
        can_export: true,
        can_read_fields: null,
    },
    attachments: {
        can_create: true,
        can_read: true,
        can_update: true,
        can_delete: false,
        can_export: true,
        can_read_fields: null,
    },
    sites: {
        can_create: true,
        can_read: true,
        can_update: true,
        can_delete: false,
        can_export: true,
        can_read_fields: null,
    },
    plants: {
        can_create: true,
        can_read: true,
        can_update: true,
        can_delete: false,
        can_export: true,
        can_read_fields: null,
    },
    products: {
        can_create: true,
        can_read: true,
        can_update: true,
        can_delete: false,
        can_export: true,
        can_read_fields: null,
    },
    'qc-samples': {
        can_create: true,
        can_read: true,
        can_update: true,
        can_delete: false,
        can_export: true,
        can_read_fields: null,
    },
    'qc-locations': {
        can_create: true,
        can_read: true,
        can_update: true,
        can_delete: false,
        can_export: true,
        can_read_fields: null,
    },
    'qc-sieves': {
        can_create: true,
        can_read: true,
        can_update: true,
        can_delete: false,
        can_export: true,
        can_read_fields: null,
    },
    'qc-sieve-sets': {
        can_create: true,
        can_read: true,
        can_update: true,
        can_delete: false,
        can_export: true,
        can_read_fields: null,
    },
    roles: {
        can_create: true,
        can_read: true,
        can_update: true,
        can_delete: false,
        can_export: true,
        can_read_fields: null,
    },
    users: {
        can_create: true,
        can_read: true,
        can_update: true,
        can_delete: false,
        can_export: true,
        can_read_fields: null,
    },
};
