import React from 'react';
import { TextField, useRecordContext } from 'react-admin';
import { sanitizeTabbedShowProps } from '../../helpers';

const defaultComponent = <TextField />;

const sanitizeFieldProps = ({ passProps, openPreviewDrawer, isDrawer, ...rest }: any) => sanitizeTabbedShowProps(rest);

const ConditionalField = ({ render, component = defaultComponent, ...props }) => {
    const record = useRecordContext(props);

    if (record && 'function' === typeof render && render(record)) {
        return React.cloneElement(component, { ...sanitizeFieldProps(props), addLabel: true });
    }

    return null;
};

ConditionalField.displayName = 'ConditionalField';

export default ConditionalField;
