import React from 'react';
import { DateField, TextField, Show, TabbedShowLayout, Tab, BooleanField, NumberField } from 'react-admin';
import {
    FilteredFieldsRenderer,
    ExpandableAuditsTable,
    LocalReferenceField,
} from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';

const getLocationsFields = () => [
    <TextField source="id" />,
    <TextField source="description" />,
    <LocalReferenceField source="parent_description" label="Parent" reference="qc-locations" passProps />,
    <LocalReferenceField source="site_description" label="Site" reference="sites" passProps />,
    <LocalReferenceField source="plant_description" label="Plant" reference="plants" passProps />,
    <LocalReferenceField source="product_description" label="Product" reference="products" passProps />,
    <TextField source="type_code" label="Type Code" />,
    <BooleanField source="is_split_sample_only" label="Is Split Sample Only" />,
    <NumberField source="email_list_id" label="Email List ID" />,
    <BooleanField source="is_send_email" label="Is Send Email" />,
    <BooleanField source="is_active" label="Is Active" />,
    <DateField label="Created At" source="created_at" showTime />,
    <DateField label="Updated At" source="updated_at" showTime />,
];

export const LocationsShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getLocationsFields} {...props} />
);

const LocationsTabbedShow = ({ actions, ...props }) => (
    <Show actions={actions} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getLocationsFields} isTabbedShow {...props} />
            <Tab label="Audits" path="audits">
                <ExpandableAuditsTable {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const LocationsSimpleShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <LocationsShowFields {...props} />
    </Show>
);

const UsersShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <LocationsSimpleShow {...props} />
    ) : (
        <LocationsTabbedShow {...props} />
    );

export default UsersShow;
