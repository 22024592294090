export const darkTheme = theme => ({
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
    overrides: {
        RaListToolbar: {
            toolbar: {
                justifyContent: 'flex-start',
                flexDirection: 'column-reverse',
                alignItems: 'flex-start',
                display: 'flex',
                flexWrap: 'wrap',
                paddingRight: 0,
                [theme.breakpoints.up('xs')]: {
                    paddingLeft: 0,
                },
                [theme.breakpoints.down('xs')]: {
                    paddingLeft: theme.spacing(2),
                },
            },
        },
        RaCardActions: {
            cardActions: {
                zIndex: 2,
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                padding: 0,
                marginBottom: '16px',
            },
        },
    },
});

export const lightTheme = theme => ({
    palette: {
        secondary: {
            light: '#6ec6ff',
            main: '#2196f3',
            dark: '#0069c0',
            contrastText: '#fff',
        },
    },
    typography: {
        title: {
            fontWeight: 400,
        },
    },
    overrides: {
        RaListToolbar: {
            toolbar: {
                justifyContent: 'flex-start',
                flexDirection: 'column-reverse',
                alignItems: 'flex-start',
                display: 'flex',
                flexWrap: 'wrap',
                paddingRight: 0,
                [theme.breakpoints.up('xs')]: {
                    paddingLeft: 0,
                },
                [theme.breakpoints.down('xs')]: {
                    paddingLeft: theme.spacing(2),
                },
            },
        },
        RaCardActions: {
            cardActions: {
                zIndex: 2,
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                padding: 0,
                marginBottom: '16px',
            },
        },
    },
});
