import React from 'react';
import { Button, TextInput, RadioButtonGroupInput, SortPayload, useListContext } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import { DialogContent, Divider, Tabs, Tab, Typography } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import {
    Storage as StorageIcon,
    Save as SaveIcon,
    Delete as DeleteIcon,
    Star as StarIcon,
    StarOutlined as StarOutlineIcon,
    Edit as EditIcon,
} from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useViewContext } from '../../../contexts/ViewContext';
import ViewFiltersButton from '../ViewFiltersButton';

const styles: any = {
    root: {
        display: 'flex',
        flexGrow: '1',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    grow: {
        flexGrow: '1',
    },
    rightIcon: {
        float: 'right',
    },
};

const hiddenStyle = { display: 'none' };

const useStyles = makeStyles(styles);

type TSaveAndLoadTabs = {
    tabs: {
        name: string;
        value: string;
    }[];
    visibleTab: string;
    setVisibleTab: React.Dispatch<React.SetStateAction<string>>;
    handleSubmit: (name: any) => void;
    handleFavorite: (name: any, unFavorite?: boolean) => void;
    handleLoad: (name: any, value: any) => void;
    handleDelete: (name: any) => void;
    closeDialog?: (refreshView?: any) => void;
};

const ViewChoice = ({ choice, isFav = false }) => (
    <div>
        {choice.name} {isFav ? <StarIcon style={{ color: yellow[500] }} /> : null}
        {/* {favoriteViewName && choice.name === favoriteViewName ? (
            <StarIcon style={{ color: yellow[500] }} />
        ) : null} */}
    </div>
);

const getViewChoice = (choice, isFav) => <ViewChoice choice={choice} isFav={isFav} />;

const SaveAndLoadTabs: React.FC<TSaveAndLoadTabs> = ({
    tabs,
    visibleTab,
    setVisibleTab,
    handleSubmit,
    handleFavorite,
    handleLoad,
    handleDelete,
    closeDialog,
}) => {
    // const [listDisplay, setListDisplay] = React.useState<TListView>({ columns: [], sort: [] });
    const [listDisplay, setListDisplay] = React.useState({ columns: [] });

    const { columns, preferences, currentViewName, favoriteViewName } = useViewContext()!;
    const { filterValues, currentSort } = useListContext();

    const classes = useStyles();

    const form = useForm();
    const {
        values: { list_view_name: listViewName, saved_list_view: savedListView },
    } = useFormState();

    const handleFormSubmit = () => {
        if ('System Default' === listViewName) {
            return;
        }
        handleSubmit(listViewName);
        form.change('list_view_name', null);
    };

    const handleOverWriteCurrentSubmit = () => {
        handleSubmit(currentViewName);
        form.change('list_view_name', null);
    };

    const handleLoadSubmit = () => {
        const viewValues = preferences && preferences[savedListView];
        handleLoad(savedListView, viewValues);
        if (closeDialog && 'function' === typeof closeDialog) {
            closeDialog();
        }
    };

    const handleFavoriteSubmit = () => {
        handleFavorite(savedListView);
    };

    const handleUnfavoriteSubmit = () => {
        handleFavorite(savedListView, true);
    };

    const handleDeleteSubmit = name => {
        form.change('saved_list_view', undefined);
        handleDelete(name);
    };

    React.useEffect(() => {
        // const listViewObj: any = { columns: [], sort: [] };
        const listViewObj: any = { columns: [] };
        if (columns) {
            if ('save' === visibleTab) {
                const filteredColumnSources = columns.reduce(
                    (newArr: string[], col) => (col.active ? [...newArr, col.source] : newArr),
                    []
                );
                listViewObj.columns = filteredColumnSources;

                if (filterValues) {
                    Object.entries(filterValues).forEach(([fil, val]: [string, any]) => {
                        listViewObj[fil] = val;
                    });
                }

                // if (sort) {
                //     const { field, order } = sort;
                //     listViewObj.sort = [field, order];
                // }
            } else if ('load' === visibleTab && savedListView && preferences && preferences[savedListView]) {
                const currentSavedView = preferences && preferences[savedListView];
                listViewObj.columns = currentSavedView.columns;

                // if (currentSavedView && currentSavedView.sort) {
                //     const { field, order } = currentSavedView.sort;
                //     listViewObj.sort = [field, order];
                // }

                if (currentSavedView) {
                    Object.entries(currentSavedView.filters).forEach(([fil, val]: [string, any]) => {
                        listViewObj[fil] = val;
                    });
                }
            }
        }
        setListDisplay(listViewObj);
    }, [columns, filterValues, preferences, savedListView, visibleTab]);

    const handleTabChange = React.useCallback(
        (ev, val) => {
            setVisibleTab(val);
        },
        [setVisibleTab]
    );

    return (
        <>
            {currentViewName && (
                <DialogContent className={classes.content}>
                    <Typography variant="h6" gutterBottom align="center">
                        Current View: {currentViewName}
                    </Typography>
                </DialogContent>
            )}
            <Tabs variant="fullWidth" centered value={visibleTab} indicatorColor="primary" onChange={handleTabChange}>
                {tabs.map(tab => (
                    <Tab key={tab.name} label={tab.name} value={tab.value} />
                ))}
            </Tabs>
            <Divider />
            <DialogContent style={'save' !== visibleTab ? hiddenStyle : undefined} className={classes.content}>
                <ViewFiltersButton
                    viewLabel="Show Current Settings"
                    hideLabel="Hide Current Settings"
                    filters={listDisplay}
                />
                <Button
                    label={'System Default' === currentViewName ? `OverWrite View` : `Overwrite ${currentViewName}`}
                    color="secondary"
                    onClick={handleOverWriteCurrentSubmit}
                    disabled={!currentViewName || 'System Default' === currentViewName}
                    submitOnEnter={false}
                >
                    <EditIcon />
                </Button>
                <TextInput
                    source="list_view_name"
                    label="List View Name"
                    validate={value =>
                        'System Default' === value ? 'Cannot save a list view named "System Default"' : undefined
                    }
                />
                <div />
                <Button
                    label="Save New View"
                    onClick={handleFormSubmit}
                    disabled={!listViewName || 'System Default' === listViewName}
                    submitOnEnter={false}
                >
                    <SaveIcon />
                </Button>
            </DialogContent>
            <DialogContent style={'load' !== visibleTab ? hiddenStyle : undefined} className={classes.content}>
                <ViewFiltersButton
                    viewLabel="Show Saved View"
                    hideLabel="Hide Saved View"
                    filters={listDisplay}
                    disabled={!savedListView}
                />
                {preferences && Object.keys(preferences).length ? (
                    <RadioButtonGroupInput
                        source="saved_list_view"
                        label="Saved List Views"
                        // choices={viewsData}
                        choices={
                            (preferences &&
                                Object.keys(preferences).map((viewName: any, index: number) => ({
                                    id: index + 1,
                                    name: viewName,
                                }))) ||
                            []
                        }
                        optionValue="name"
                        optionText={choice =>
                            getViewChoice(choice, favoriteViewName && choice.name === favoriteViewName)
                        }
                        initialValue={currentViewName}
                        row={false}
                    />
                ) : (
                    <Typography variant="h6" gutterBottom align="center">
                        No Saved Views Found
                    </Typography>
                )}
                <div />
                <div className={classes.buttonRow}>
                    <Button
                        label="Delete View"
                        color="secondary"
                        onClick={() => handleDeleteSubmit(savedListView)}
                        disabled={!savedListView}
                        submitOnEnter={false}
                    >
                        <DeleteIcon />
                    </Button>
                    {savedListView === favoriteViewName ? (
                        <Button
                            label="Unfavorite View"
                            color="secondary"
                            onClick={handleUnfavoriteSubmit}
                            disabled={!savedListView}
                            submitOnEnter={false}
                        >
                            <StarOutlineIcon />
                        </Button>
                    ) : (
                        <Button
                            label="Favorite View"
                            onClick={handleFavoriteSubmit}
                            disabled={!savedListView}
                            submitOnEnter={false}
                        >
                            <StarIcon />
                        </Button>
                    )}
                    <Button
                        label="Load View"
                        onClick={handleLoadSubmit}
                        disabled={!savedListView}
                        submitOnEnter={false}
                    >
                        <StorageIcon />
                    </Button>
                </div>
            </DialogContent>
        </>
    );
};

export default SaveAndLoadTabs;
