import React from 'react';
import { Pagination, useRecordContext } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { getUserSettings } from '../../helpers';
import AttachmentPreviewField from './AttachmentPreviewField';
import BulkUpdateButton from './BulkUpdateButton';
import ColumnsWrapper from './ColumnsWrapper';
import ConditionalField from './ConditionalField';
import CustomizeColumnsButton from './CustomizeColumnsButton';
import CustomFileField from './CustomFileField';
import DatagridWithViewContext from './DatagridWithViewContext';
import DrawerPreview, { DrawerFeatures } from './Drawers/DrawerPreview';
import DuplicateButton from './DuplicateButton';
import ExpandableAuditsTable from './ExpandableAuditsTable';
import ExpandableSimpleList from './ExpandableSimpleList';
import ExportSelectedButton from './ExportSelectedButton';
import FilteredFieldsRenderer from './FilteredFieldsRenderer';
import ItemsListing from './ItemsListing';
import ListWithViewContext from './ListWithViewContext';
import LocalReferenceField from './LocalReferenceField';
import Notification from './CustomNotification';
import NumberArrayField from './NumberArrayField';
import OptionsMenu from './OptionsMenu';
import PhoneNumberField from './PhoneNumberField';
import PreviewDrawerWrapper from './Drawers/PreviewDrawerWrapper';
import PrintButton from './PrintButton';
import PrintTitle from './PrintTitle';
import PrintWrapper from './PrintWrapper';
import ReferenceDrawerField from './ReferenceDrawerField';
import RelationTable from './RelationTable';
import SaveViewButton from './SaveViewButton';
import ShowViewWithPrint from './ShowViewWithPrint';
import TabbedList from './TabbedList';
import VariableEditButton from './VariableEditButton';
import ViewFiltersButton from './ViewFiltersButton';

const CustomResourceTitle = ({ customName }) => {
    const record = useRecordContext();
    return (
        <span>
            {customName}
            {record ? ` #${record.id}` : ''}
        </span>
    );
};

const ResponsiveList = ({ desktopList, mobileList, ...props }) => {
    const isNotSmall = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const forceDesktopView = true === getUserSettings('force_desktop_view', false);

    if (!mobileList || isNotSmall || forceDesktopView) {
        return React.cloneElement(desktopList, { ...props });
    }

    return React.cloneElement(mobileList, { ...props });
};

const CustomPagination = props => <Pagination rowsPerPageOptions={[25, 100, 250, 500]} {...props} />;

export {
    AttachmentPreviewField,
    BulkUpdateButton,
    ColumnsWrapper,
    ConditionalField,
    CustomizeColumnsButton,
    CustomFileField,
    CustomPagination,
    CustomResourceTitle,
    DatagridWithViewContext,
    DrawerPreview,
    DrawerFeatures,
    DuplicateButton,
    ExpandableAuditsTable,
    ExpandableSimpleList,
    ExportSelectedButton,
    FilteredFieldsRenderer,
    ItemsListing,
    ListWithViewContext,
    LocalReferenceField,
    Notification,
    NumberArrayField,
    OptionsMenu,
    PhoneNumberField,
    PreviewDrawerWrapper,
    PrintButton,
    PrintTitle,
    PrintWrapper,
    ReferenceDrawerField,
    RelationTable,
    ResponsiveList,
    SaveViewButton,
    ShowViewWithPrint,
    TabbedList,
    VariableEditButton,
    ViewFiltersButton,
};
