import React from 'react';
import { MenuItemLink } from 'react-admin';
import {
    Accessibility,
    Gradient,
    Grain,
    InsertChart,
    Layers,
    LocationOn,
    Map,
    VerifiedUser,
    Work,
} from '@material-ui/icons';

import { shouldRender } from '../../Components/lib/helpers';

export const renderMenuItem = ({ item, permissions, dense, open, ...rest }) => {
    if (item.needAccess) {
        if (shouldRender(permissions, item.role)) {
            return (
                <MenuItemLink
                    key={item.name}
                    leftIcon={item.icon || null}
                    to={`/${item.name}`}
                    primaryText={item.primaryText}
                    sidebarIsOpen={open}
                    dense={dense}
                    {...rest}
                />
            );
        }
        return null;
    }

    return (
        <MenuItemLink
            key={item.name}
            to={`/${item.name}`}
            leftIcon={item.icon || null}
            primaryText={item.primaryText}
            sidebarIsOpen={open}
            dense={dense}
            {...rest}
        />
    );
};

export const menuItems = {
    operations: [
        { name: 'sites', role: 'sites', primaryText: 'Sites', icon: <Map />, needAccess: true },
        { name: 'plants', role: 'plants', primaryText: 'Plants', icon: <Work />, needAccess: true },
        { name: 'products', role: 'products', primaryText: 'Products', icon: <Grain />, needAccess: true },
    ],
    qualityControl: [
        {
            name: 'qc-samples',
            role: 'qc-samples',
            primaryText: 'Samples',
            icon: <InsertChart />,
            needAccess: true,
        },
        {
            name: 'qc-locations',
            role: 'qc-locations',
            primaryText: 'Locations',
            icon: <LocationOn />,
            needAccess: true,
        },
        { name: 'qc-sieves', role: 'qc-sieves', primaryText: 'Sieves', icon: <Gradient />, needAccess: true },
        {
            name: 'qc-sieve-sets',
            role: 'qc-sieve-sets',
            primaryText: 'Sieve Sets',
            icon: <Layers />,
            needAccess: true,
        },
    ],
    admin: [
        { name: 'users', role: 'users', primaryText: 'Users', icon: <Accessibility />, needAccess: true },
        { name: 'roles', role: 'roles', primaryText: 'Roles', icon: <VerifiedUser />, needAccess: true },
    ],
};

export const menuRoles = {
    adminMenu: menuItems.admin.map(item => item.role),
    qualityControlMenu: menuItems.qualityControl.map(item => item.role),
    operationsMenu: menuItems.operations.map(item => item.role),
};

export const menuPaths = {
    sites: { name: 'operationsMenu', subName: null },
    plants: { name: 'operationsMenu', subName: null },
    products: { name: 'operationsMenu', subName: null },
    'qc-samples': { name: 'qualityControlMenu', subName: null },
    'qc-locations': { name: 'qualityControlMenu', subName: null },
    'qc-sieves': { name: 'qualityControlMenu', subName: null },
    'qc-sieve-sets': { name: 'qualityControlMenu', subName: null },
    users: { name: 'adminMenu', subName: null },
    roles: { name: 'adminMenu', subName: null },
};
