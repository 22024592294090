import * as React from 'react';
import { useListContext, useNotify, useResourceContext } from 'react-admin';
import StorageIcon from '@material-ui/icons/Storage';
import { DialogForm, SimpleFormWrapper } from '../../formComponents';
import { CustomConfirm as Confirm } from '../../index';
import SaveAndLoadTabs from './SaveAndLoadTabs';
import { useViewContext } from '../../../contexts/ViewContext';

const tabs = [
    { name: 'save', value: 'save' },
    { name: 'load', value: 'load' },
];

const SaveViewButton = () => {
    const [visibleTab, setVisibleTab] = React.useState<string>(tabs[0].value);
    const [overwriteVal, setOverwriteVal] = React.useState<string | null>(null);
    const [deleteVal, setDeleteVal] = React.useState<string | null>(null);

    const { preferences, selectView, saveView, currentViewName, favoriteView, deleteView } = useViewContext()!;
    const { displayedFilters, filterValues, setFilters, currentSort, setSort } = useListContext();
    const resource = useResourceContext();
    const notify = useNotify();

    const handleChangeListView = React.useCallback(
        ({ filters, sort }) => {
            if (sort) {
                const { field, order } = sort;
            }
            setFilters(filters, displayedFilters, false);
        },
        [displayedFilters, setFilters]
    );

    const handleSubmit = React.useCallback(
        name => {
            if (name === currentViewName || (preferences && Object.keys(preferences).includes(name))) {
                return setOverwriteVal(name);
            }
            setVisibleTab('load');
            saveView(name, filterValues, currentSort);
        },
        [currentSort, currentViewName, filterValues, preferences, saveView]
    );

    const handleLoad = React.useCallback(
        (name, value) => {
            handleChangeListView(value);
            selectView(name);
            notify(`List view "${name}" loaded; Columns and filters set`);
        },
        [handleChangeListView, notify, selectView]
    );

    const handleFavorite = (name, unFavorite = false) => {
        favoriteView(name, resource, unFavorite);
    };

    const handleSetDeleteValue = React.useCallback(name => {
        setDeleteVal(name);
    }, []);

    const handleDelete = () => {
        if (deleteVal) {
            deleteView(deleteVal, resource);
            setDeleteVal(null);
        }
    };

    const handleOverwriteSubmit = React.useCallback(() => {
        const prevFavorite =
            preferences && Object.entries(preferences).find(([name, value]: [string, any]) => value.favorite);
        if (overwriteVal) {
            const isFavorite = (prevFavorite && prevFavorite[0] === overwriteVal) || false;

            saveView(overwriteVal, filterValues, currentSort, isFavorite);
            setOverwriteVal(null);
        }
    }, [currentSort, filterValues, overwriteVal, preferences, saveView]);

    return (
        <>
            <Confirm
                isOpen={!!overwriteVal}
                title="Overwrite Saved List View"
                content={
                    overwriteVal !== currentViewName
                        ? `A saved list view named "${overwriteVal}" already exists, would you like to overwrite it?`
                        : `Overwrite view "${currentViewName}" with current list values?`
                }
                onConfirm={handleOverwriteSubmit}
                onClose={() => setOverwriteVal('')}
            />
            <Confirm
                isOpen={!!deleteVal}
                title="Delete Saved List View"
                content={`Are you sure you want to delete list view "${deleteVal}?"`}
                onConfirm={handleDelete}
                onClose={() => setDeleteVal('')}
            />
            <DialogForm
                title="Save / Load User List View"
                label={`View${currentViewName ? `: ${currentViewName}` : ''}`}
                icon={<StorageIcon />}
            >
                <SimpleFormWrapper>
                    <SaveAndLoadTabs
                        tabs={tabs}
                        visibleTab={visibleTab}
                        setVisibleTab={setVisibleTab}
                        handleSubmit={handleSubmit}
                        handleFavorite={handleFavorite}
                        handleLoad={handleLoad}
                        handleDelete={handleSetDeleteValue}
                    />
                </SimpleFormWrapper>
            </DialogForm>
        </>
    );
};

export default SaveViewButton;
