import React from 'react';
import { useLocation } from 'react-router';
import { Card } from '@material-ui/core';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Title } from 'react-admin';

const styles = {
    icon: {
        width: '9em',
        height: '9em',
    },
    message: {
        textAlign: 'center',
        fontFamily: 'Roboto, sans-serif',
        opacity: 0.5,
        margin: '3em 1em',
    },
};

const useStyles = makeStyles(styles);

const NoAccess = () => {
    const { state, search } = useLocation();
    const classes = useStyles();

    let erroredResource;
    let additionalMessage;

    if (state) {
        if (state.shouldRefresh) {
            window.location.reload();
        }
        if (state.erroredResource) {
            erroredResource = state.erroredResource;
        }
        if (state.additionalMessage) {
            additionalMessage = state.additionalMessage;
        }
    } else if (search) {
        erroredResource = search.replace('?resource=', '');
        additionalMessage = `If you were not explicitely attempting to access ${erroredResource || 'this resource'}, \n 
            then additional permissions may be required in order to complete your request.`;
    }

    return (
        <Card>
            <Title title="Access Denied" />
            <div className={classes.message}>
                <ReportProblemIcon className={classes.icon} />
                <h1>
                    You do not have permission to access
                    {erroredResource ? ` resource: ${erroredResource}` : ' this resource'}
                </h1>

                <div>Please consult your supervisor for access.</div>
                <br />
                {additionalMessage && <div>{additionalMessage}</div>}
            </div>
        </Card>
    );
};

export default NoAccess;
