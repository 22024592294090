import * as React from 'react';
import { useEffect, ReactNode, FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslate, ClassesOverride } from 'react-admin';

const useStyles = makeStyles(
    theme => ({
        removeButton: {},
        removeIcon: {
            color: theme.palette.error.main,
            disabledColor: theme.palette.text.disabled,
        },
        disabledIcon: {
            color: theme.palette.text.disabled,
        },
    }),
    { name: 'RaFileInputPreview' }
);

interface Props {
    children: ReactNode;
    className?: string;
    classes?: ClassesOverride<typeof useStyles>;
    onRemove: () => void;
    disableRemove?: (file: any) => boolean;
    file: any;
}

const FileInputPreview: FunctionComponent<Props> = props => {
    const { children, classes: classesOverride, className, onRemove, disableRemove, file, ...rest } = props;
    const classes = useStyles(props);
    const translate = useTranslate();

    useEffect(
        () => () => {
            const preview = file.rawFile ? file.rawFile.preview : file.preview;

            if (preview) {
                window.URL.revokeObjectURL(preview);
            }
        },
        [file]
    );

    const disabled = (disableRemove && 'function' === typeof disableRemove && disableRemove(file)) || false;

    return (
        <div className={className} {...rest}>
            <IconButton
                className={classes.removeButton}
                onClick={onRemove}
                aria-label={translate('ra.action.delete')}
                title={translate('ra.action.delete')}
                disabled={disabled}
            >
                <RemoveCircle className={disabled ? classes.disabledIcon : classes.removeIcon} />
            </IconButton>
            {children}
        </div>
    );
};

FileInputPreview.propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    file: PropTypes.object,
    onRemove: PropTypes.func.isRequired,
    disableRemove: PropTypes.func,
};

FileInputPreview.defaultProps = {
    file: undefined,
};

export default FileInputPreview;
