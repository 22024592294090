import React, { useState, useEffect, useMemo } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';

const SaveProgress = ({ subentities }) => {
    const [percent, setPercent] = useState(0);

    const formState = useFormState();
    const form = useForm();
    const { errors, values } = formState;
    const memoizedErrors = useMemo(() => errors, [errors]);

    useEffect(() => {
        const inputs = form.getRegisteredFields();
        const erroredInputs = inputs.filter(i => memoizedErrors && Object.keys(memoizedErrors).includes(i));
        const numberOfErrors = erroredInputs.length + 1;
        const newPercent = 100 / numberOfErrors;

        setPercent(newPercent);
    }, [memoizedErrors, form, percent, subentities, values]);

    return <Progress percent={percent} status={100 === percent ? 'success' : 'error'} />;
};

export default SaveProgress;
