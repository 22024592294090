import React from 'react';
import { useRecordContext, useResourceContext } from 'react-admin';
import { requestGetAudits } from '../../../../../dataProvider/RestClient';
import { ItemsListingView } from '../ItemsListing';

const sanitizedProps = ({
    ids,
    hasShow,
    hasCreate,
    hasList,
    hasEdit,
    printView,
    setIsLoadingQRForPrint,
    ...rest
}: any) => rest;

const metaFormatter = meta => {
    const { record, changes } = meta;
    let formattingPayload: any = meta;
    if (changes && Object.entries(changes).length) {
        formattingPayload = changes;
    } else if (record && Object.entries(record).length) {
        formattingPayload = record;
    }

    const formattedData = data => {
        if ('boolean' === typeof data) {
            return data.toString();
        }
        if ('object' === typeof data) {
            return null;
        }
        return data;
    };

    return formattingPayload && Object.keys(formattingPayload).length
        ? Object.keys(formattingPayload).map((field: any, i: number) => (
              <span key={field + i} style={{ display: 'block' }}>
                  {field.replace(/_/g, ' ')}: {formattedData(formattingPayload[field])}
              </span>
          ))
        : null;
};

const useGetAuditsTrail = ({ idField = 'entity_id', perPageCount = 25, printView = false, ...props }) => {
    const [lineItems, setLineItems] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isErrored, setIsErrored] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [nextPage, setNextPage] = React.useState<number | null>(1);
    const [totalRecords, setTotalRecords] = React.useState<number>(0);
    const [visibleRecords, setVisibleRecords] = React.useState<number>(0);

    const record = useRecordContext(props);
    const resource = useResourceContext(props);

    const entityName = React.useMemo(
        () =>
            [
                'App',
                'Entities',
                `${resource
                    .toLowerCase()
                    .split('-')
                    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
                    .join('')
                    .slice(0, -1)}`,
            ].join('\\'),
        [resource]
    );

    const fullyExpanded = React.useMemo(
        () => lineItems.length && lineItems.length === totalRecords && visibleRecords >= totalRecords,
        [lineItems.length, totalRecords, visibleRecords]
    );
    const fullyClosed = React.useMemo(
        () => visibleRecords && visibleRecords <= perPageCount,
        [perPageCount, visibleRecords]
    );

    const isMountedRef = React.useRef(false);

    const hasItems = React.useMemo(
        () =>
            lineItems && Array.isArray(lineItems)
                ? 0 < lineItems.length
                : lineItems && 'object' === typeof lineItems && 0 < Object.keys(lineItems).length,
        [lineItems]
    );

    const handleError = React.useCallback(error => {
        setIsErrored(true);
        setIsLoading(false);
        const {
            status,
            data: { message },
        } = error;
        if (status && 403 === status) {
            setErrorMessage(`Permissions required to view audits. ${message || ''}`);
        } else if (status && 404 === status) {
            setErrorMessage('Audits not found!');
        } else {
            setErrorMessage(`Problem getting audit trail. ${message || ''}`);
        }
    }, []);

    const auditsFilterObj = React.useMemo(
        () => ({
            complexPayloadLogic: 'or',
            logic: 'and',
            entity_id: record.id,
            entity: entityName,
            complexFilterLogic: 'and',
            complexFilter: [
                { field: 'parent_entity_id', operator: '=', value: record.id },
                {
                    field: 'parent_entity',
                    operator: '=',
                    value: entityName,
                },
            ],
        }),
        [entityName, record.id]
    );

    const getAuditsChunk = React.useCallback(
        async page => {
            if (1 === page) {
                setIsLoading(true);
            }
            if (record && record.id) {
                await requestGetAudits(
                    'created_at',
                    'DESC',
                    perPageCount,
                    [
                        'created_by_name',
                        'created_at',
                        'entity',
                        'entity_id',
                        'entity_name',
                        'parent_entity_id',
                        'parent_entity_name',
                        'action',
                        'meta',
                    ],
                    { secondarySort: [{ field: 'id', order: 'DESC' }], ...auditsFilterObj },
                    page
                )
                    .then(({ data, total }) => {
                        if (isMountedRef.current) {
                            if (data && data.length && data.length >= perPageCount) {
                                setNextPage(page + 1);
                            }
                            if (1 === page) {
                                setTotalRecords(total);
                                setIsLoading(false);
                                setLineItems(data);

                                setVisibleRecords(data.length);
                            } else {
                                setLineItems(prev => [...prev, ...data]);
                                setVisibleRecords(prev => prev + data.length);
                            }
                        }
                    })
                    .catch(handleError);
            }
        },
        [auditsFilterObj, handleError, perPageCount, record]
    );

    const onExpandClick = React.useCallback(async () => {
        if (visibleRecords < lineItems.length) {
            return setVisibleRecords(prev => prev + perPageCount);
        }
        if (!(lineItems.length === totalRecords)) {
            getAuditsChunk(nextPage);
        }
    }, [getAuditsChunk, lineItems, nextPage, perPageCount, totalRecords, visibleRecords]);

    const onCollapseClick = React.useCallback(
        (isRefresh = false) => {
            if (isRefresh) {
                return setVisibleRecords(perPageCount);
            }
            setVisibleRecords(prev => prev - perPageCount);
        },
        [perPageCount]
    );

    React.useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current && false === isErrored && entityName && !printView && !isLoading && 1 === nextPage) {
            getAuditsChunk(nextPage);
        }
        return () => {
            isMountedRef.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityName, getAuditsChunk, isErrored, nextPage, printView]);

    return {
        lineItems,
        hasItems,
        isLoading,
        isErrored,
        errorMessage,
        onExpandClick,
        onCollapseClick,
        fullyClosed,
        fullyExpanded,
        visibleRecords,
    };
};

const ExpandableAuditsTable = props => {
    const { printView } = props;

    const auditsTrailProps = useGetAuditsTrail(props);

    if (printView) {
        return null;
    }

    return (
        <ItemsListingView
            {...sanitizedProps(props)}
            columns={{
                created_by_name: 'User',
                created_at: 'Date/Time',
                entity: 'Entity',
                action: 'Action',
                meta: 'Details',
            }}
            title="Audits History"
            hasExpandButton
            formatToDate={[{ name: 'created_at', format: 'date-time' }]}
            formatToCustom={[
                {
                    name: 'meta',
                    formatter: record => record && record.meta && metaFormatter(record.meta),
                },
            ]}
            {...auditsTrailProps}
        />
    );
};

export default ExpandableAuditsTable;
