import React from 'react';
import { BooleanInput, useNotify } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Divider, Tabs, Tab } from '@material-ui/core';
import { requestGetByID } from '../../../../../dataProvider/RestClient';
import {
    GeneralInputs,
    IntervalInputs,
    ScheduleInputs,
} from '../../../../UserReports/UserReportForms/UserReportFormInputs';
import { resourceMapping } from '../../../../../dataProvider';

type TRecurringTabs = {
    schemaDateFields?: any;
    record?: any;
    resource?: string;
    setFiltersFromPayload?: React.SetStateAction<any>;
    fullWidth?: boolean;
};

const tabs = [
    { name: 'general', value: 'general' },
    { name: 'interval', value: 'interval' },
    { name: 'schedule', value: 'schedule' },
];

const hiddenStyle = { display: 'none' };

export const RecurringTabs: React.FC<TRecurringTabs> = ({
    schemaDateFields: schemaDatesFromList,
    fullWidth,
    ...rest
}) => {
    const [visibleTab, setVisibleTab] = React.useState(tabs[0].value);
    const [schemaDateFields, setSchemaDateFields] = React.useState([]);

    const notify = useNotify();

    let resource;

    if (rest.record && rest.record.settings && rest.record.settings.config) {
        resource = rest.record.settings.config.resource;
    } else {
        resource = rest.resource;
    }

    const { request_payload: requestPayload } = rest.record;
    const { setFiltersFromPayload } = rest;

    const hasConditions = (requestPayload && requestPayload.filters && requestPayload.filters.conditions) || false;

    const hasRelationsFilters =
        (requestPayload &&
            requestPayload.relations &&
            requestPayload.relations.some(rel => rel.filters && rel.filters.conditions)) ||
        false;

    React.useMemo(() => {
        const formattedFilters = {};
        if (hasConditions) {
            requestPayload.filters.conditions.forEach(filter => {
                let filterField = filter.field;

                if ('>=' === filter.operator) {
                    filterField = `${filter.field}_from`;
                } else if ('<=' === filter.operator) {
                    filterField = `${filter.field}_to`;
                }
                if (['in', '='].includes(filter.operator)) {
                    formattedFilters[filterField] = filter.value;
                } else {
                    formattedFilters[filterField] = `${filter.operator} ${filter.value}`;
                }
            });
        }
        if (hasRelationsFilters) {
            requestPayload.relations.forEach(rel => {
                if (rel && rel.filters && rel.filters.conditions) {
                    rel.filters.conditions.forEach(relFil => {
                        let relFilterField = relFil.field;

                        if ('>=' === relFil.operator) {
                            relFilterField = `${relFil.field}_from`;
                        } else if ('<=' === relFil.operator) {
                            relFilterField = `${relFil.field}_to`;
                        }

                        formattedFilters[`${rel.name}-${relFilterField}`] = relFil.value;
                    });
                }
            });
        }

        if (formattedFilters && Object.entries(formattedFilters).length && setFiltersFromPayload) {
            setFiltersFromPayload(formattedFilters);
        }
    }, [hasConditions, hasRelationsFilters, requestPayload, setFiltersFromPayload]);

    React.useEffect(() => {
        const getSchemaFromCall = () =>
            requestGetByID(resource, 'schema')
                .then(res => {
                    const timeDateFields = res.data.fields.filter(
                        field => 'datetime' === field.type || 'timestamp' === field.type
                    );
                    setSchemaDateFields(timeDateFields);
                })
                .catch(err => notify(`Problem getting schema for ${resource}`, 'warning'));

        if (schemaDatesFromList && schemaDatesFromList.length) {
            setSchemaDateFields(schemaDatesFromList);
        } else {
            getSchemaFromCall();
        }
    }, [notify, resource, schemaDatesFromList]);

    const handleTabChange = React.useCallback((ev, val) => {
        setVisibleTab(val);
    }, []);

    return (
        <>
            <Tabs variant="fullWidth" centered value={visibleTab} indicatorColor="primary" onChange={handleTabChange}>
                {tabs.map(tab => (
                    <Tab key={tab.name} label={tab.name} value={tab.value} />
                ))}
            </Tabs>
            <Divider />
            <GeneralInputs style={'general' !== visibleTab ? hiddenStyle : null} fullWidth {...rest} />
            <IntervalInputs
                style={'interval' !== visibleTab ? hiddenStyle : null}
                resource={resource}
                schemaDateFields={schemaDateFields}
                fullWidth
                {...rest}
            />
            <ScheduleInputs style={'schedule' !== visibleTab ? hiddenStyle : null} fullWidth {...rest} />
        </>
    );
};

const recurrenceFormFields = [
    'description',
    'started_at',
    'ended_at',
    'interval_field',
    'interval_date',
    'recurrence_interval',
    'recurrence_frequency',
    'recurrence_days',
    'recurrence_month_days',
    'schedule',
];

const RecurringReportInputs = ({ schemaDateFields, setResourceOverride, setReportsClassification, ...rest }) => {
    const { resource } = rest;
    const mappedResource = resourceMapping[resource] || resource;

    const form = useForm();
    const formState = useFormState();
    const {
        values: { is_recurring: isRecurring },
    } = formState;

    const handleReccurring = React.useCallback(
        active => {
            if (active) {
                form.batch(() => {
                    recurrenceFormFields.forEach(field => {
                        form.change(field, undefined);
                    });
                });
                setResourceOverride(`${mappedResource}/schedule-report`);
                setReportsClassification('recurring');
            } else {
                setResourceOverride(`${mappedResource}/export-to-csv`);
                setReportsClassification('nonrecurring');
            }
        },
        [form, mappedResource, setReportsClassification, setResourceOverride]
    );

    return (
        <>
            <BooleanInput source="is_recurring" label="Recurring" onChange={handleReccurring} {...rest} />
            {isRecurring && <RecurringTabs schemaDateFields={schemaDateFields} fullWidth {...rest} />}
        </>
    );
};

export default RecurringReportInputs;
