import React from 'react';
import { Labeled } from 'react-admin';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        margin: `${theme.spacing(1)}px 0`,
        width: `${theme.spacing(20)}px`,
    },
}));

const Progress: ({ label, ...props }: { [x: string]: any; label?: any }) => JSX.Element = ({ label, ...props }) => {
    const classes = useStyles();

    return (
        <Labeled label={label}>
            <LinearProgress className={classes.root} />
        </Labeled>
    );
};

export default Progress;
