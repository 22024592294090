import axios from 'axios';

const clientConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    maxContentLength: Infinity,
};

if ('development' === process.env.REACT_APP_APP_ENV) {
    clientConfig.headers = {
        common: {
            Authorization: `Bearer ${process.env.REACT_APP_PERSONAL_TOKEN}`,
        },
    };
}

const authClient = axios.create(clientConfig);

export default authClient;
