import React from 'react';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useChoices } from 'react-admin';
import {
    List,
    Card,
    CardHeader,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Divider,
    RootRef,
} from '@material-ui/core';
import DragHandle from '@material-ui/icons/DragHandle';

const styles = theme => ({
    primary: {
        color: theme.palette.primary.main,
    },
    secondary: {
        color: theme.palette.primary.light,
    },
});

const useStyles = makeStyles(styles);

const DraggableCheckboxItem = ({
    id,
    choice,
    source,
    index,
    handleToggle,
    optionText,
    optionValue,
    translateChoice,
    classes,
    isChecked,
}) => {
    const themeBackgroundClasses: any = useStyles();

    const { getChoiceText, getChoiceValue } = useChoices({
        optionText,
        optionValue,
        translateChoice,
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,

        ...(isDragging && {
            background: themeBackgroundClasses.secondary.color,
        }),
        ...{ userSelect: 'none' },
    });

    return (
        <Draggable key={source} draggableId={source} index={index}>
            {(provided, snapshot) => (
                <ListItem
                    role="listitem"
                    button
                    onClick={handleToggle(choice)}
                    ref={provided.innerRef}
                    ContainerComponent="li"
                    ContainerProps={{ ref: provided.innerRef }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                    <ListItemIcon>
                        <Checkbox
                            id={`${id}_${getChoiceValue(choice)}`}
                            color="primary"
                            className={classes.checkbox}
                            checked={isChecked}
                            value={String(getChoiceValue(choice))}
                            onChange={handleToggle(choice)}
                        />
                    </ListItemIcon>
                    <ListItemText id={id} primary={getChoiceText(choice)} />
                    <DragHandle />
                </ListItem>
            )}
        </Draggable>
    );
};

const CustomDraggableList = ({
    title,
    id,
    items,
    classes,
    setActive,
    handleToggleAll,
    numberOfChecked,
    handleToggle,
    optionText,
    optionValue,
    translateChoice,
    checked,
    source,
}) => {
    const themeBackgroundClasses: any = useStyles();
    const form = useForm();

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(items, result.source.index, result.destination.index);

        form.change(
            source,
            reorderedItems.map((item: any) => item.source)
        );

        setActive(reorderedItems);
    };

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? themeBackgroundClasses.secondary.color : themeBackgroundClasses.primary.color,
    });
    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && 0 !== items.length}
                        indeterminate={numberOfChecked(items) !== items.length && 0 !== numberOfChecked(items)}
                        disabled={0 === items.length}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} checked`}
            />
            <Divider />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <RootRef rootRef={provided.innerRef}>
                            <List
                                className={classes.list}
                                dense
                                component="div"
                                role="list"
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {items.map((choice, index) => {
                                    if (choice === undefined) {
                                        return null;
                                    }
                                    return (
                                        <DraggableCheckboxItem
                                            key={index}
                                            index={index}
                                            id={id}
                                            source={choice.source}
                                            choice={choice}
                                            isChecked={-1 !== checked.indexOf(choice)}
                                            handleToggle={handleToggle}
                                            optionText={optionText}
                                            optionValue={optionValue}
                                            translateChoice={translateChoice}
                                            classes={classes}
                                        />
                                    );
                                })}
                                {provided.placeholder}
                            </List>
                        </RootRef>
                    )}
                </Droppable>
            </DragDropContext>
        </Card>
    );
};

export default CustomDraggableList;
