import React from 'react';
import lodash from 'lodash';
import deepmerge from 'deepmerge';
import { useNotify, useRedirect } from 'react-admin';
import dataProvider from '../../../dataProvider';

type TQuery = {
    fields?: string[];
    filters?: any;
    relations?: object[];
    join?: boolean;
};

type TProgress = {
    percent: number;
    currentPage: number;
    totalPages: number;
};

type TuseGetPaginatedData = ({
    resource,
    sortByField,
    sortOrder,
    perPageCount,
    query,
    parsingFunction,
    page,
    chunkSize,
    autoRefresh,
    preventInit,
    showErrorNotification,
}: {
    resource: string;
    sortByField?: string;
    sortOrder?: string;
    perPageCount?: number;
    query: TQuery;
    parsingFunction?: (data: any) => any;
    page?: number;
    chunkSize?: number;
    autoRefresh?: boolean | null;
    preventInit?: boolean | null;
    showErrorNotification?: boolean;
}) => {
    progress: {
        percent: number;
        currentPage: number;
        totalPages: number;
    };
    allPagesData: object[];
    loading: boolean;
    initGetData: () => void;
    onClose: () => void;
    onCancel: () => void;
    getDataAll: () => Promise<any[]>;
    error?: string | null;
    unauthorized: boolean;
    paginationText: string;
};

const useGetPaginatedData: TuseGetPaginatedData = ({
    resource,
    sortByField = 'id',
    sortOrder = 'ASC',
    perPageCount = 500,
    query = {
        fields: [],
        filters: {},
        relations: [],
        join: false,
    },
    parsingFunction,
    page = 1,
    chunkSize = 15,
    autoRefresh = false,
    preventInit = false,
    showErrorNotification = true,
}) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const [allPagesData, setAllPagesData] = React.useState([]);
    const [fetchAllData, setFetchAllData] = React.useState<boolean>(false);
    const [fetchingChunk, setFetchingChunk] = React.useState<boolean>(false);
    const [pendingRequest, setPendingRequest] = React.useState<boolean>(false);
    const [cancelRequest, setCancelRequest] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [progress, setProgress] = React.useState<TProgress>({ percent: 0, currentPage: 0, totalPages: 0 });
    const [memoQuery, setMemoQuery] = React.useState<TQuery>(query);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [unauthorized, setUnauthorized] = React.useState<boolean>(false);

    const pendingRequestRef = React.useRef(pendingRequest);
    const isMountedRef = React.useRef(false);
    const cancelRef = React.useRef(cancelRequest);

    const params = React.useMemo(
        () => ({
            pagination: { page, perPage: perPageCount },
            sort: { field: sortByField, order: sortOrder },
            fields: memoQuery.fields,
            filter: memoQuery.filters,
            relations: memoQuery.relations,
            join: memoQuery.join,
        }),
        [
            memoQuery.fields,
            memoQuery.filters,
            memoQuery.join,
            memoQuery.relations,
            page,
            perPageCount,
            sortByField,
            sortOrder,
        ]
    );

    const paginationText = React.useMemo(
        () =>
            0 !== progress.percent
                ? `Loading Page ${
                      progress.currentPage > progress.totalPages ? progress.totalPages : progress.currentPage
                  }/${progress.totalPages}`
                : 'Loading',
        [progress.currentPage, progress.percent, progress.totalPages]
    );

    const reset = React.useCallback(() => {
        if (isMountedRef.current) {
            setPendingRequest(false);
            setCancelRequest(false);
            setFetchingChunk(false);
            setLoading(false);
            setProgress({
                percent: 0,
                currentPage: 0,
                totalPages: 0,
            });
            setAllPagesData([]);
            setFetchAllData(false);
        }
    }, []);

    const onClose = React.useCallback(() => {
        if (isMountedRef.current) {
            setLoading(false);
        }
    }, []);

    const onCancel = React.useCallback(() => {
        if (isMountedRef.current) {
            setCancelRequest(true);
            cancelRef.current = true;
            setLoading(false);
            setProgress({
                percent: 0,
                currentPage: 0,
                totalPages: 0,
            });
            setFetchAllData(false);
        }
    }, []);

    const initGetData = React.useCallback(() => {
        if (isMountedRef.current) {
            setProgress({
                percent: 0,
                currentPage: 0,
                totalPages: 0,
            });
            setLoading(true);
            dataProvider
                .getListWithPages(resource, params)
                .then(res =>
                    parsingFunction && 'function' === typeof parsingFunction && res.data.data
                        ? { ...res, data: { ...res.data, data: parsingFunction(res.data.data) } }
                        : res
                )
                .then(res => {
                    if (res.data && 0 === res.data.length && isMountedRef.current) {
                        setAllPagesData([]);
                        setPendingRequest(false);
                        setLoading(false);
                        return;
                    }
                    const { data: records, current_page: currentPage, last_page: lastPage } = res.data;

                    const nextPage = currentPage + 1;

                    if (isMountedRef.current) {
                        setPendingRequest(false);

                        setAllPagesData(records);

                        setProgress({
                            percent: (currentPage / lastPage) * 100,
                            currentPage: currentPage + 1,
                            totalPages: lastPage,
                        });
                        setErrorMessage(null);

                        if (nextPage > lastPage) {
                            setLoading(false);
                        }

                        if (autoRefresh && nextPage <= lastPage) {
                            setFetchAllData(true);
                        }
                    }
                })
                .catch(error => {
                    setLoading(false);
                    const {
                        status,
                        data: { message },
                    } = error;
                    if (401 === status) {
                        localStorage.clear();
                        return redirect('/login');
                    }
                    if (403 === status) {
                        setErrorMessage(`You do not have permission to view ${resource}. ${message || ''}`);
                        setUnauthorized(true);
                        return;
                    }
                    notify(`Error at ${resource}: ${error.data.message}`, 'warning');
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPagesData, autoRefresh, notify, params, reset, resource]);

    const getDataChunk = React.useCallback(
        async startPage => {
            const currentPendingRequest = pendingRequestRef.current;
            const reportExited = !isMountedRef.current;
            const isCancelled = cancelRef.current;
            let count = 0;
            const { totalPages } = progress;
            const promises: Promise<any>[] = [];

            if (currentPendingRequest || reportExited) {
                return;
            }

            if (isCancelled && !isMountedRef.current) {
                setCancelRequest(false);
                setFetchingChunk(false);

                if (autoRefresh && showErrorNotification) {
                    notify('Request Cancelled!', 'warning');
                }

                throw new Error('Request Cancelled!');
            }

            while (chunkSize > count && startPage + count <= totalPages) {
                const pageDataPromise = dataProvider
                    .getListWithPages(resource, {
                        ...params,
                        pagination: { page: startPage + count, perPage: perPageCount },
                    })
                    .then(res => res.data)
                    .catch(error => {
                        notify(`Error at ${resource}: ${error.data.message}`, 'warning');
                    });

                promises.push(pageDataPromise);
                count++;
            }

            if (count === chunkSize || startPage + count > totalPages) {
                return Promise.all(promises)
                    .then((result: any) => {
                        const lastPage = result[0].last_page;

                        let totalData;

                        if (Array.isArray(result[0].data)) {
                            totalData = result.flatMap(({ data: records }) => records);
                        } else {
                            totalData = Object.values(result).reduce((total: any, nextPage: any) => {
                                const mergedTotal = deepmerge(total, nextPage.data);
                                return mergedTotal;
                            }, {});
                        }

                        let processedData;

                        if (parsingFunction && 'function' === typeof parsingFunction) {
                            processedData = parsingFunction(totalData);
                        } else {
                            processedData = totalData;
                        }

                        const currentPage = startPage + chunkSize;

                        if (isMountedRef.current) {
                            setFetchingChunk(false);
                            setAllPagesData(prevData => deepmerge(prevData, processedData));
                            setProgress({
                                percent: currentPage > totalPages ? 100 : (currentPage / totalPages) * 100,
                                currentPage: currentPage > totalPages ? totalPages : currentPage,
                                totalPages,
                            });
                            return { pageData: processedData, currentPage, totalPages: lastPage };
                        }
                    })
                    .catch(error => {
                        if (isMountedRef.current) {
                            setLoading(false);
                            setProgress({
                                percent: 100,
                                currentPage: 0,
                                totalPages: 0,
                            });
                            setFetchingChunk(false);
                            setAllPagesData([]);
                            setErrorMessage(error.data.message);
                            if (showErrorNotification) {
                                notify(`Error at ${resource}: ${error.data.message}`, 'warning');
                            }
                        }
                    });
            }
        },
        [
            autoRefresh,
            chunkSize,
            notify,
            params,
            parsingFunction,
            perPageCount,
            progress,
            resource,
            showErrorNotification,
        ]
    );

    const getDataAll = React.useCallback(async () => {
        let startPage = progress.currentPage;
        let allData = Array.isArray(allPagesData) ? [...allPagesData] : allPagesData;

        if (100 !== progress.percent) {
            setLoading(true);
        }

        while (
            0 !== progress.totalPages &&
            startPage <= progress.totalPages &&
            isMountedRef.current &&
            !cancelRef.current
        ) {
            setFetchingChunk(true);
            const dataChunk = await getDataChunk(startPage)
                .then(data => data)
                .catch(err => {
                    if (autoRefresh && showErrorNotification) {
                        notify('Could not retrieve data', 'warning');
                    }
                    throw new Error(`${err}`);
                });
            if (dataChunk) {
                const { pageData, currentPage } = dataChunk;
                startPage = currentPage;

                allData = deepmerge(allData, pageData);
            }
        }

        return allData;
    }, [
        allPagesData,
        autoRefresh,
        getDataChunk,
        notify,
        progress.currentPage,
        progress.percent,
        progress.totalPages,
        showErrorNotification,
    ]);

    React.useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    React.useEffect(() => {
        if (!lodash.isEqual(memoQuery, query)) {
            setMemoQuery(query);
            if (!preventInit) {
                setPendingRequest(true);
            }
        }
    }, [initGetData, memoQuery, preventInit, query, resource]);

    React.useEffect(() => {
        if (isMountedRef.current) {
            reset();
            if (!preventInit) {
                setPendingRequest(true);
            }
        }
    }, [preventInit, reset]);

    React.useEffect(() => {
        if (pendingRequest && !fetchingChunk && isMountedRef.current) {
            initGetData();
        }
    }, [fetchingChunk, initGetData, pendingRequest]);

    React.useEffect(() => {
        if (autoRefresh && fetchAllData && isMountedRef.current) {
            setFetchAllData(false);
            getDataAll();
        }
    }, [autoRefresh, fetchAllData, getDataAll]);

    React.useEffect(() => {
        if (100 === progress.percent && isMountedRef.current) {
            setLoading(false);
        }
    }, [progress.percent]);

    return {
        progress,
        allPagesData,
        loading,
        initGetData,
        onClose,
        onCancel,
        getDataAll,
        error: errorMessage,
        unauthorized,
        paginationText,
    };
};

export default useGetPaginatedData;
