import React from 'react';
import moment from 'moment';
import { DateTimeInput, TimeInput, DateInput } from '../inputComponents/ReactAdminDateInputs';

const CustomDateTimeInput: ({
    format,
    type,
    options,
    label,
    ...props
}: {
    [x: string]: any;
    format?: any;
    type?: string;
    options?: {};
    label: any;
}) => JSX.Element = ({ format, type = 'dateTime', options = {}, label, ...props }) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const timeZone = user ? user.time_zone_abbr : '';

    if ('dateTime' === type) {
        return (
            <DateTimeInput
                options={{
                    ...options,
                    format: format || 'MM/dd/yyyy, hh:mm a',
                    clearable: true,
                    value: data => moment(data).utc().format(),
                }}
                label={`${label} (${timeZone})`}
                {...props}
            />
        );
    }
    if ('date' === type) {
        return (
            <DateInput
                options={{
                    ...options,
                    format: format || 'MM/dd/yyyy',
                    clearable: true,
                    value: data => moment(data).utc().format(),
                }}
                label={`${label} (${timeZone})`}
                {...props}
            />
        );
    }
    return (
        <TimeInput
            options={{
                ...options,
                format: format || 'hh:mm a',
                clearable: true,
                value: data => moment(data).utc().format(),
            }}
            label={`${label} (${timeZone})`}
            {...props}
        />
    );
};

export default CustomDateTimeInput;
