import React from 'react';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';

const useReferenceSource = ({ source, idOverride, descOverride, refOverride, ...props }) => {
    const [reference, setReference] = React.useState({
        refResource: '',
        idField: '',
        descriptionField: '',
        recordID: null,
    });

    const record = useRecordContext(props);

    React.useEffect(() => {
        const modifiedSource = source.replace(/_id|_description|_name/, '');
        const refResource = refOverride || `${modifiedSource.replace(/_/g, '-')}s`;
        const recordID = get(record, idOverride || source.replace(/_description|_name/, '_id'));
        const descriptionField = descOverride || source.replace('_id', '_description');
        const idField = idOverride || source.replace(/_description|_name/, '_id');

        setReference({
            refResource,
            idField,
            descriptionField,
            recordID,
        });
    }, [descOverride, idOverride, record, refOverride, source]);

    return reference;
};

export default useReferenceSource;
