import React from 'react';
import moment from 'moment';
import { AutocompleteArrayInput, SelectArrayInput, ReferenceArrayInput, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { DateInput } from '../inputComponents';
import { phoneNumberFormat } from '../../helpers';
import { DateTimeInput } from '../formComponents';

const useStyles = makeStyles(theme => ({
    spacer: { width: theme.spacing(2) },
}));

const DateTimeFilter = ({ source, label, ...props }) => {
    const classes = useStyles(props);

    return (
        <>
            <DateTimeInput source={`${source}_from`} label={`${label} (Start Date)`} {...props} />
            <div className={classes.spacer}>&nbsp;</div>
            <DateTimeInput source={`${source}_to`} label={`${label} (End Date)`} {...props} />
        </>
    );
};

const DateFilter = ({ source, label, ...props }) => {
    const classes = useStyles(props);

    return (
        <>
            <DateInput
                source={`${source}_from`}
                label={`${label} (Start Date)`}
                options={{
                    format: 'MM/dd/yyyy',
                    clearable: true,
                    value: data => moment(data).utc().format(),
                }}
                {...props}
            />
            <div className={classes.spacer}>&nbsp;</div>
            <DateInput
                source={`${source}_to`}
                label={`${label} (End Date)`}
                options={{
                    format: 'MM/dd/yyyy',
                    clearable: true,
                    value: data => moment(data).utc().format(),
                }}
                {...props}
            />
        </>
    );
};

const EmployeeFilter = ({
    label = 'Employee',
    source = 'employee_id',
    filter = { status: ['active', 'on leave'] },
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = (
        <AutocompleteArrayInput shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length} />
    ),
    ...rest
}) => (
    <ReferenceArrayInput
        label={label}
        source={source}
        reference="employees"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        filterToQuery={searchText => ({ name: `%${searchText}%` })}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
        emptyText="Clear Selection"
        {...rest}
    >
        {inputComponent}
    </ReferenceArrayInput>
);

const LocationFilter = ({
    label = 'Location',
    source = 'qc_location_id',
    filter = {},
    fieldSelectOnFilterLine = ['id', 'description'],
    inputComponent = <SelectArrayInput optionText="description" />,
    ...rest
}) => (
    <ReferenceArrayInput
        label={label}
        source={source}
        reference="qc-locations"
        filter={{ ...filter, fieldSelectOnFilterLine }}
        sort={{ field: 'description', order: 'ASC' }}
        {...rest}
    >
        {inputComponent}
    </ReferenceArrayInput>
);

const PhoneFilter = ({ label = 'Phone Number', source = 'phone', ...rest }) => (
    <TextInput
        source="phone"
        label="Phone"
        format={phoneNumberFormat}
        parse={phoneText => {
            if (!phoneText) {
                return null;
            }
            const modifiedSearchText = phoneText.replace(/-|_|\(|\)|\s/g, '');
            return `%${modifiedSearchText.trim()}%`;
        }}
        {...rest}
    />
);

const TextFilter = props => (
    <TextInput
        parse={searchText => `%${searchText}%`}
        format={searchText => (searchText ? searchText.replace(/%/g, '') : null)}
        {...props}
    />
);

export { DateFilter, DateTimeFilter, EmployeeFilter, LocationFilter, PhoneFilter, TextFilter };
