import React from 'react';
import { Link } from 'react-router-dom';
import { Drawer, IconButton, Typography, LinearProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import RecordShowFields from './RecordShowFields';
import { requestGetByID } from '../../../../../dataProvider/RestClient';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '1em',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    drawerPaper: {
        maxWidth: '80vw',
        zIndex: 100,
    },
    button: {
        background: 'none',
        border: 'none',
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
    link: {
        textDecoration: 'underline',
        color: theme.palette.primary.main,
    },
    progress: { marginTop: theme.spacing(2) },
}));

type TRecord = {
    id?: number;
};

interface IPreviewDrawerWrapper {
    position: 'right' | 'left' | 'top' | 'bottom' | undefined;
    isDrawer: boolean;
    children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

const PreviewDrawerWrapper: React.FC<IPreviewDrawerWrapper> = ({
    position = 'right',
    isDrawer = false,
    children,
    ...props
}) => {
    const [previewRecord, setPreviewRecord] = React.useState<TRecord>({});
    const [entityResource, setEntityResource] = React.useState<string | null>(null);
    const [showPanel, setShowPanel] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isErrored, setIsErrored] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState('Problem getting drawer data!');

    const classes = useStyles();

    const handleCloseClick = React.useCallback(() => {
        setShowPanel(false);
        setIsErrored(false);
    }, []);

    const handleClick: (id: number, resource: string) => React.MouseEventHandler<HTMLButtonElement> | Promise<void> =
        React.useCallback(async (id, resource) => {
            setIsErrored(false);
            setIsLoading(true);
            setShowPanel(true);
            await requestGetByID(resource, id)
                .then(res => {
                    setEntityResource(resource);
                    setPreviewRecord(res.data);
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                    setIsErrored(true);
                    const { status } = error;
                    if (404 === status) {
                        setErrorMessage(`${resource} #${id} not found!`);
                    }
                    if (403 === status) {
                        setErrorMessage(`You do not have permissions to view ${resource}!`);
                    }
                });
        }, []);

    const openPreviewDrawer = (id, resource, displayField) =>
        false === isDrawer ? (
            <button type="button" className={classes.button} onClick={() => handleClick(id, resource)}>
                {displayField}
            </button>
        ) : (
            <Link to={`/${resource}/${id}/show`} className={classes.link} onClick={ev => ev.stopPropagation()}>
                {displayField}
            </Link>
        );

    return (
        <>
            <Drawer
                anchor={position}
                open={showPanel}
                onClose={handleCloseClick}
                classes={{
                    paper: classes.drawerPaper,
                }}
                onClick={ev => ev.stopPropagation()}
            >
                <div className={classes.root}>
                    <div className={classes.title}>
                        {isErrored ? (
                            <Typography variant="h6" align="center" color="error">
                                Error
                            </Typography>
                        ) : (
                            <Typography variant="h6">
                                {`${
                                    (entityResource &&
                                        entityResource
                                            .toLowerCase()
                                            .split('-')
                                            .map(word => word[0].toUpperCase() + word.substr(1))
                                            .join(' ')) ||
                                    `Record`
                                } #${previewRecord.id || ''}`}
                            </Typography>
                        )}
                        <IconButton onClick={handleCloseClick}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {isLoading ? (
                        <LinearProgress className={classes.progress} />
                    ) : (
                        <RecordShowFields
                            openPreviewDrawer={openPreviewDrawer}
                            resource={entityResource}
                            record={previewRecord}
                        />
                    )}
                    {!isLoading && isErrored && (
                        <Typography variant="h6" color="error">
                            {errorMessage}
                        </Typography>
                    )}
                </div>
            </Drawer>
            {React.cloneElement(children, { ...props, openPreviewDrawer })}
        </>
    );
};

export default PreviewDrawerWrapper;
