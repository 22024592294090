import React from 'react';

const getSubentityErrors = (subentity, errors) => {
    const lineErrors = [];

    // For required subentity with no input
    if (errors['FINAL_FORM/array-error']) {
        return <li key={`FINAL_FORM/array-error-${subentity}`}>Input for {subentity} is required.</li>;
    }

    errors.forEach((line, index) => {
        // When no line error, form returns 'undefined' so check line data exists.
        if (line) {
            const lineErr = Object.keys(line).map(field => (
                <li key={field}>
                    Input for {subentity}[{index + 1}]-{field} is invalid.
                </li>
            ));

            lineErrors.push(lineErr);
        }
    });

    return lineErrors;
};

// Collect errors for sweet alert notification
const getNotificationErrors = (errors, subentities) => {
    const errorList = Object.keys(errors).map(error => {
        if (subentities && subentities.includes(error)) {
            return getSubentityErrors(error, errors[error]);
        }

        return <li key={error}>Input for {error} is invalid.</li>;
    });

    return <ol style={{ listStylePosition: 'inside', paddingLeft: 'inherit' }}>{errorList}</ol>;
};

export default getNotificationErrors;
