import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { ClassesOverride, FilterButton, FilterForm, sanitizeListRestProps, useListContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { getDynamicComponents, modifiedName } from '../../hooks/useGetDynamicComponents';
import { useViewContext } from '../../contexts/ViewContext';
import CustomFilterButton from './CustomFilterButton';

const useStyles = makeStyles(
    {
        button: {},
        form: {},
    },
    { name: 'RaFilter' }
);

interface DynamicFilterProps {
    alwaysOnFilters?: string[];
    children?: any;
    classes?: ClassesOverride<typeof useStyles>;
    context?: 'form' | 'button';
    variant?: string;
}

const sortByTemplateColumnIndex = (a, b, defaultArray) => {
    if (-1 === defaultArray.indexOf(a.props.source)) {
        return 1;
    }
    if (-1 === defaultArray.indexOf(b.props.source)) {
        return -1;
    }

    return defaultArray.indexOf(a.props.source) - defaultArray.indexOf(b.props.source);
};

const DynamicFilter: React.FC<DynamicFilterProps> = ({ alwaysOnFilters, ...props }) => {
    const classes = useStyles(props);
    const { resource, showFilter, hideFilter, setFilters, displayedFilters, filterValues } = useListContext(props);
    const viewContext = useViewContext();
    const [inputs, setInputs] = React.useState<Array<React.ReactNode>>([]);
    const [isInitialRender, setIsInitialRender] = React.useState<boolean>(true);

    React.useEffect(() => {
        const childrenFromProps =
            'undefined' !== typeof props.children && !Array.isArray(props.children) ? [props.children] : props.children;
        const filterSources =
            (childrenFromProps &&
                childrenFromProps.map(child => child && child.props && modifiedName(child.props.source))) ||
            [];

        if (viewContext && viewContext.schema && viewContext.schema.fields) {
            const { schema } = viewContext;
            if (schema.fields && schema.fields.length) {
                const dInputs = getDynamicComponents(schema.fields, filterSources, 'inputs', alwaysOnFilters);
                const sortedDynamicInputs: object[] = dInputs.sort((a, b) => {
                    if (a.props.label < b.props.label) {
                        return -1;
                    }
                    if (a.props.label > b.props.label) {
                        return 1;
                    }
                    return 0;
                });
                if (alwaysOnFilters && alwaysOnFilters.length) {
                    sortedDynamicInputs.sort((a, b) => sortByTemplateColumnIndex(a, b, alwaysOnFilters));
                }
                setInputs(sortedDynamicInputs);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewContext]);

    React.useEffect(() => {
        if (isInitialRender && viewContext && viewContext.preferences) {
            const favView = Object.entries(viewContext.preferences).find(
                ([viewName, view]: [string, any]) => view.favorite
            );
            if (favView) {
                const [name, view]: [string, any] = favView;
                const { filters: favFilters } = view;
                if (!lodash.isEqual(favFilters, filterValues)) {
                    setFilters(favFilters, displayedFilters);
                }
            }
        }
        setIsInitialRender(false);
    }, [displayedFilters, filterValues, isInitialRender, setFilters, viewContext]);

    const renderButton = () => {
        const { classes: classesOverride, context, children, variant, ...rest } = props;

        const childrenFromProps = 'undefined' !== typeof children && !Array.isArray(children) ? [children] : children;

        return (
            <CustomFilterButton
                className={classes.button}
                resource={resource}
                filters={
                    childrenFromProps
                        ? React.Children.toArray([...childrenFromProps, ...inputs])
                        : React.Children.toArray([...inputs])
                }
                showFilter={showFilter}
                displayedFilters={displayedFilters}
                filterValues={filterValues}
                {...sanitizeListRestProps(rest)}
            />
        );
    };

    const renderForm = () => {
        const { classes: classesOverride, context, children, ...rest } = props;

        const childrenFromProps = 'undefined' !== typeof children && !Array.isArray(children) ? [children] : children;

        return (
            <FilterForm
                className={classes.form}
                resource={resource}
                filters={
                    childrenFromProps
                        ? React.Children.toArray([...childrenFromProps, ...inputs])
                        : React.Children.toArray([...inputs])
                }
                hideFilter={hideFilter}
                displayedFilters={displayedFilters}
                initialValues={filterValues}
                setFilters={setFilters}
                {...sanitizeListRestProps(rest)}
            />
        );
    };

    return 'button' === props.context ? renderButton() : renderForm();
};

DynamicFilter.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    context: PropTypes.oneOf(['form', 'button']),
};

export default DynamicFilter;
