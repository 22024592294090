import React from 'react';
import { EmailField, SimpleShowLayout, useRecordContext } from 'react-admin';
import { Chip } from '@material-ui/core';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { shouldRender } from '../../lib/helpers';
import { ItemsListing, ListWithViewContext } from '../../lib/customComponents/showComponents';
import SwitchToUserButton from './SwitchToUserButton';

export const userAllowed = permissions => shouldRender(permissions, 'users', 'can_update');
export const userCanRead = permissions => shouldRender(permissions, 'users', 'can_read');
export const userLoginAsAllowed = permissions => shouldRender(permissions, 'user-impersonate', 'can_update');

export const CheckOrClose = (toggled, tertiaryColor = false) => {
    if (toggled) {
        if (tertiaryColor) {
            return <CheckIcon style={{ color: green[500] }} />;
        }
        return <CheckIcon color="primary" />;
    }
    return <CloseIcon color="secondary" />;
};

export const AccessGrid = (access, showName = false) => {
    if (!access) {
        return null;
    }
    const {
        can_read: canRead,
        can_export: canExport,
        can_create: canCreate,
        can_update: canUpdate,
        can_delete: canDelete,
        name,
    } = access;
    return (
        <div
            key={`accessgrid.${name}`}
            style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'baseline' }}
        >
            {showName && <h4>{name}:</h4>}
            <span>{CheckOrClose(canRead)}Read</span>
            <span>{CheckOrClose(canExport)}Export</span>
            <span>{CheckOrClose(canCreate)}Create</span>
            <span>{CheckOrClose(canUpdate)}Update</span>
            <span>{CheckOrClose(canDelete)}Delete</span>
        </div>
    );
};

export const FieldsCell = canReadFields =>
    canReadFields && canReadFields.length
        ? canReadFields.map(field => (
              <Chip key={`fieldcell.${field}`} style={{ backgroundColor: green[500] }} label={field} />
          ))
        : 'All';

const createGrid = capabilities => capabilities && capabilities.map(capability => AccessGrid(capability, true));

export const UserRoles = props => (
    <ItemsListing
        columns={{
            name: 'Name',
            description: 'Description',
            capabilities: 'Capabilities',
            id: 'Fields',
        }}
        type="roles"
        title="Roles"
        formatToCustom={[
            {
                name: 'capabilities',
                formatter: record => record && record.capabilities && createGrid(record.capabilities),
            },
            {
                name: 'id',
                formatter: record =>
                    record && record.pivot && record.pivot.can_read_fields
                        ? FieldsCell(record.pivot.can_read_fields)
                        : 'All',
            },
        ]}
        {...props}
    />
);

export const toUpper = str => {
    const processSubstring = sub =>
        sub
            .toLowerCase()
            .split(' ')
            .map(word => word[0].toUpperCase() + word.substr(1))
            .join(' ');
    return str
        .split('_')
        .map(subString => processSubstring(subString))
        .join(' ');
};

export const formatSettings = setting => {
    const { value } = setting;
    if ('boolean' === typeof value) {
        return CheckOrClose(value);
    }
    if ('string' === typeof value) {
        return toUpper(value);
    }
    return value;
};

export const UserSettingsTable: ({
    settingsOverrideData,
    ...props
}: {
    [x: string]: any;
    settingsOverrideData?: any;
}) => JSX.Element = ({ settingsOverrideData, ...props }) => {
    const record = useRecordContext(props);

    const settingsData: { field: any; value: any }[] = [];
    if (record && record.meta && record.meta.settings && Object.keys(record.meta.settings).length) {
        Object.entries(record.meta.settings).map(entry => {
            const [key, val] = entry;
            return settingsData.push({ field: key, value: val });
        });
    } else if (settingsOverrideData) {
        Object.entries(settingsOverrideData).map(entry => {
            const [key, val] = entry;
            return settingsData.push({ field: key, value: val });
        });
    }

    return (
        <ItemsListing
            columns={{
                field: 'Setting',
                value: 'Value',
            }}
            type="meta.settings"
            title="Settings"
            dataOverride={settingsData}
            formatToCustom={[
                {
                    name: 'field',
                    formatter: rec => rec && rec.field && toUpper(rec.field),
                },
                {
                    name: 'value',
                    formatter: rec => rec && formatSettings(rec),
                },
            ]}
            {...props}
        />
    );
};

const UsersExpand = props => (
    <SimpleShowLayout {...props}>
        <UserSettingsTable {...props} />
        <UserRoles {...props} />
    </SimpleShowLayout>
);

const usersDefaultColumns = ['id', 'name', 'email', 'is_active'];

const usersSpecialColumns = () => [<EmailField label="Email" source="email" />];

export const UsersList = React.memo(props => (
    <ListWithViewContext
        title="Users"
        alwaysOnFilters={['name', 'email']}
        defaultColumns={usersDefaultColumns}
        specialCols={usersSpecialColumns}
        expand={<UsersExpand />}
        primaryText={rec => rec && rec.name}
        secondaryText={rec => rec && rec.id}
        tertiaryText={rec => rec && rec.email}
        // additionalButtons={<SwitchToUserButton />}
        {...props}
    />
));
