import dataProvider from './index';

export const requestGetList = (
    url,
    sortByField = 'id',
    sortOrder = 'ASC',
    perPageCount = 25,
    fields = [],
    filter = {},
    relations = [],
    page = 1
) => {
    const params = {
        pagination: { page, perPage: perPageCount },
        sort: { field: sortByField, order: sortOrder },
        fields,
        filter,
        relations,
    };

    return dataProvider.getList(url, params);
};

export const requestGetOneWithQuery = (url, id, fields = [], relations = [], join = false) => {
    const params = {
        id,
        fields,
        relations,
        join,
    };
    return dataProvider.getOneWithQuery(url, params);
};

export const requestGetByID = (url, id) => dataProvider.getOne(url, { id });

export const requestGetMany = (url, ids, sortByField, sortOrder, perPageCount = 500, relations = [], join = false) => {
    const params = {
        pagination: { page: 1, perPage: perPageCount },
        ids,
        relations,
        join,
    };

    if (sortByField && sortOrder) {
        params.sort = { field: sortByField, order: sortOrder };
    }

    return dataProvider.getMany(url, params);
};

export const requestGetAudits = (
    sortByField = 'id',
    sortOrder = 'ASC',
    perPageCount = 25,
    fields = [],
    filter = {},
    page = 1
) => {
    const params = {
        pagination: { page, perPage: perPageCount },
        sort: { field: sortByField, order: sortOrder },
        fields,
        filter,
    };

    return dataProvider.getAudits(params);
};

export const requestGetNotificationsList = () => dataProvider.getNotificationsList();

export const requestGetCurrentUser = () => dataProvider.getCurrentUser();

export const requestUpdateCurrentUser = data => dataProvider.updateCurrentUser({ data });

export const requestCreate = (url, data) => dataProvider.create(url, { data });

export const requestUpdate = (url, id, data) => dataProvider.update(url, { id, data });

export const requestSetStatus = (url, id, status, data = {}) => {
    const params = {
        status,
        id,
        data,
    };
    return dataProvider.setStatus(url, params);
};

export const requestAddAttachments = (resource, id, files) => dataProvider.addAttachments(resource, id, files);

export const requestDownloadAttachment = id => dataProvider.downloadAttachment(id);

export const requestDownloadArrayBufferImage = id => dataProvider.downloadArrayBufferImage(id);

export const requestDownloadReport = id => dataProvider.downloadReport(id);

export const requestDeleteAttachments = (resource, id, paths) => dataProvider.deleteAttachments(resource, id, paths);

export const requestPasswordUpdate = data => dataProvider.updatePassword(data);

export const forgotPassword = data => dataProvider.forgotPassword(data);

export const requestDelete = (url, id) => dataProvider.delete(url, { id });
