import React from 'react';
import { ShowButton, useResourceDefinition } from 'react-admin';
import { Drawer, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { VariableEditButton } from '../index';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '1em',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    drawerPaper: {
        maxWidth: '80vw',
        zIndex: 100,
    },
}));

const stopPropagation = ev => ev.stopPropagation();

const DrawerPreview: ({
    position,
    showPanel,
    handleCloseClick,
    title,
    children,
}: {
    position?: 'bottom' | 'left' | 'right' | 'top';
    showPanel: any;
    handleCloseClick: any;
    title: any;
    children: any;
}) => JSX.Element = ({ position = 'right', showPanel, handleCloseClick, title, children }) => {
    const classes = useStyles();

    return (
        <Drawer
            anchor={position}
            open={showPanel}
            onClose={handleCloseClick}
            classes={{
                paper: classes.drawerPaper,
            }}
            onClick={stopPropagation}
        >
            <div className={classes.root}>
                <div className={classes.title}>
                    <Typography variant="h6">{title}</Typography>
                    <IconButton onClick={handleCloseClick}>
                        <CloseIcon />
                    </IconButton>
                </div>
                {children}
            </div>
        </Drawer>
    );
};

const sanitizedButtonProps = ({
    passProps,
    hasEdit,
    hasCreate,
    hasShow,
    hasList,
    setIsLoadingQRForPrint,
    openPreviewDrawer,
    ...rest
}: any) => rest;

export const DrawerFeatures: ({
    isDrawer,
    userAllowed,
    recordAllowed,
    ...props
}: {
    [x: string]: any;
    isDrawer?: boolean;
    userAllowed?: (args: any) => boolean | boolean;
    recordAllowed?: (args: any) => boolean | boolean;
}) => JSX.Element = ({ isDrawer, userAllowed, recordAllowed, ...props }) => {
    const { hasEdit } = useResourceDefinition(props);

    return isDrawer ? (
        <>
            <ShowButton {...sanitizedButtonProps(props)} />
            {hasEdit && (
                <VariableEditButton
                    recordAllowed={recordAllowed}
                    userAllowed={userAllowed}
                    {...sanitizedButtonProps(props)}
                />
            )}
        </>
    ) : null;
};

export default DrawerPreview;
