import React, { useState, useEffect, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import 'react-sweet-progress/lib/style.css';
import { CustomConfirm as Confirm } from '../../index';
import getErrors from './getErrors';

const AlertWindow: ({
    isOpen,
    onClose,
    subentity,
}: {
    isOpen?: any;
    onClose?: any;
    subentity?: any;
}) => JSX.Element = ({ isOpen, onClose, subentity }) => {
    const formState = useFormState();
    const { invalid, errors } = formState;
    const [errorContent, setErrorContent] = useState(null);
    const title = invalid ? 'Submit Failed' : 'We are processing your request!';
    const memoizedErrors = useMemo(() => errors, [errors]);

    useEffect(() => {
        if (invalid) {
            setErrorContent(getErrors(memoizedErrors, subentity));
        }
    }, [memoizedErrors, invalid, subentity]);

    return (
        <Confirm
            isOpen={isOpen}
            onClose={() => (invalid ? onClose() : null)}
            errorText="The following are required for submission."
            hasConfirm={false}
            simpleMessage={!invalid}
            content={invalid ? errorContent : 'We are processing your request!'}
            title={title}
            error={invalid}
            loading={!invalid}
        />
    );
};

export default AlertWindow;
