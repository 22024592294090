import setCustomFilters from '../../../../../dataProvider/setQuery/setCustomFilters';

type TFilledRel = { name: string; fields?: string[]; filters?: object };

const getRelations = (relation, relationColumns, relationFilterFields, formVals, listFilters) => {
    const relationsQuery: TFilledRel[] = [];

    if (relation && relation.length) {
        relation.forEach((rel, index) => {
            if (!rel || 'None' === rel) {
                return;
            }

            const filledRelation: TFilledRel = { name: rel };

            if (relationColumns && 0 < relationColumns[index].length) {
                filledRelation.fields = relationColumns[index];
            }

            const relationFilters = {};
            if (relationFilterFields && relationFilterFields[index] && 0 < relationFilterFields[index].length) {
                Object.entries(formVals)
                    .filter(item => item[0].includes(`relation_filter_values-${rel}`))
                    .map(([field, value]) => (relationFilters[field.split(`-${rel}-`)[1]] = value));
            }

            Object.keys(listFilters)
                .filter(fil => fil.includes(`${rel}-RELATION-`))
                .map(item => (relationFilters[item.split('-RELATION-')[1]] = listFilters[item]));

            const formattedRelationFilters = setCustomFilters(relationFilters);

            if (formattedRelationFilters && Object.keys(formattedRelationFilters).length) {
                filledRelation.filters = formattedRelationFilters;
            }

            relationsQuery.push(filledRelation);
        });
    }

    return relationsQuery;
};

export default getRelations;
