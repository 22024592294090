import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRedirect } from 'react-admin';
import {
    Typography,
    Divider,
    Tooltip,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    ListSubheader,
    MenuItem,
} from '@material-ui/core';
import { red, green, yellow, orange } from '@material-ui/core/colors';
import { Lens as ErrorIcon } from '@material-ui/icons';
import { formatDate } from '../../../Components/lib/helpers';
import OptionsButton from './NotificationOptionsButton';
import getHeaderText from './getHeaderText';
import { OptionsMenu } from '../../../Components/lib/customComponents/showComponents';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    readItem: {
        paddingLeft: '72px',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    secondaryContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const getColor = type => {
    switch (type) {
        case 'low':
            return yellow[500];

        case 'normal':
            return green[500];

        case 'high':
            return orange[500];

        default:
            return red[500];
    }
};

const NotificationList = ({ notifications, handleNotificationClick }) => {
    const classes = useStyles();
    const redirect = useRedirect();

    const handleShowClick = React.useCallback(pathname => redirect(pathname), [redirect]);

    return (
        <List
            className={classes.root}
            subheader={
                <div className={classes.headerContainer}>
                    <ListSubheader component="div" id="subheader" color="inherit">
                        Notifications
                    </ListSubheader>
                    <OptionsMenu>
                        <MenuItem
                            onClick={() =>
                                handleNotificationClick(
                                    'read',
                                    notifications.map(item => item.id)
                                )
                            }
                        >
                            Mark All as Read
                        </MenuItem>
                    </OptionsMenu>
                </div>
            }
        >
            {notifications ? (
                notifications.map(
                    (
                        {
                            subject,
                            sent_at: sentAt,
                            data,
                            priority,
                            id,
                            is_read: isRead,
                            redirect: redirectData,
                            notification_type: notificationType,
                        },
                        i
                    ) => (
                        <React.Fragment key={i}>
                            <ListItem id={id} button onClick={() => handleShowClick(`/notifications/${id}/show`)}>
                                <ListItemIcon>
                                    <OptionsButton
                                        id={id}
                                        isRead={isRead}
                                        handleNotificationClick={handleNotificationClick}
                                        redirect={redirectData}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            component="p"
                                            variant="h6"
                                            gutterBottom
                                            color={isRead ? 'textSecondary' : 'textPrimary'}
                                        >
                                            {subject}
                                        </Typography>
                                    }
                                    secondary={
                                        <span className={classes.secondaryContainer}>
                                            <Typography
                                                component="span"
                                                variant="subtitle2"
                                                gutterBottom
                                                color={isRead ? 'textSecondary' : 'textPrimary'}
                                            >
                                                {getHeaderText(notificationType, data)}
                                            </Typography>
                                            <Typography component="span" variant="caption" color="textSecondary">
                                                {formatDate(sentAt, 'date-time')}
                                            </Typography>
                                        </span>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Tooltip title={`Priority ${priority}`}>
                                        <ErrorIcon style={{ color: getColor(priority) }} />
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                            {i !== notifications.length - 1 && <Divider variant="inset" component="li" />}
                        </React.Fragment>
                    )
                )
            ) : (
                <p>No Notifications</p>
            )}
        </List>
    );
};

export default NotificationList;
