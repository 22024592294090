import React from 'react';
import { Resource } from 'react-admin';
import { shouldRender } from './Components/lib/helpers';
import NoAccess from './Layout/NoAccess';
import DebugPage from './Layout/DebugPage';

const UsersList = React.lazy(() => import('./Components/Admin/Users').then(module => ({ default: module.UsersList })));
const UserShow = React.lazy(() => import('./Components/Admin/Users/UserShow'));
const UserCreate = React.lazy(() =>
    import('./Components/Admin/Users/UserForms').then(module => ({ default: module.CreateUserForm }))
);
const UserEdit = React.lazy(() =>
    import('./Components/Admin/Users/UserForms').then(module => ({ default: module.EditUserForm }))
);

const RolesList = React.lazy(() => import('./Components/Admin/Roles').then(module => ({ default: module.RolesList })));
const RolesShow = React.lazy(() => import('./Components/Admin/Roles/RolesShow'));
const RoleCreate = React.lazy(() =>
    import('./Components/Admin/Roles/RolesForms').then(module => ({ default: module.RolesCreateForm }))
);
const RoleEdit = React.lazy(() =>
    import('./Components/Admin/Roles/RolesForms').then(module => ({ default: module.RolesEditForm }))
);

const UserReportList = React.lazy(() =>
    import('./Components/UserReports').then(module => ({ default: module.UserReportList }))
);
const UserReportShow = React.lazy(() => import('./Components/UserReports/RecurringReportShow'));
const UserReportEditForm = React.lazy(() =>
    import('./Components/UserReports/UserReportForms').then(module => ({ default: module.UserReportEditForm }))
);

const PlantsList = React.lazy(() =>
    import('./Components/Operations/Plants').then(module => ({ default: module.PlantsList }))
);
const PlantsShow = React.lazy(() => import('./Components/Operations/Plants/PlantsShow'));
const PlantsCreateForm = React.lazy(() =>
    import('./Components/Operations/Plants/PlantsForms').then(module => ({ default: module.PlantsCreateForm }))
);
const PlantsEditForm = React.lazy(() =>
    import('./Components/Operations/Plants/PlantsForms').then(module => ({ default: module.PlantsEditForm }))
);

const ProductsList = React.lazy(() =>
    import('./Components/Operations/Products').then(module => ({ default: module.ProductsList }))
);
const ProductsShow = React.lazy(() => import('./Components/Operations/Products/ProductsShow'));
const ProductsCreateForm = React.lazy(() =>
    import('./Components/Operations/Products/ProductsForms').then(module => ({ default: module.ProductsCreateForm }))
);
const ProductsEditForm = React.lazy(() =>
    import('./Components/Operations/Products/ProductsForms').then(module => ({ default: module.ProductsEditForm }))
);

const SitesList = React.lazy(() =>
    import('./Components/Operations/Sites').then(module => ({ default: module.SitesList }))
);
const SitesShow = React.lazy(() => import('./Components/Operations/Sites/SitesShow'));
const SitesCreateForm = React.lazy(() =>
    import('./Components/Operations/Sites/SitesForms').then(module => ({ default: module.SitesCreateForm }))
);
const SitesEditForm = React.lazy(() =>
    import('./Components/Operations/Sites/SitesForms').then(module => ({ default: module.SitesEditForm }))
);

const SamplesList = React.lazy(() =>
    import('./Components/QualityControl/Samples').then(module => ({ default: module.SamplesList }))
);
const SamplesShow = React.lazy(() => import('./Components/QualityControl/Samples/SamplesShow'));
const SamplesCreateForm = React.lazy(() =>
    import('./Components/QualityControl/Samples/SamplesForms').then(module => ({
        default: module.SamplesCreateForm,
    }))
);
const SamplesEditForm = React.lazy(() =>
    import('./Components/QualityControl/Samples/SamplesForms').then(module => ({
        default: module.SamplesEditForm,
    }))
);

const LocationsList = React.lazy(() =>
    import('./Components/QualityControl/Locations').then(module => ({ default: module.LocationsList }))
);
const LocationsShow = React.lazy(() => import('./Components/QualityControl/Locations/LocationsShow'));
const LocationsCreateForm = React.lazy(() =>
    import('./Components/QualityControl/Locations/LocationsForms').then(module => ({
        default: module.LocationsCreateForm,
    }))
);
const LocationsEditForm = React.lazy(() =>
    import('./Components/QualityControl/Locations/LocationsForms').then(module => ({
        default: module.LocationsEditForm,
    }))
);

const SievesList = React.lazy(() =>
    import('./Components/QualityControl/Sieves').then(module => ({ default: module.SievesList }))
);
const SievesShow = React.lazy(() => import('./Components/QualityControl/Sieves/SievesShow'));
const SievesCreateForm = React.lazy(() =>
    import('./Components/QualityControl/Sieves/SievesForms').then(module => ({ default: module.SievesCreateForm }))
);
const SievesEditForm = React.lazy(() =>
    import('./Components/QualityControl/Sieves/SievesForms').then(module => ({ default: module.SievesEditForm }))
);

const SieveSetsList = React.lazy(() =>
    import('./Components/QualityControl/SieveSets').then(module => ({ default: module.SieveSetsList }))
);
const SieveSetsShow = React.lazy(() => import('./Components/QualityControl/SieveSets/SieveSetsShow'));
const SieveSetCreateForm = React.lazy(() =>
    import('./Components/QualityControl/SieveSets/SieveSetsForms').then(module => ({
        default: module.SieveSetsCreateForm,
    }))
);
const SieveSetEditForm = React.lazy(() =>
    import('./Components/QualityControl/SieveSets/SieveSetsForms').then(module => ({
        default: module.SieveSetsEditForm,
    }))
);

const UsersListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UsersList {...props} />
    </React.Suspense>
);
const UserShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UserShow {...props} />
    </React.Suspense>
);
const UserCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UserCreate {...props} />
    </React.Suspense>
);
const UserEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UserEdit {...props} />
    </React.Suspense>
);

const RolesListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <RolesList {...props} />
    </React.Suspense>
);
const RoleshowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <RolesShow {...props} />
    </React.Suspense>
);
const RoleCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <RoleCreate {...props} />
    </React.Suspense>
);
const RoleEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <RoleEdit {...props} />
    </React.Suspense>
);

const UserReportsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UserReportList {...props} />
    </React.Suspense>
);
const UserReportsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UserReportShow {...props} />
    </React.Suspense>
);
const UserReportEditFormAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UserReportEditForm {...props} />
    </React.Suspense>
);

const PlantsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PlantsList {...props} />
    </React.Suspense>
);
const PlantsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PlantsShow {...props} />
    </React.Suspense>
);
const PlantCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PlantsCreateForm {...props} />
    </React.Suspense>
);
const PlantEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PlantsEditForm {...props} />
    </React.Suspense>
);

const ProductsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ProductsList {...props} />
    </React.Suspense>
);
const ProductsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ProductsShow {...props} />
    </React.Suspense>
);
const ProductCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ProductsCreateForm {...props} />
    </React.Suspense>
);
const ProductEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ProductsEditForm {...props} />
    </React.Suspense>
);

const SitesListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SitesList {...props} />
    </React.Suspense>
);
const SitesShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SitesShow {...props} />
    </React.Suspense>
);
const SiteCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SitesCreateForm {...props} />
    </React.Suspense>
);
const SiteEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SitesEditForm {...props} />
    </React.Suspense>
);

const SamplesListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SamplesList {...props} />
    </React.Suspense>
);
const SamplesShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SamplesShow {...props} />
    </React.Suspense>
);
const SampleCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SamplesCreateForm {...props} />
    </React.Suspense>
);
const SampleEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SamplesEditForm {...props} />
    </React.Suspense>
);

const LocationsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <LocationsList {...props} />
    </React.Suspense>
);
const LocationsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <LocationsShow {...props} />
    </React.Suspense>
);
const LocationCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <LocationsCreateForm {...props} />
    </React.Suspense>
);
const LocationEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <LocationsEditForm {...props} />
    </React.Suspense>
);

const SievesListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SievesList {...props} />
    </React.Suspense>
);
const SievesShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SievesShow {...props} />
    </React.Suspense>
);
const SieveCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SievesCreateForm {...props} />
    </React.Suspense>
);
const SieveEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SievesEditForm {...props} />
    </React.Suspense>
);

const SieveSetsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SieveSetsList {...props} />
    </React.Suspense>
);
const SieveSetsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SieveSetsShow {...props} />
    </React.Suspense>
);
const SieveSetCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SieveSetCreateForm {...props} />
    </React.Suspense>
);
const SieveSetEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SieveSetEditForm {...props} />
    </React.Suspense>
);

const getResources = permissions => [
    <Resource
        name="users"
        list={shouldRender(permissions, 'users') ? UsersListAsync : NoAccess}
        show={shouldRender(permissions, 'users') ? UserShowAsync : NoAccess}
        create={shouldRender(permissions, 'users') ? UserCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'users') ? UserEditAsync : NoAccess}
    />,
    <Resource
        name="roles"
        list={shouldRender(permissions, 'roles') ? RolesListAsync : NoAccess}
        show={shouldRender(permissions, 'roles') ? RoleshowAsync : NoAccess}
        create={shouldRender(permissions, 'roles') ? RoleCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'roles') ? RoleEditAsync : NoAccess}
    />,
    <Resource
        name="plants"
        list={shouldRender(permissions, 'plants') ? PlantsListAsync : NoAccess}
        show={shouldRender(permissions, 'plants') ? PlantsShowAsync : NoAccess}
        create={shouldRender(permissions, 'plants') ? PlantCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'plants') ? PlantEditAsync : NoAccess}
    />,
    <Resource
        name="products"
        list={shouldRender(permissions, 'products') ? ProductsListAsync : NoAccess}
        show={shouldRender(permissions, 'products') ? ProductsShowAsync : NoAccess}
        create={shouldRender(permissions, 'products') ? ProductCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'products') ? ProductEditAsync : NoAccess}
    />,
    <Resource
        name="sites"
        list={shouldRender(permissions, 'sites') ? SitesListAsync : NoAccess}
        show={shouldRender(permissions, 'sites') ? SitesShowAsync : NoAccess}
        create={shouldRender(permissions, 'sites') ? SiteCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'sites') ? SiteEditAsync : NoAccess}
    />,
    <Resource
        name="qc-samples"
        list={shouldRender(permissions, 'qc-samples') ? SamplesListAsync : NoAccess}
        show={shouldRender(permissions, 'qc-samples') ? SamplesShowAsync : NoAccess}
        create={shouldRender(permissions, 'qc-samples') ? SampleCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'qc-samples') ? SampleEditAsync : NoAccess}
    />,
    <Resource
        name="qc-locations"
        list={shouldRender(permissions, 'qc-locations') ? LocationsListAsync : NoAccess}
        show={shouldRender(permissions, 'qc-locations') ? LocationsShowAsync : NoAccess}
        create={shouldRender(permissions, 'qc-locations') ? LocationCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'qc-locations') ? LocationEditAsync : NoAccess}
    />,
    <Resource
        name="qc-sieves"
        list={shouldRender(permissions, 'qc-sieves') ? SievesListAsync : NoAccess}
        show={shouldRender(permissions, 'qc-sieves') ? SievesShowAsync : NoAccess}
        create={shouldRender(permissions, 'qc-sieves') ? SieveCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'qc-sieves') ? SieveEditAsync : NoAccess}
    />,
    <Resource
        name="qc-sieve-sets"
        list={shouldRender(permissions, 'qc-sieve-sets') ? SieveSetsListAsync : NoAccess}
        show={shouldRender(permissions, 'qc-sieve-sets') ? SieveSetsShowAsync : NoAccess}
        create={shouldRender(permissions, 'qc-sieve-sets') ? SieveSetCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'qc-sieve-sets') ? SieveSetEditAsync : NoAccess}
    />,
    <Resource name="reports" list={UserReportsListAsync} show={UserReportsShowAsync} edit={UserReportEditFormAsync} />,
    <Resource name="unauthorized" list={NoAccess} />,
    <Resource name="debug" list={DebugPage} />,
];

export default getResources;
