import React from 'react';
import { RolesShowFields } from '../../../../Admin/Roles/RolesShow';
import { UserShowFields } from '../../../../Admin/Users/UserShow';
import { LocationsShowFields } from '../../../../QualityControl/Locations/LocationsShow';

const sanitizedDefaultProps = ({ hasEdit, passProps, passClasses, ...rest }: any) => rest;

const RecordShowFields = ({ resource, record: recordFromProps, ...props }) => {
    const defaultProps = {
        ...sanitizedDefaultProps(props),
        basePath: `/${resource}`,
        resource,
        record: recordFromProps,
        isDrawer: true,
        id: recordFromProps.id,
    };

    switch (resource) {
        case 'roles':
            return <RolesShowFields {...defaultProps} />;
        case 'users':
            return <UserShowFields {...defaultProps} />;
        case 'qc-locations':
            return <LocationsShowFields {...defaultProps} />;
        default:
            return null;
    }
};

export default RecordShowFields;
