import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon } from '@material-ui/icons';
import { CustomConfirm as Confirm } from '../index';
import formViewStyles from '../../styles/formViewStyles';

const useStyles = makeStyles(formViewStyles);

const SaveAndSendEmailButton = ({ handleClick, ...props }) => {
    const [noteWindow, setNoteWindow] = React.useState<boolean>(false);
    const { invalid } = props;

    const classes = useStyles();

    const setNoteAndSave = React.useCallback(
        ev => {
            setNoteWindow(false);
            handleClick(ev, { send_notification: true });
        },
        [handleClick]
    );

    const handleSave = React.useCallback(
        ev => {
            if (invalid) {
                return handleClick(ev);
            }

            setNoteWindow(true);
        },
        [handleClick, invalid]
    );

    return (
        <>
            <Confirm
                isOpen={noteWindow}
                title="Send Notification"
                content="Do you want to send a notification?"
                confirmText="Yes"
                cancelText="No"
                onConfirm={setNoteAndSave}
                onClose={() => {
                    setNoteWindow(false);
                    handleClick();
                }}
            />
            <Button
                variant="contained"
                color="primary"
                size="medium"
                className={classes.button}
                onMouseUp={handleSave}
                startIcon={<SaveIcon />}
            >
                {' '}
                Save
            </Button>
        </>
    );
};

export default SaveAndSendEmailButton;
