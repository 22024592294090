import { createStyles } from '@material-ui/core/styles';

const styles = theme =>
    createStyles({
        button: {
            margin: theme.spacing(1),
        },
    });

export default styles;
