import React from 'react';

// PrintWrapper should be a class-based component since printing requires a DOM ref
// and functional components cannot take refs by default
// https://www.npmjs.com/package/react-to-print#faq

export default class PrintWrapper extends React.Component {
    render() {
        const { children, ...props } = this.props;

        return React.Children.map(children, child =>
            React.cloneElement(child, {
                ...props,
            })
        );
    }
}
