import React from 'react';
import { UserMenu, MenuItemLink, useNotify, useRedirect, usePermissions } from 'react-admin';
import {
    AccountBox as AccountBoxIcon,
    Assignment as AssignmentIcon,
    RateReview as RateReviewIcon,
} from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { shouldRender } from '../../Components/lib/helpers';
import { usePusherEvent } from '../../Components/lib/hooks';
import { Notification } from '../../Components/lib/customComponents/showComponents';
import authProvider, { setLocalStorage } from '../../authProvider';
import { requestGetCurrentUser } from '../../dataProvider/RestClient';

const AnyMenuItemLink = MenuItemLink as any;

const CustomUserMenu = ({ classes, ...props }) => {
    const [notificationMessage, setNotificationMessage] = React.useState(false);

    const userData = React.useMemo(() => JSON.parse(localStorage.getItem('user') || '{}'), []);
    const { permissions } = usePermissions();
    const userName = userData && userData.name ? userData.name : 'Name Not Found';

    const userCanViewReviews = shouldRender(permissions, 'reviews');

    const redirect = useRedirect();
    const notify = useNotify();

    const handleNotificationClick = () => {
        setNotificationMessage(false);
        window.location.reload();
    };

    const entityParser = React.useCallback(
        async ({ data, id, action, resource }) => {
            if (userData && userData.id === id && data && data.id === userData.id && 'users' === resource) {
                if ('update' === action) {
                    await requestGetCurrentUser()
                        .then(user => {
                            if (false === user.is_active) {
                                return authProvider.logout();
                            }
                            setLocalStorage({ user });
                            if (false === userData.is_active && true === user.is_active) {
                                return window.location.reload();
                            }
                            setNotificationMessage(true);
                        })
                        .catch(err => {
                            localStorage.clear();
                            notify('No current user found', 'warning');
                            redirect('/login');
                        });
                }
            }
        },
        [notify, redirect, userData]
    );

    usePusherEvent(`api.entity-changed.users.${userData.id}`, 'EntityChanged', entityParser);

    if (!userData || !permissions) {
        return (
            <div data-cy="userMenu">
                <UserMenu label={userName} {...props} />
            </div>
        );
    }

    return (
        <div data-cy="userMenu">
            <Notification
                handleUndo={handleNotificationClick}
                undoable
                undoButtonText="Refresh Page"
                message="Your user record has been updated, please refresh"
                notification={notificationMessage}
            />
            <UserMenu label={userName} {...props}>
                <div />
                <Typography
                    className={classes.userName}
                    color="textSecondary"
                    align="center"
                    variant="subtitle1"
                    gutterBottom
                >
                    {userName}
                </Typography>
                <AnyMenuItemLink
                    to={`/users/${userData.id}/show`}
                    primaryText="My Profile"
                    leftIcon={<AccountBoxIcon />}
                />
                <AnyMenuItemLink to="/reports" primaryText="My Reports" leftIcon={<AssignmentIcon />} />
                {userCanViewReviews && (
                    <AnyMenuItemLink to="/reviews" primaryText="My Reviews" leftIcon={<RateReviewIcon />} />
                )}
            </UserMenu>
        </div>
    );
};

export default CustomUserMenu;
