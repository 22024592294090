import React from 'react';
import { useListContext } from 'react-admin';
import EventNoteIcon from '@material-ui/icons/EventNote';
import setRecurrence from './setRecurrence';
import { TabbedCreate as CreateUserReportForm } from '../../UserReports/UserReportForms';
import { DialogForm } from '../../lib/customComponents/formComponents';
import { resourceMapping } from '../../../dataProvider';

const insertCreateValues = ({ config, resource, reportDetails, data }) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const userID = user && user.id;
    const referencedResource = resourceMapping[resource] || resource;

    data.is_active = true;
    data.user_id = userID;
    data.classification = 'recurring';
    data.endpoint = referencedResource || reportDetails.endpoint;

    if (config) {
        data.settings = { renderer: 'Flexmonster', config };
    }
};

const getSanitizedFormValues = ({
    recurrence_frequency: recurrenceFrequency,
    recurrence_interval: recurrenceInterval,
    recurrence_days: recurrenceDays,
    recurrence_month_days: recurrenceMonthDays,
    interval_date: recurrenceDate,
    interval_field: intervalField,
    request_payload: requestPayload,
    ...values
}: any) => {
    const sanitizedVals = {};
    Object.keys(values)
        .filter(key => !key.includes('relation_filter_'))
        .map(item => (sanitizedVals[item] = values[item]));

    return sanitizedVals;
};

const getOperator = value => {
    if ('string' === typeof value && value.match(/^%.*%$/gm)) {
        return 'like';
    }
    if ('object' === typeof value && Array.isArray(value)) {
        return 'in';
    }
    return '=';
};

export const transform: ({
    config,
    reportDetails,
    resource,
    filters,
    ...formValues
}: {
    [x: string]: any;
    config?: any;
    reportDetails?: {
        endpoint: string;
        dateField: string;
    };
    resource: string;
    filters: any;
}) => any = ({ config, reportDetails, resource, filters, ...formValues }) => {
    const sanitizedFormValues = getSanitizedFormValues(formValues);
    const { interval_field: intervalField, interval_date: intervalDate } = formValues;
    const isRecurringExportCreate = resource && intervalField;
    const isAnalyticsReportsCreate = !!reportDetails;
    const isEdit = !!formValues.id;

    let dateField;
    if (isAnalyticsReportsCreate) {
        dateField = reportDetails.dateField;
    } else {
        dateField = intervalField;
    }
    const intervalFilter = {
        field: dateField,
        operator: 'interval',
        value: intervalDate,
    };

    const getRequestConditions = () => {
        let conditions;
        if (isEdit) {
            conditions = formValues &&
                formValues.request_payload &&
                formValues.request_payload.filters &&
                formValues.request_payload.filters.conditions && [
                    ...formValues.request_payload.filters.conditions.filter(item => 'interval' !== item.operator),
                ];
        } else if (isRecurringExportCreate) {
            conditions = [];
            Object.entries(filters).forEach(([key, value]) => {
                conditions.push({ field: key, operator: getOperator(value), value });
            });
        } else {
            conditions =
                formValues &&
                formValues.request_payload &&
                formValues.request_payload.filters &&
                formValues.request_payload.filters.conditions
                    ? [...formValues.request_payload.filters.conditions]
                    : [];
        }
        conditions.push(intervalFilter);
        return conditions;
    };

    const requestConditions = getRequestConditions();

    const requestPayload = {
        ...formValues.request_payload,
        filters: {
            ...(formValues && formValues.request_payload && formValues.request_payload.filters),
            conditions: requestConditions,
        },
    };

    const recurrence = setRecurrence(formValues);

    const data = { ...sanitizedFormValues, request_payload: requestPayload, recurrence };
    if (isRecurringExportCreate || isAnalyticsReportsCreate) {
        insertCreateValues({ config, resource, reportDetails, data });
    }

    const sanitizeUserReport = rec => {
        const newRecord: any = {
            is_active: rec.is_active,
            classification: rec.classification,
            description: rec.description,
            started_at: rec.started_at,
            ended_at: rec.ended_at,
            user_id: rec.user_id,
            endpoint: rec.endpoint,
            settings: rec.settings,
            request_payload: rec.request_payload,
            recurrence: rec.recurrence,
            schedule: rec.schedule,
        };
        if (rec.id) {
            newRecord.id = rec.id;
        }
        return newRecord;
    };

    const sanitizedData = sanitizeUserReport(data);

    return sanitizedData;
};

const CreateReportButton = ({ config, reportDetails, ...props }) => {
    const { endpoint } = reportDetails;
    const { filterValues: filters } = useListContext();

    return (
        <DialogForm title="Create Recurring Report" icon={<EventNoteIcon />} passCloseDialogToForm={false}>
            <CreateUserReportForm
                resource="reports"
                resourceOverride={`${endpoint}/schedule-report`}
                fullWidth
                analyticsReport
                transform={vals => transform({ config, reportDetails, filters, ...vals })}
                {...props}
            />
        </DialogForm>
    );
};

export default CreateReportButton;
