import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNotify, usePermissions, useRecordContext, useResourceContext } from 'react-admin';
import PropTypes from 'prop-types';
import { requestGetList } from '../../../../dataProvider/RestClient';
import { ItemsListingView } from './ItemsListing';
import { shouldRender } from '../../helpers';

const useRelation = ({
    resource: resourceOverride,
    columns,
    useOriginalResource = false,
    additionalFields,
    excludeColumns = {},
    sortByField = 'id',
    sortByOrder = 'ASC',
    sortLinesBy,
    secondarySort,
    perPageCount = 100,
    printView = false,
    ...props
}) => {
    const [lineItems, setLineItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSorted, setIsSorted] = useState(false);
    const [isErrored, setIsErrored] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const isMountedRef = useRef(false);

    const record = useRecordContext(props);
    const resource = useResourceContext();
    const { permissions, loaded } = usePermissions();

    const notify = useNotify();

    const { title, relation } = props;
    const recordID = props.recordIdField ? record[props.recordIdField] : record.id;
    const tableResource = resourceOverride || resource;

    const idField = useOriginalResource
        ? 'id'
        : props.idField || (tableResource && `${tableResource.replace(/-/g, '_').slice(0, -1)}_id`) || null;
    const processedRelation = relation && relation.replace(/_/g, '-');
    const processedResource = tableResource && tableResource.replace(/_/g, '-');

    const canReadRelationFields = React.useMemo(
        () => shouldRender(permissions, relation, 'can_read_fields'),
        [permissions, relation]
    );

    const hasItems = useMemo(
        () =>
            lineItems && Array.isArray(lineItems)
                ? 0 < lineItems.length
                : lineItems && 'object' === typeof lineItems && 0 < Object.keys(lineItems).length,
        [lineItems]
    );

    const handleError = useCallback(
        error => {
            setIsErrored(true);
            setIsLoading(false);
            const {
                status,
                data: { message },
            } = error;
            if (status && 403 === status) {
                setErrorMessage(`Permissions required to view ${tableResource || 'this resource'}. ${message || ''}`);
            } else {
                notify(`Error trying to set line items at ${title || relation}!`, 'warning');
            }
        },
        [notify, relation, tableResource, title]
    );

    const getRequestFields = React.useCallback(() => {
        let requestFields;
        if (additionalFields && additionalFields.length) {
            requestFields = Object.keys(columns).concat(additionalFields);
        } else {
            requestFields = Object.keys(columns);
        }

        if (canReadRelationFields) {
            return requestFields.filter(field => canReadRelationFields.includes(field));
        }
        return requestFields;
    }, [additionalFields, canReadRelationFields, columns]);

    const refreshData = useCallback(() => {
        const requestFields = getRequestFields();

        if (relation && tableResource !== relation && useOriginalResource) {
            return requestGetList(
                processedResource,
                sortByField,
                sortByOrder,
                perPageCount,
                [],
                {
                    [idField]: recordID,
                    secondarySort,
                },
                [
                    {
                        name: relation,
                        fields: requestFields,
                    },
                ]
            )
                .then(response => {
                    if (isMountedRef.current) {
                        setLineItems(response.data);
                        setIsLoading(false);
                    }
                })
                .catch(handleError);
        }
        if (processedRelation && idField) {
            return requestGetList(processedRelation, sortByField, sortByOrder, perPageCount, requestFields, {
                [idField]: recordID,
                secondarySort,
            })
                .then(response => {
                    if (isMountedRef.current) {
                        if (sortLinesBy && 'function' === typeof sortLinesBy) {
                            const sorted = sortLinesBy(response.data);
                            setIsSorted(true);
                            setLineItems(sorted);
                        } else {
                            setLineItems(response.data);
                        }
                        setIsLoading(false);
                    }
                })
                .catch(handleError);
        }
    }, [
        getRequestFields,
        handleError,
        idField,
        perPageCount,
        processedRelation,
        processedResource,
        recordID,
        relation,
        secondarySort,
        sortByField,
        sortByOrder,
        sortLinesBy,
        tableResource,
        useOriginalResource,
    ]);

    useEffect(() => {
        isMountedRef.current = true;

        if (printView) {
            return;
        }
        if (isMountedRef.current && false === isErrored) {
            if (record && record[relation]) {
                if (sortLinesBy && 'function' === typeof sortLinesBy) {
                    const sorted = sortLinesBy(record[relation]);
                    setLineItems(sorted);
                    setIsSorted(true);
                } else {
                    setLineItems(record[relation]);
                }
            } else if (loaded) {
                setIsLoading(true);
                refreshData();
            }
        }

        return () => {
            isMountedRef.current = false;
        };
    }, [isErrored, loaded, printView, props.title, record, refreshData, relation, sortLinesBy]);

    return {
        lineItems,
        isLoading,
        isSorted,
        hasItems,
        excludeColumns,
        canReadRelationFields,
        relation,
        isErrored,
        errorMessage,
    };
};

const RelationTable = props => {
    const { printView } = props;

    const relationProps = useRelation(props);
    const hasSortedRelations = relationProps && relationProps.lineItems && relationProps.isSorted;

    if (printView) {
        return null;
    }

    return hasSortedRelations ? (
        <>
            {Object.keys(relationProps.lineItems).map((item, index) => {
                const excludeColByTitle = (relationProps.excludeColumns && relationProps.excludeColumns[item]) || [];
                return (
                    <ItemsListingView
                        {...props}
                        {...relationProps}
                        key={`sorted_relations.${index}`}
                        title={item.toString()}
                        lineItems={relationProps.lineItems[item]}
                        excludeColByTitle={excludeColByTitle}
                    />
                );
            })}
        </>
    ) : (
        <ItemsListingView {...props} {...relationProps} />
    );
};

export default RelationTable;
