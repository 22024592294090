import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import CustomConfirm from './CustomConfirm';
import DialogTable from './showComponents/DialogTable';

const LoadingWindow: ({
    isLoading,
    linear,
    completed,
    simpleMessage,
    content,
    hasConfirm,
    ...props
}: {
    [x: string]: any;
    isLoading?: any;
    linear?: any;
    completed?: number;
    simpleMessage?: boolean;
    content?: string;
    hasConfirm?: boolean;
}) => JSX.Element = ({
    isLoading,
    linear,
    completed = 100,
    simpleMessage = true,
    content = 'Loading...',
    hasConfirm = false,
    ...props
}) => (
    <CustomConfirm
        isOpen={isLoading}
        title="Please Wait"
        content={content}
        simpleMessage={simpleMessage}
        hasConfirm={hasConfirm}
        {...props}
    >
        {linear ? (
            <LinearProgress variant="determinate" value={completed} />
        ) : (
            <CircularProgress size={50} style={{ margin: '25px 200px' }} />
        )}
    </CustomConfirm>
);

const SuccessWindow: ({
    children,
    onConfirm,
    onClose,
    isOpen,
    title,
    content,
    ...props
}: {
    [x: string]: any;
    children: any;
    onConfirm?: any;
    onClose?: any;
    isOpen?: boolean;
    title?: string;
    content?: any;
}) => JSX.Element = ({ children, onConfirm, onClose, isOpen, title, content, ...props }) => (
    <CustomConfirm
        isOpen={isOpen}
        title={title}
        content={content}
        simpleMessage
        onConfirm={onConfirm}
        onClose={onClose}
    >
        {children}
    </CustomConfirm>
);

const ErrorWindow: ({
    children,
    onConfirm,
    onClose,
    isOpen,
    title,
    content,
    ...props
}: {
    [x: string]: any;
    children?: any;
    onConfirm?: any;
    onClose?: any;
    isOpen?: any;
    title?: any;
    content?: any;
}) => JSX.Element = ({ children, onConfirm, onClose, isOpen, title, content, ...props }) => (
    <CustomConfirm
        isOpen={isOpen}
        title={title}
        content={content}
        confirmText="Close"
        simpleMessage
        confirmColor="warning"
        onConfirm={onConfirm}
        onClose={onClose}
    >
        {children}
    </CustomConfirm>
);

export { CustomConfirm, DialogTable, ErrorWindow, SuccessWindow, LoadingWindow };
