import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { TextField, useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useReferenceSource } from '../../hooks';
import ReferenceDrawerField from './ReferenceDrawerField';

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'underline',
        color: theme.palette.primary.main,
    },
}));

interface ILocalReferenceField {
    [x: string]: any;
    className?: string;
    idSource?: string;
    descSource?: string;
    reference?: string;
    record?: any;
    source: string;
    label?: string;
    isDrawer?: boolean;
    link?: string;
    addLabel?: boolean;
    printView?: boolean;
}

const LocalReferenceField: React.FC<ILocalReferenceField> = memo(
    ({
        className,
        idSource: idOverride,
        descSource: descOverride,
        reference: refOverride,
        source,
        isDrawer = false,
        link = isDrawer ? 'show' : 'drawer',
        printView = false,
        ...rest
    }) => {
        const record = useRecordContext(rest);

        const classes = useStyles(rest);
        const { descriptionField, idField, refResource, recordID } = useReferenceSource({
            source,
            record,
            idOverride,
            descOverride,
            refOverride,
        });

        const linkPath = `/${refResource}/${recordID}${'show' === link ? '/show' : ''}`;

        if (!recordID || !record) {
            return null;
        }

        if (printView) {
            return <TextField source={descriptionField} record={record} />;
        }

        if ('drawer' === link) {
            return (
                <ReferenceDrawerField
                    className={className}
                    idSource={idOverride}
                    descSource={descOverride}
                    reference={refOverride}
                    record={record}
                    source={source}
                    {...rest}
                />
            );
        }

        return (
            <Link to={linkPath} onClick={ev => ev.stopPropagation()}>
                <TextField source={descriptionField} record={record} className={classes.link} />
            </Link>
        );
    }
);

LocalReferenceField.defaultProps = {
    addLabel: true,
};

export default LocalReferenceField;
