import React from 'react';
import { Button } from 'react-admin';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FiltersTable from './FiltersTable';

const styles = theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
});

// @ts-ignore
const useStyles = makeStyles(styles);

const ViewFiltersButton = ({
    filters,
    hideLabel = 'Hide Your Selected Filters',
    viewLabel = 'View Your Selected Filters',
    disabled = false,
}) => {
    const [modalOpened, setModalOpened] = React.useState(false);
    const classes = useStyles();

    const hasFilters = filters && 'object' === typeof filters && 0 < Object.keys(filters).length;

    const handleOpen = () => {
        setModalOpened(true);
    };

    const handleClose = () => {
        setModalOpened(false);
    };

    React.useEffect(() => {
        if (disabled) {
            setModalOpened(false);
        }
    }, [disabled]);

    return (
        <div className={classes.content}>
            {hasFilters && (
                <>
                    {modalOpened ? (
                        <Button label={hideLabel} onClick={handleClose} disabled={disabled}>
                            <VisibilityOffIcon />
                        </Button>
                    ) : (
                        <Button label={viewLabel} onClick={handleOpen} disabled={disabled}>
                            <VisibilityIcon />
                        </Button>
                    )}
                    {modalOpened && <FiltersTable data={filters} />}
                </>
            )}
        </div>
    );
};

export default ViewFiltersButton;
