import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
    List,
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Collapse,
    IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
    linkToRecord,
    sanitizeListRestProps,
    SimpleListLoading,
    ClassesOverride,
    useResourceContext,
} from 'react-admin';
import { ExpandButtonRow } from './DialogTable';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        link: {
            textDecoration: 'none',
            color: 'inherit',
            width: '100%',
        },
        tertiary: { float: 'right', opacity: 0.541176 },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            marginLeft: 'auto',
            transform: 'rotate(180deg)',
        },
    })
);

const LinkOrNot: ({
    classes: classesOverride,
    linkType,
    resource,
    id,
    children,
}: {
    classes?: ClassesOverride<typeof useStyles>;
    linkType?: string | boolean;
    resource: string;
    id: number;
    children: React.ReactNode;
}) => JSX.Element = ({ classes: classesOverride, linkType, resource, id, children }) => {
    const classes = useStyles({ classes: classesOverride });

    if ('edit' === linkType || true === linkType) {
        return (
            <Link to={linkToRecord(resource, id)} className={classes.link} onClick={ev => ev.stopPropagation()}>
                {children}
            </Link>
        );
    }
    if ('show' === linkType) {
        return (
            <Link
                to={`${linkToRecord(resource, id)}/show`}
                className={classes.link}
                onClick={ev => ev.stopPropagation()}
            >
                {children}
            </Link>
        );
    }
    return <span>{children}</span>;
};

const RecordItem = ({
    resource,
    classes,
    data,
    leftAvatar,
    leftIcon,
    primaryText,
    rightAvatar,
    rightIcon,
    secondaryText,
    tertiaryText,
    handleClick,
    linkType,
    id,
    autoFocus,
    autoFocusTarget,
    disabled,
    keyFormula,
}) => (
    <ListItem
        key={keyFormula && 'function' === typeof keyFormula ? keyFormula(data[id]) : id}
        // @ts-ignore
        button={linkType !== undefined || (handleClick && 'function' === typeof handleClick)}
        autoFocus={autoFocus && autoFocusTarget.includes(data[id].id)}
        selected={autoFocus && autoFocusTarget.includes(data[id].id)}
        onClick={handleClick ? event => handleClick({ event, id, data: data[id] }) : undefined}
        disabled={disabled && 'function' === typeof disabled ? disabled(data[id]) : disabled}
    >
        {leftIcon && <ListItemIcon>{leftIcon(data[id], id)}</ListItemIcon>}
        {leftAvatar && (
            <ListItemAvatar>
                <Avatar>{leftAvatar(data[id], id)}</Avatar>
            </ListItemAvatar>
        )}
        {linkType ? (
            <LinkOrNot
                classes={classes}
                linkType={linkType}
                resource={resource}
                id={data[id] ? data[id].id : id}
                key={id}
            >
                <ListItemText
                    primary={
                        <div>
                            {primaryText(data[id], id)}
                            {tertiaryText && <span className={classes.tertiary}>{tertiaryText(data[id], id)}</span>}
                        </div>
                    }
                    secondary={secondaryText && secondaryText(data[id], id)}
                    secondaryTypographyProps={{ component: 'span' }}
                />
            </LinkOrNot>
        ) : (
            <ListItemText
                primary={
                    <div>
                        {primaryText(data[id], id)}
                        {tertiaryText && <span className={classes.tertiary}>{tertiaryText(data[id], id)}</span>}
                    </div>
                }
                secondary={secondaryText && secondaryText(data[id], id)}
                secondaryTypographyProps={{ component: 'span' }}
            />
        )}
        {(rightAvatar || rightIcon) && (
            <ListItemSecondaryAction>
                {rightAvatar && <Avatar>{rightAvatar(data[id], id)}</Avatar>}
                {rightIcon && <ListItemIcon>{rightIcon(data[id], id)}</ListItemIcon>}
            </ListItemSecondaryAction>
        )}
    </ListItem>
);

const ExpandableSimpleList = ({
    className,
    classes: classesOverride,
    data,
    dataOverride,
    ids,
    keyFormula,
    loaded,
    loading,
    leftAvatar,
    leftIcon,
    onToggleItem,
    primaryText,
    rightAvatar,
    rightIcon,
    secondaryText,
    selectedIds = [],
    tertiaryText,
    total = ids.length,
    handleClick,
    linkType,
    visibleLimit = 10,
    onExpandClick,
    onCollapseClick,
    fullyExpanded,
    fullyClosed,
    autoFocus,
    autoFocusTarget,
    triggerAutoFocus,
    disabled = false,
    syncWithLocation,
    hasList,
    hasShow,
    hasEdit,
    hasCreate,
    hasBulkActions,
    openPreviewDrawer,
    ...rest
}) => {
    const [expanded, setExpanded] = useState(false);
    const [delayedAutoFocus, setDelayedAutoFocus] = useState(false);
    const prevValues = useRef(autoFocus);

    const resource = useResourceContext(rest);

    const classes = useStyles({ classes: classesOverride });
    let visibleItems = ids;
    let hiddenItems;

    const handleExpandClick = useCallback(() => {
        if (true === autoFocus && 'function' === typeof triggerAutoFocus && true === expanded) {
            triggerAutoFocus();
            setDelayedAutoFocus(false);
        }

        setExpanded(!expanded);

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true);
            }, 750);
        });
    }, [autoFocus, expanded, triggerAutoFocus]);

    // Necessary to handle focus/select after expand completes
    useEffect(() => {
        if (prevValues.current !== autoFocus && autoFocusTarget) {
            if (autoFocus && !expanded) {
                handleExpandClick().then(() => setDelayedAutoFocus(autoFocus));
            } else if (autoFocus) {
                setDelayedAutoFocus(autoFocus);
            }
        }
    }, [autoFocus, setDelayedAutoFocus, handleExpandClick, delayedAutoFocus, autoFocusTarget, expanded]);

    useEffect(() => {
        prevValues.current = autoFocus;
    }, [autoFocus]);

    if (false === loaded) {
        return (
            <SimpleListLoading
                classes={classes}
                className={className}
                hasLeftAvatarOrIcon={!!leftIcon || !!leftAvatar}
                hasRightAvatarOrIcon={!!rightIcon || !!rightAvatar}
                hasSecondaryText={!!secondaryText}
                hasTertiaryText={!!tertiaryText}
            />
        );
    }

    if (visibleLimit) {
        visibleItems = ids.slice(0, visibleLimit);
        hiddenItems = ids.slice(visibleLimit);
    }

    return (
        (loading || 0 < total) && (
            <List className={className} {...sanitizeListRestProps(rest)}>
                {visibleItems.map(id => (
                    <RecordItem
                        resource={resource}
                        classes={classes}
                        data={dataOverride || data}
                        leftAvatar={leftAvatar}
                        leftIcon={leftIcon}
                        primaryText={primaryText}
                        rightAvatar={rightAvatar}
                        rightIcon={rightIcon}
                        secondaryText={secondaryText}
                        tertiaryText={tertiaryText}
                        handleClick={handleClick}
                        linkType={linkType}
                        key={id}
                        id={id}
                        autoFocus={delayedAutoFocus}
                        autoFocusTarget={autoFocusTarget}
                        disabled={disabled}
                        keyFormula={keyFormula}
                    />
                ))}
                {hiddenItems &&
                    0 !== hiddenItems.length &&
                    visibleLimit &&
                    !(onExpandClick && 'function' === typeof onExpandClick) && (
                        <>
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                {hiddenItems.map(id => (
                                    <RecordItem
                                        resource={resource}
                                        classes={classes}
                                        data={dataOverride || data}
                                        leftAvatar={leftAvatar}
                                        leftIcon={leftIcon}
                                        rightAvatar={rightAvatar}
                                        rightIcon={rightIcon}
                                        primaryText={primaryText}
                                        secondaryText={secondaryText}
                                        tertiaryText={tertiaryText}
                                        handleClick={handleClick}
                                        linkType={linkType}
                                        key={id}
                                        id={id}
                                        autoFocus={delayedAutoFocus}
                                        autoFocusTarget={autoFocusTarget}
                                        disabled={disabled}
                                        keyFormula={keyFormula}
                                    />
                                ))}
                            </Collapse>
                            <ListItem button onClick={handleExpandClick}>
                                <IconButton
                                    className={expanded ? classes.expandOpen : classes.expand}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </ListItem>
                        </>
                    )}
                {onExpandClick && 'function' === typeof onExpandClick && (
                    <ExpandButtonRow
                        onExpandClick={onExpandClick}
                        onCollapseClick={onCollapseClick}
                        fullyExpanded={fullyExpanded}
                        fullyClosed={fullyClosed}
                    />
                )}
            </List>
        )
    );
};

export default ExpandableSimpleList;
