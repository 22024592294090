import { getTimeZone, getPermissions } from './authHelper';
import authClient from '../dataProvider/authClient';
import { localDevPermissions } from './localDevPermissions';

export const setLocalStorage = ({ user }) => {
    const { meta, roles, ...userData } = user;
    const userSettings = meta && meta.settings ? meta.settings : {};
    const savedDashboard = meta && meta.dashboard ? meta.dashboard : [];
    const savedViews = meta && meta.preferences ? meta.preferences : {};
    const savedSafetyDashboard = meta && meta.safetyDashboard ? meta.safetyDashboard : [];
    const permissions = getPermissions(roles);
    const timeZoneData = getTimeZone();

    localStorage.setItem('user', JSON.stringify({ ...userData, ...timeZoneData }));
    localStorage.setItem('userSettings', JSON.stringify(userSettings));
    localStorage.setItem('dashboard', JSON.stringify(savedDashboard));
    localStorage.setItem('preferences', JSON.stringify(savedViews));
    localStorage.setItem('safetyDashboard', JSON.stringify(savedSafetyDashboard));
    localStorage.setItem('permissions', JSON.stringify(permissions));
};

const userLogin = async ({ username, password, remember = false, provider = 'direct' }) => {
    let userData;

    if ('development' === process.env.REACT_APP_APP_ENV) {
        console.log('Logging in development');
        localStorage.setItem('user', JSON.stringify({ id: 2, name: 'Zach Selindh' }));
        localStorage.setItem('permissions', JSON.stringify(localDevPermissions));
        return Promise.resolve();
    }

    if ('azure' === provider) {
        userData = await authClient.get('user').then(res => res.data.data);
    } else {
        const auth = { email: username, password, provider, remember };
        userData = await authClient.get('csrf-cookie').then(cookies =>
            authClient
                .post('login', auth)
                .then(response => {
                    if (200 > response.status || 300 <= response.status) {
                        throw new Error(response.statusText);
                    }
                    return response;
                })
                .then(res => res.data.data)
        );
    }

    setLocalStorage({ user: userData });
    return Promise.resolve();
};

const userLogout = async () => {
    const logoutInfo = await authClient
        .post('logout')
        .then(res => res.data)
        .catch(err => {
            localStorage.clear();
            return Promise.resolve();
        });
    if (logoutInfo) {
        localStorage.clear();
        return Promise.resolve();
    }
    return Promise.resolve();
};

const checkAuthorization = async () => {
    const user = localStorage.getItem('user');

    if (user) {
        return Promise.resolve();
    }

    return Promise.reject();
};

const userGetPermissions = () => {
    const permissions = JSON.parse(localStorage.getItem('permissions'));

    if (!permissions) {
        return Promise.reject();
    }
    return Promise.resolve(permissions);
};

const authProvider = {
    login: params => userLogin(params),
    logout: () => userLogout(),
    checkError: error => {
        const { status } = error;

        if (status) {
            if (403 === status) {
                const { config } = error;
                const resource = config && config.url && config.url.split('/list')[0];
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    redirectTo: `/unauthorized?resource=${resource || ''}`,
                    logoutUser: false,
                });
            }

            if (401 === status || 419 === status) {
                localStorage.clear();
                return Promise.reject();
            }
            return Promise.resolve();
        }
        return Promise.resolve();
    },
    checkAuth: () => checkAuthorization(),
    getPermissions: () => userGetPermissions(),
};

export default authProvider;
