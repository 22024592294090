import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { DashboardMenuItem, usePermissions } from 'react-admin';
import { WorkOutlined, PermIdentityOutlined, CalendarTodayOutlined } from '@material-ui/icons';
import { shouldRender } from '../../Components/lib/helpers';
import { menuItems, menuRoles, menuPaths, renderMenuItem } from './mainMenuHelpers';
import SubMenu from './SubMenu';

const MainMenu = props => {
    const [menu, setMenu] = React.useState({
        qualityControlMenu: false,
        operationsMenu: false,
        adminMenu: false,
    });

    const handleToggle = menuItem => {
        setMenu(prevMenu => ({ ...prevMenu, [menuItem]: !prevMenu[menuItem] }));
    };

    const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
    const { pathname } = useLocation();
    const { permissions } = usePermissions();
    const { hasDashboard, dense, ...rest } = props;

    React.useEffect(() => {
        const setActiveMenusOnMount = () => {
            const modifiedPathname = pathname.match(/^\/([a-zA-Z/-]*[^/\d])/)[1];

            if (menuPaths[modifiedPathname]) {
                if (menuPaths[modifiedPathname].subName) {
                    setMenu(prevMenu => ({
                        ...prevMenu,
                        [menuPaths[modifiedPathname].name]: true,
                        [menuPaths[modifiedPathname].subName]: true,
                    }));
                } else {
                    setMenu(prevMenu => ({ ...prevMenu, [menuPaths[modifiedPathname].name]: true }));
                }
            }
        };

        if (pathname && '/' !== pathname) {
            setActiveMenusOnMount();
        }
    }, [pathname]);

    return (
        <>
            <DashboardMenuItem />
            {shouldRender(permissions, menuRoles.qualityControlMenu) && (
                <SubMenu
                    handleToggle={() => handleToggle('qualityControlMenu')}
                    isOpen={menu.qualityControlMenu}
                    name="Quality Control"
                    icon={<WorkOutlined />}
                    dense={dense}
                    {...rest}
                >
                    {menuItems.qualityControl.map(item => renderMenuItem({ item, permissions, dense, open, ...rest }))}
                </SubMenu>
            )}
            {shouldRender(permissions, menuRoles.operationsMenu) && (
                <SubMenu
                    handleToggle={() => handleToggle('operationsMenu')}
                    isOpen={menu.operationsMenu}
                    name="Operations"
                    icon={<CalendarTodayOutlined />}
                    dense={dense}
                    {...rest}
                >
                    {menuItems.operations.map(item => renderMenuItem({ item, permissions, dense, open, ...rest }))}
                </SubMenu>
            )}
            {shouldRender(permissions, menuRoles.adminMenu) && (
                <SubMenu
                    handleToggle={() => handleToggle('adminMenu')}
                    isOpen={menu.adminMenu}
                    name="Admin"
                    icon={<PermIdentityOutlined />}
                    dense={dense}
                    {...rest}
                >
                    {menuItems.admin.map(item => renderMenuItem({ item, permissions, dense, open, ...rest }))}
                </SubMenu>
            )}
        </>
    );
};

export default MainMenu;
