import React, { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { required, NumberInput } from 'react-admin';
import getRecurrenceHelperText from './getRecurrenceHelperText';

const RecurrenceIntervalInput = rest => {
    const formState = useFormState();
    const { values } = formState;
    const {
        started_at: startedAt,
        recurrence_interval: recurrenceInterval,
        recurrence_frequency: recurrenceFrequency,
        recurrence_month_days: recurrenceMonthDays,
        recurrence_days: recurrenceDays,
        schedule: recurrenceSchedule,
    } = values;

    const helperText = useMemo(
        () => (recurrenceInterval && recurrenceFrequency ? getRecurrenceHelperText(values) : undefined),
        [recurrenceFrequency, recurrenceInterval, values]
    );

    return (
        <NumberInput
            label="Interval"
            source="recurrence_interval"
            min={1}
            validate={[required()]}
            helperText={helperText}
            initialValue={1}
            fullWidth
            {...rest}
        />
    );
};

export default RecurrenceIntervalInput;
