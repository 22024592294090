import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    useRecordContext,
    Toolbar,
    useDataProvider,
    useNotify,
    useRedirect,
    SaveButton,
    useResourceContext,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import SaveProgress from './SaveProgress';
import useAttachments from './useAttachments';
import AlertWindow from './AlertWindow';
import { LoadingWindow } from '../../index';

const useRedirectFromQuery = initialValue => {
    const [redirect, setRedirect] = useState(initialValue);
    const { search } = useLocation();

    useEffect(() => {
        const parsedQuery = queryString.parse(search);
        if (parsedQuery && parsedQuery.redirect) {
            setRedirect(parsedQuery.redirect);
        }
    }, [search]);

    return redirect;
};

const CustomToolbar: ({
    subentity,
    subentityNotRequired,
    handleSubmitWithRedirect,
    closeDialog,
    dispatch,
    saving,
    dontSendRecordID,
    resourceOverride,
    basePath,
    customSaveButton,
    handleSubmit,
    updateValuesOnSubmit,
    redirectPath: redirectPathOverride,
    transform,
    noProgress,
    onSuccess,
    onFailure,
    awaitNotification,
    enableNotification,
    noInitialRequirement,
    ...props
}: {
    [x: string]: any;
    subentity?: any;
    subentityNotRequired?: boolean;
    handleSubmitWithRedirect?: any;
    closeDialog?: any;
    dispatch?: any;
    saving?: any;
    dontSendRecordID?: boolean;
    resourceOverride?: any;
    basePath?: any;
    customSaveButton?: any;
    handleSubmit?: any;
    updateValuesOnSubmit?: any;
    redirectPath?: any;
    transform?: any;
    noProgress?: any;
    onSuccess?: any;
    onFailure?: any;
    awaitNotification?: any;
    enableNotification?: any;
    noInitialRequirement?: boolean;
}) => JSX.Element = ({
    subentity,
    subentityNotRequired = false,
    handleSubmitWithRedirect,
    closeDialog,
    dispatch,
    saving,
    dontSendRecordID = false,
    resourceOverride,
    basePath,
    customSaveButton,
    handleSubmit,
    updateValuesOnSubmit = {},
    redirectPath: redirectPathOverride,
    transform,
    noProgress = false,
    onSuccess,
    onFailure,
    awaitNotification = false,
    enableNotification = true,
    noInitialRequirement = false,
    ...props
}) => {
    const [alertWindow, setAlertWindow] = useState(false);
    const [attachmentsWindow, setAttachmentsWindow] = useState(false);
    const [recordSent, setRecordSent] = useState(false);
    const resource = useResourceContext(props);

    const toolbarResource = resourceOverride || resource;
    const record = useRecordContext(props);

    const isMountedRef = useRef(false);

    const { updateFiles } = useAttachments(toolbarResource);

    const notify = useNotify();
    const redirectPath = useRedirectFromQuery(redirectPathOverride || basePath);
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const formState = useFormState();
    const { invalid, values } = formState;

    const handleError = useCallback(
        (error, attachmentsErr = false) => {
            if ('function' === typeof onFailure) {
                onFailure(error);
            }
            setAlertWindow(false);

            if (attachmentsErr) {
                setAttachmentsWindow(false);
                return notify('Error attempting to submit attachments!', 'warning');
            }

            notify('Error attempting to submit form data!', 'warning');
        },
        [notify, onFailure]
    );

    const sendFiles = useCallback(
        response => {
            setAttachmentsWindow(true);
            return updateFiles(response).catch(err => handleError(err, true));
        },
        [handleError, updateFiles]
    );

    const createOrUpdate = useCallback(
        async updatedValues => {
            let result;
            const payload = transform ? transform({ ...values, ...updatedValues }) : { ...values, ...updatedValues };
            const redirectEnabled = props.redirect;

            if (dontSendRecordID) {
                result = await dataProvider
                    // @ts-ignore
                    .update(toolbarResource, { data: payload })
                    .then(({ data }) => data)
                    .catch(handleError);
            } else if (record && record.id) {
                result = await dataProvider
                    // @ts-ignore
                    .update(toolbarResource, { id: record.id, data: payload })
                    .then(({ data }) => data)
                    .catch(handleError);
            } else {
                result = await dataProvider
                    // @ts-ignore
                    .create(toolbarResource, { id: record.id, data: payload })
                    .then(({ data }) => data)
                    .catch(handleError);
            }

            if (awaitNotification && isMountedRef.current) {
                setRecordSent(true);
                return;
            }

            if (isMountedRef.current) {
                await sendFiles(payload).catch(handleError);

                setAlertWindow(false);
                setAttachmentsWindow(false);

                if ('function' === typeof onSuccess) {
                    onSuccess(result);
                }
                if (redirectEnabled) {
                    redirect(redirectPath);
                }
                if (result && enableNotification) {
                    notify(
                        result.id
                            ? `${toolbarResource} #${result.id} ${record.id ? 'updated' : 'created'}`
                            : `${toolbarResource} ${record.id ? 'updated' : 'created'}`,
                        'success'
                    );
                }
            }
        },
        [
            awaitNotification,
            dataProvider,
            dontSendRecordID,
            enableNotification,
            handleError,
            notify,
            onSuccess,
            props.redirect,
            record,
            redirect,
            redirectPath,
            sendFiles,
            toolbarResource,
            transform,
            values,
        ]
    );

    const handleClick = useCallback(
        async (e, updatedValues = updateValuesOnSubmit) => {
            const ignoreSubentity = !subentity || true === subentityNotRequired;

            if (saving) {
                // prevent double submission
                return e.preventDefault();
            }

            if (invalid) {
                return setAlertWindow(true);
            }

            if (ignoreSubentity || subentity.some(val => values[val] && 0 < values[val].length)) {
                setAlertWindow(true);

                return createOrUpdate(updatedValues);
            }
        },
        [createOrUpdate, invalid, saving, subentity, subentityNotRequired, updateValuesOnSubmit, values]
    );

    const ToolbarSaveButton = useCallback(
        () =>
            customSaveButton ? (
                React.cloneElement(customSaveButton, {
                    handleClick,
                    transform,
                    onSuccess,
                    onFailure,
                    handleSubmitWithRedirect,
                    handleSubmit,
                    ...props,
                })
            ) : (
                <SaveButton label="save" onMouseDown={handleClick} submitOnEnter={false} />
            ),
        [customSaveButton, handleClick, handleSubmit, handleSubmitWithRedirect, onFailure, onSuccess, props, transform]
    );

    React.useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return (
        <Toolbar {...props}>
            {attachmentsWindow && (
                <LoadingWindow isLoading={attachmentsWindow} linear simpleMessage content="Processing attachments..." />
            )}
            <AlertWindow isOpen={alertWindow} onClose={() => setAlertWindow(false)} />
            <AlertWindow isOpen={alertWindow} onClose={() => setAlertWindow(false)} subentity={subentity} />
            {!noProgress && <SaveProgress subentities={subentity} />}
            <ToolbarSaveButton />
        </Toolbar>
    );
};

export default CustomToolbar;
