import React from 'react';
import {
    List,
    sanitizeListRestProps,
    ShowButton,
    SimpleList,
    usePermissions,
    useResourceContext,
    useResourceDefinition,
    useUnselectAll,
    warning,
} from 'react-admin';
import { useViewController, ViewContextProvider } from '../../contexts/ViewContext';
import { getUserSettings, shouldRender } from '../../helpers';
import { CustomActionsList } from '../../helpers/customActions';
import { useCustomizableColumns } from '../../hooks';
import { CustomPagination, DatagridWithViewContext, ResponsiveList, VariableEditButton } from '.';
import { DynamicFilter } from '../filterComponents';

type TDefaultMobileList = {
    leftIcon?: (record: any) => any;
    primaryText?: (record: any) => any;
    secondaryText?: (record: any) => any;
    tertiaryText?: (record: any) => any;
};

type TDefaultDesktopList = {
    actions?: JSX.Element;
    resource?: string;
    recordAllowed?: ((record: any) => boolean) | boolean;
    hasEdit?: boolean;
    expand?: JSX.Element;
    rowClick?: ((id?: number, resource?: string, record?: any) => 'edit' | 'show') | string;
    additionalButtons?: JSX.Element | boolean;
    defaultColumns?: any[];
    specialCols?: () => any[];
};

const sanitizeMobileListProps = ({
    additionalButtons,
    additionalColumns,
    defaultColumns,
    filterDefaultValues,
    hasList,
    hasShow,
    hasCreate,
    hasEdit,
    recordAllowed,
    rowClick,
    syncWithLocation,
    specialCols,
    ...rest
}: any) => sanitizeListRestProps(rest);

const DefaultMobileList: React.FC<TDefaultMobileList> = ({
    leftIcon,
    primaryText,
    secondaryText,
    tertiaryText,
    ...props
}) => (
    <SimpleList
        leftIcon={leftIcon}
        primaryText={primaryText}
        secondaryText={secondaryText}
        tertiaryText={tertiaryText}
        linkType="show"
        {...sanitizeMobileListProps(props)}
    />
);

const sanitizeDesktopListProps = ({
    additionalColumns,
    defaultColumns,
    filterDefaultValues,
    hasList,
    hasShow,
    hasCreate,
    hasEdit,
    leftIcon,
    primaryText,
    rowClick,
    syncWithLocation,
    specialCols,
    secondaryText,
    tertiaryText,
    ...rest
}: any) => sanitizeListRestProps(rest);

const DefaultDesktopList: React.FC<TDefaultDesktopList> = ({
    recordAllowed = true,
    expand,
    rowClick: rowClickOverride,
    additionalButtons = false,
    specialCols,
    ...props
}) => {
    const [isInitialRender, setIsInitialRender] = React.useState<boolean>(true);

    const isMountedRef = React.useRef(false);
    const unselectAll = useUnselectAll();
    const resource = useResourceContext();
    const { hasEdit } = useResourceDefinition(props);
    const { permissions } = usePermissions();

    const rowClick = (id, resourceArg, record) => {
        let clickRecordAllowed;
        if (recordAllowed) {
            if ('function' === typeof recordAllowed) {
                clickRecordAllowed = recordAllowed(record);
            } else {
                clickRecordAllowed = recordAllowed;
            }
        }
        if (clickRecordAllowed && shouldRender(permissions, resource, 'can_update') && hasEdit) {
            return 'edit';
        }
        return 'show';
    };

    React.useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    React.useMemo(() => {
        if (isInitialRender && isMountedRef.current && 'toggleSelection' === rowClickOverride) {
            unselectAll(resource);
            setIsInitialRender(false);
        }
    }, [isInitialRender, resource, rowClickOverride, unselectAll]);

    const clonedAdditionalButtons =
        additionalButtons && Array.isArray(additionalButtons)
            ? additionalButtons.map((button, index) =>
                  React.cloneElement(button, { ...button.props, key: `additional-btn-${index}` })
              )
            : React.isValidElement(additionalButtons) && React.cloneElement(additionalButtons);

    const columns = useCustomizableColumns(specialCols);

    return (
        <DatagridWithViewContext
            rowClick={rowClickOverride || rowClick}
            expand={React.isValidElement(expand) ? React.cloneElement(expand) : undefined}
            {...sanitizeDesktopListProps(props)}
        >
            {columns}
            <ShowButton />
            {hasEdit && <VariableEditButton recordAllowed={recordAllowed} />}
            {clonedAdditionalButtons}
        </DatagridWithViewContext>
    );
};

const sanitizeListProps = ({
    additionalColumns,
    defaultColumns,
    additionalButtons,
    editButton,
    expand,
    leftIcon,
    primaryText,
    rowClick,
    recordAllowed,
    secondaryText,
    specialCols,
    tertiaryText,
    ...rest
}: any) => rest;

const ListWithViewContext: ({
    sort,
    alwaysOnFilters,
    filters,
    filterDefaultValues,
    pagination,
    perPage,
    bulkActionButtons,
    desktopList,
    mobileList,
    actions,
    children,
    ...props
}: {
    [x: string]: any;
    sort?: {
        field: string;
        order: string;
    };
    alwaysOnFilters?: any;
    filters?: any;
    filterDefaultValues?: any;
    pagination?: any;
    perPage?: any;
    bulkActionButtons?: any;
    desktopList?: JSX.Element;
    mobileList?: JSX.Element;
    actions?: any;
    children?: any;
}) => JSX.Element = ({
    sort = { field: 'id', order: 'DESC' },
    alwaysOnFilters,
    filters = <DynamicFilter alwaysOnFilters={alwaysOnFilters} />,
    filterDefaultValues,
    pagination = <CustomPagination />,
    perPage = getUserSettings('default_per_page', 25),
    bulkActionButtons = false,
    desktopList = <DefaultDesktopList />,
    mobileList = <DefaultMobileList />,
    actions = <CustomActionsList />,
    children,
    ...props
}) => {
    const viewControllerProps = useViewController(props);

    warning(desktopList === undefined, `You must provide a desktopList component to a ListWithViewContext`);
    warning(mobileList === undefined, `You must provide a mobileList component to a ListWithViewContext`);
    warning(actions === undefined, `You must provide an actions component to a ListWithViewContext`);

    const cloneActions =
        actions && React.isValidElement(actions) && React.cloneElement(actions, { ...sanitizeListProps(props) });
    const cloneBulkActions =
        React.isValidElement(bulkActionButtons) &&
        React.cloneElement(bulkActionButtons, { ...sanitizeListProps(props) });

    return (
        <ViewContextProvider value={viewControllerProps}>
            <List
                filters={filters}
                filterDefaultValues={filterDefaultValues}
                sort={sort}
                actions={cloneActions}
                pagination={pagination}
                perPage={perPage}
                bulkActionButtons={cloneBulkActions}
                {...sanitizeListProps(props)}
            >
                {children || <ResponsiveList desktopList={desktopList} mobileList={mobileList} {...props} />}
            </List>
        </ViewContextProvider>
    );
};

export default ListWithViewContext;
