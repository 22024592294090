import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Grid,
    List,
    Card,
    CardHeader,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Button,
    Divider,
    FormHelperText,
    FormLabel,
    FormControl,
} from '@material-ui/core';
import {
    FieldTitle,
    InputHelperText,
    Labeled,
    LinearProgress,
    sanitizeInputRestProps,
    useInput,
    warning,
    useChoices,
} from 'react-admin';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CustomDraggableList from './CustomDraggableList';

const emptyArray = [];

const sanitizeRestProps = ({ setFilter, setPagination, setSort, loaded, closeDialog, ...rest }: any) =>
    sanitizeInputRestProps(rest);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 'auto',
        },
        cardHeader: {
            padding: theme.spacing(1, 2),
        },
        list: {
            width: 250,
            height: 230,
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        },
        button: {
            margin: theme.spacing(0.5, 0),
        },
    })
);

const notIncluded = (a: any, b: any) => a.filter(value => -1 === b.indexOf(value));

const CheckboxItem = ({ id, choice, handleToggle, optionText, optionValue, translateChoice, classes, isChecked }) => {
    const { getChoiceText, getChoiceValue } = useChoices({
        optionText,
        optionValue,
        translateChoice,
    });

    return (
        <ListItem role="listitem" button onClick={handleToggle(choice)}>
            <ListItemIcon>
                <Checkbox
                    id={`${id}_${getChoiceValue(choice)}`}
                    color="primary"
                    className={classes.checkbox}
                    checked={isChecked}
                    value={String(getChoiceValue(choice))}
                    onChange={handleToggle(choice)}
                />
            </ListItemIcon>
            <ListItemText id={id} primary={getChoiceText(choice)} />
        </ListItem>
    );
};

const TransferInput = props => {
    const {
        choices = [],
        className,
        classes: classesOverride,
        format,
        fullWidth,
        helperText,
        label,
        loaded,
        loading,
        margin = 'dense',
        onBlur,
        onChange,
        onFocus,
        optionText,
        optionValue,
        options,
        parse,
        reorderableRightInput,
        resource,
        row,
        source,
        translate,
        translateChoice,
        validate,
        leftMessage = 'Unselected',
        rightMessage = 'Selected',
        ...rest
    } = props;
    const classes = useStyles(props);

    warning(
        source === undefined,
        `If you're not wrapping the CheckboxGroupInput inside a ReferenceArrayInput, you must provide the source prop`
    );

    warning(
        choices === undefined,
        `If you're not wrapping the CheckboxGroupInput inside a ReferenceArrayInput, you must provide the choices prop`
    );

    const {
        id,
        input: { onChange: finalFormOnChange, onBlur: finalFormOnBlur, value },
        isRequired,
        meta: { error, submitError, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        validate,
        ...rest,
    });

    const [checked, setChecked] = useState<any[]>([]);
    const [inactive, setInactive] = useState<any[]>([]);
    const [active, setActive] = useState<any[]>([]);

    const inactiveChecked = checked.filter(inactiveValue => -1 !== inactive.indexOf(inactiveValue));
    const activeChecked = checked.filter(activeValue => -1 !== active.indexOf(activeValue));
    const currentValues = value || emptyArray;

    useEffect(() => {
        if (choices) {
            const choiceItems = () => choices.filter(val => !currentValues.includes(val.source));
            setInactive(choiceItems);
            setActive([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [choices, optionValue]);

    useEffect(() => {
        if (choices) {
            const getSelectedFromValue = val => choices.find(choice => get(choice, optionValue) === val);
            const defaultItems = () => currentValues.map(getSelectedFromValue).filter(item => item !== undefined);

            setActive(defaultItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [choices, optionValue]);

    const handleToggle = (toggleValue: number | string) => () => {
        const currentIndex = checked.indexOf(toggleValue);
        const newChecked = [...checked];

        if (-1 === currentIndex) {
            newChecked.push(toggleValue);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: number[]) =>
        checked.filter(checkedValue => -1 !== items.indexOf(checkedValue)).length;

    const handleToggleAll = (items: number[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(notIncluded(checked, items));
        } else {
            setChecked([...checked, ...notIncluded(items, checked)]);
        }
    };

    const handleStatusUpdate = useCallback(
        (movedToActive, checkedVals) => {
            const newValues = checkedVals.map(item => item[optionValue]);
            if (movedToActive) {
                finalFormOnChange([...(currentValues || []), ...newValues]);
            } else {
                finalFormOnChange(currentValues.filter(val => !newValues.includes(val))); // eslint-disable-line eqeqeq
            }
            finalFormOnBlur(); // HACK: See https://github.com/final-form/react-final-form/issues/365#issuecomment-515045503
        },
        [finalFormOnBlur, optionValue, finalFormOnChange, currentValues]
    );

    const moveToActive = () => {
        setActive(active.concat(inactiveChecked));
        handleStatusUpdate(true, inactiveChecked);
        setInactive(notIncluded(inactive, inactiveChecked));
        setChecked(notIncluded(checked, inactiveChecked));
    };

    const moveToInactive = () => {
        setInactive(inactive.concat(activeChecked));
        handleStatusUpdate(false, activeChecked);
        setActive(notIncluded(active, activeChecked));
        setChecked(notIncluded(checked, activeChecked));
    };

    const customList = (title: React.ReactNode, items: number[]) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && 0 !== items.length}
                        indeterminate={numberOfChecked(items) !== items.length && 0 !== numberOfChecked(items)}
                        disabled={0 === items.length}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} checked`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((choice: number | string, index: number) => (
                    <CheckboxItem
                        key={index}
                        id={id}
                        choice={choice}
                        isChecked={-1 !== checked.indexOf(choice)}
                        handleToggle={handleToggle}
                        optionText={optionText}
                        optionValue={optionValue}
                        translateChoice={translateChoice}
                        classes={classes}
                    />
                ))}
                <ListItem />
            </List>
        </Card>
    );

    if (loading) {
        return (
            <Labeled label={label} source={source} resource={resource} className={className} isRequired={isRequired}>
                <LinearProgress />
            </Labeled>
        );
    }

    return (
        <FormControl
            component="fieldset"
            margin={margin}
            error={touched && !!(error || submitError)}
            className={fullWidth ? classes.root : classnames(classes.root, className)}
            fullWidth={fullWidth}
            {...sanitizeRestProps(rest)}
        >
            <FormLabel component="legend">
                <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
            </FormLabel>
            <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
                <Grid item>{customList(leftMessage, inactive)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={moveToActive}
                            disabled={0 === inactiveChecked.length}
                            aria-label="move selected to active"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={moveToInactive}
                            disabled={0 === activeChecked.length}
                            aria-label="move selected to inactive"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    {reorderableRightInput ? (
                        <CustomDraggableList
                            title={rightMessage}
                            id={id}
                            items={active}
                            classes={classes}
                            handleToggleAll={handleToggleAll}
                            numberOfChecked={numberOfChecked}
                            handleToggle={handleToggle}
                            setActive={setActive}
                            optionText={optionText}
                            optionValue={optionValue}
                            translateChoice={translateChoice}
                            checked={checked}
                            source={source}
                        />
                    ) : (
                        customList(rightMessage, active)
                    )}
                </Grid>
            </Grid>
            <FormHelperText>
                <InputHelperText touched={touched} error={error || submitError} helperText={helperText} />
            </FormHelperText>
        </FormControl>
    );
};

TransferInput.propTypes = {
    choices: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    label: PropTypes.string,
    source: PropTypes.string,
    options: PropTypes.object,
    optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
    optionValue: PropTypes.string,
    initialValue: PropTypes.object,
    validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func, PropTypes.element]),
    onChange: PropTypes.func,
    row: PropTypes.bool,
    fullWidth: PropTypes.bool,
    resource: PropTypes.string,
    translateChoice: PropTypes.bool,
    reorderableRightInput: PropTypes.bool,
};

TransferInput.defaultProps = {
    options: {},
    optionText: 'name',
    optionValue: 'id',
    translateChoice: true,
    fullWidth: true,
    row: true,
};

export default TransferInput;
