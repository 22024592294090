import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useFormState, useForm } from 'react-final-form';
import { BooleanInput, required, TextInput, SelectInput, CheckboxGroupInput, useRecordContext } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DateTimeInput } from '../../lib/customComponents/formComponents';
import RecurrenceIntervalInput from './RecurrenceIntervalInput';
import showViewStyles from '../../lib/styles/showViewStyles';
import { frequencyDayChoices, intervalChoices, frequencyMonthDayChoices, frequencyChoices } from './userReportFormData';
import { mustNotBePastDate, mustBeFutureDate } from '../../lib/customComponents/formComponents/validation';
import { requestGetByID } from '../../../dataProvider/RestClient';

const useStyles = makeStyles(showViewStyles);
const daysOfWeek = frequencyDayChoices.map(day => day.name);

export const GeneralInputs = ({ edit = false, ...rest }) => {
    const record = useRecordContext(rest);
    const { fullWidth } = rest;
    const classes = useStyles();
    const classNameOveride = !fullWidth ? classes.columnShowView : undefined;

    const isRecurringEdit = 'recurring' === record.classification;

    return (
        <>
            <div className={classNameOveride}>
                <TextInput source="description" validate={[required()]} {...rest} />
            </div>
            <div className={classNameOveride}>
                <DateTimeInput
                    type="date"
                    source="started_at"
                    label="Start Date"
                    options={{ disabled: isRecurringEdit, disablePast: true }}
                    validate={isRecurringEdit ? [required()] : [required(), mustNotBePastDate]}
                    {...rest}
                />
            </div>
            <div className={classNameOveride}>
                <DateTimeInput
                    type="date"
                    source="ended_at"
                    label="Expiration Date"
                    options={{ disablePast: true }}
                    validate={[required(), mustNotBePastDate, mustBeFutureDate]}
                    {...rest}
                />
            </div>
            {edit && <BooleanInput label="Active" source="is_active" />}
        </>
    );
};

type TIntervalInputs = {
    [x: string]: any;
    edit?: boolean | undefined;
    analyticsReport?: boolean;
    schemaDateFields?: any;
    recordResource?: string;
    fullWidth?: boolean;
    record?: any;
};

export const IntervalInputs: React.FC<TIntervalInputs> = ({
    edit = false,
    analyticsReport,
    schemaDateFields,
    recordResource,
    fullWidth,
    ...rest
}) => {
    const [dateFieldsFromLocalSchemaCall, setDateFieldsFromLocalSchemaCall] = useState<any>([]);
    const [showIntervalField, setShowIntervalField] = useState<boolean>(true);
    const [disableIntervalField, setDisableIntervalField] = useState<boolean>(false);

    const record = useRecordContext(rest);

    const classes = useStyles();
    const form = useForm();
    const classNameOveride = !fullWidth ? classes.columnShowView : undefined;

    const rangeFilter =
        edit &&
        record &&
        record.request_payload.filters.conditions.find(item => !!item.field && 'interval' === item.operator);
    const { value, field } = rangeFilter;

    const getLocalSchemaFromResource = useCallback(() => {
        const queryResource =
            recordResource ||
            (record && record.settings && record.settings.config && record.settings.config.resource) ||
            undefined;
        const isAnalyticsEdit = undefined === queryResource && !!record;
        if (isAnalyticsEdit) {
            const {
                request_payload: {
                    filters: { conditions },
                },
            } = record;

            const prevIntervalVal = conditions && conditions[0] && conditions[0].field;
            if (prevIntervalVal) {
                setDateFieldsFromLocalSchemaCall([{ id: 1, name: prevIntervalVal }]);
                form.change('interval_field', prevIntervalVal);
            }
            setDisableIntervalField(true);
        } else {
            requestGetByID(queryResource, 'schema')
                .then(res => {
                    const timeDateFields = res.data.fields.filter(
                        dateField => 'datetime' === dateField.type || 'timestamp' === dateField.type
                    );
                    setDateFieldsFromLocalSchemaCall(timeDateFields);
                })
                .catch(err => console.error(err.message));
        }
    }, [form, record, recordResource]);

    useEffect(() => {
        if ((recordResource && recordResource.includes('schedule-report')) || analyticsReport) {
            setShowIntervalField(false);
        } else if (!schemaDateFields && !dateFieldsFromLocalSchemaCall.length) {
            setDateFieldsFromLocalSchemaCall([{ name: record.interval_field }]);
            getLocalSchemaFromResource();
        }
    }, [
        analyticsReport,
        dateFieldsFromLocalSchemaCall.length,
        getLocalSchemaFromResource,
        record.interval_field,
        recordResource,
        schemaDateFields,
    ]);

    return (
        <>
            <div className={classNameOveride}>
                {showIntervalField && (
                    <SelectInput
                        source="interval_field"
                        label="Interval Field"
                        choices={schemaDateFields || dateFieldsFromLocalSchemaCall}
                        disabled={disableIntervalField}
                        initialValue={edit && field}
                        optionValue="name"
                        validate={[required()]}
                        fullWidth={fullWidth}
                        {...rest}
                    />
                )}
            </div>
            <div className={classNameOveride}>
                <SelectInput
                    source="interval_date"
                    label="Interval Date Range"
                    choices={intervalChoices}
                    initialValue={edit && value}
                    optionValue="name"
                    validate={[required()]}
                    fullWidth={fullWidth}
                    {...rest}
                />
            </div>
        </>
    );
};

export const ScheduleInputs = ({ edit = false, ...rest }) => {
    const [hasInitialSchedule, setHasInitialSchedule] = useState(false);

    const formState = useFormState();
    const form = useForm();
    const classes = useStyles();

    const record = useRecordContext(rest);

    const { fullWidth } = rest;
    const classNameOveride = !fullWidth ? classes.columnShowView : undefined;
    const { values } = formState;
    const { recurrence_frequency: recurrenceFrequency } = values;

    const handleFrequencyChange = useCallback(
        value => {
            if ('Daily' === value) {
                form.change('recurrence_days', daysOfWeek);
                form.change('recurrence_month_days', []);
            } else if ('Weekly' === value) {
                form.change('recurrence_days', ['MO']);
                form.change('recurrence_month_days', []);
            } else if ('Monthly' === value) {
                form.change('recurrence_days', []);
                form.change('recurrence_month_days', [1, 15]);
            }
        },
        [form]
    );

    const getScheduleTime = useCallback(schedule => {
        const [time, zulu] = schedule.split('.');
        const [hour, minute, second] = time.split(':');
        const scheduleDate = moment().utcOffset(zulu).set({ hour, minute, second });
        return scheduleDate;
    }, []);

    useMemo(() => {
        if (edit && record && !hasInitialSchedule) {
            const schedule = getScheduleTime(record.schedule).local();
            form.change('schedule', schedule);
            setHasInitialSchedule(true);
        }
    }, [edit, form, getScheduleTime, hasInitialSchedule, record]);

    return (
        <>
            <div className={classNameOveride}>
                <SelectInput
                    source="recurrence_frequency"
                    label="Frequency"
                    choices={frequencyChoices}
                    initialValue={edit && record && record.recurrence && record.recurrence.frequency}
                    optionValue="name"
                    onChange={ev => handleFrequencyChange(ev.target.value)}
                    validate={[required()]}
                    {...rest}
                />
            </div>
            <RecurrenceIntervalInput className={classNameOveride} {...rest} />
            {recurrenceFrequency && 'Monthly' !== recurrenceFrequency && (
                <div className={!fullWidth ? classes.singleColumn : undefined}>
                    <CheckboxGroupInput
                        source="recurrence_days"
                        label="Frequency Day of Week"
                        choices={frequencyDayChoices}
                        initialValue={edit && record && record.recurrence && record.recurrence.days}
                        optionValue="name"
                        optionText="label"
                        validate={'Monthly' === recurrenceFrequency ? null : [required()]}
                        {...rest}
                    />
                </div>
            )}
            {recurrenceFrequency && 'Monthly' === recurrenceFrequency && (
                <div className={!fullWidth ? classes.singleColumn : undefined}>
                    <CheckboxGroupInput
                        source="recurrence_month_days"
                        label="Frequency Days of Month"
                        choices={frequencyMonthDayChoices}
                        initialValue={edit && record && record.recurrence && record.recurrence.month_days}
                        optionValue="name"
                        optionText="label"
                        validate={'Monthly' === recurrenceFrequency ? [required()] : null}
                        {...rest}
                    />
                </div>
            )}
            <div className={classNameOveride}>
                <DateTimeInput
                    type="time"
                    source="schedule"
                    label="Scheduled Delivery Time"
                    validate={[required()]}
                    {...rest}
                />
            </div>
        </>
    );
};
