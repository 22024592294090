import React from 'react';
import get from 'lodash/get';
import { useRecordContext, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { requestDownloadAttachment } from '../../../../dataProvider/RestClient';
import { saveFile } from './AttachmentPreviewField/helpers';

const useStyles = makeStyles(
    {
        attachments: {
            display: 'inline-block',
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
            },
        },
    },
    { name: 'CustomFileField' }
);

const CustomFileField = ({ className, setIsDownloading, onDownload, ...props }) => {
    const classes = useStyles(props);

    const record = useRecordContext(props);
    const notify = useNotify();
    const { id, name } = record;

    const isNewFile = record.rawFile && record.rawFile instanceof File;

    const getFileDownload = React.useCallback(() => {
        setIsDownloading(true);
        requestDownloadAttachment(id)
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                saveFile(url, name);
                setIsDownloading(false);
                if (onDownload && 'function' === typeof onDownload) {
                    onDownload();
                }
            })
            .catch(err => {
                setIsDownloading(false);
                notify('Problem downloading attachment!', 'warning');
            });
    }, [id, name, notify, onDownload, setIsDownloading]);

    const handleClick = React.useCallback(() => {
        if (isNewFile) {
            // record.path refers to the hard-coded "source" in CustomFileField
            saveFile(record.path, record.rawFile.path);
        }
        if (id) {
            getFileDownload();
        }
    }, [getFileDownload, id, isNewFile, record]);

    const titleValue = isNewFile ? record.rawFile.path : get(record, 'name') || 'name';

    return (
        <div role="presentation" className={classnames(classes.attachments, className)} onClick={handleClick}>
            <a title={titleValue}>{titleValue}</a>
        </div>
    );
};

export default CustomFileField;
