import React from 'react';
import { Labeled, useRecordContext } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Collapse, IconButton, Typography } from '@material-ui/core';
import { RemoveCircleOutline as RemoveIcon, AddCircleOutline as AddIcon } from '@material-ui/icons';
import FilePreview from './FilePreview';
import ImagePreview from './ImagePreview';
import showViewStyles from '../../../styles/showViewStyles';
import { LoadingWindow } from '../../index';

const useStyles = makeStyles(showViewStyles);

const getFilteredImages = (files, printView = false) => {
    if (!files) {
        return [];
    }
    const filteredFiles = files.filter(item => /\.(gif|jpg|jpeg|tiff|png|bmp|svg)$/i.test(item.path));

    if (!printView) {
        return filteredFiles;
    }

    return filteredFiles.filter(file => file.is_locked);
};

const getFilteredFiles = files =>
    files && files.filter(item => /\.(msg|xls|xlsx|xlsm|csv|pdf|tsx|doc|docx|txt)$/i.test(item.path));

const AttachmentPreviewField = ({ setIsLoadingQRForPrint, ...props }) => {
    const [isOpen, setIsOpen] = React.useState(true);
    const [isDownloading, setIsDownloading] = React.useState(false);

    const record = useRecordContext(props);
    const { attachments } = record;
    const { printView } = props;

    const classes = useStyles();

    const filteredImages = getFilteredImages(attachments, printView) || [];
    const filteredFiles = printView ? [] : getFilteredFiles(attachments) || [];

    React.useEffect(() => {
        if (printView && 0 === filteredImages.length) {
            setIsLoadingQRForPrint(false);
        }
    }, [filteredImages.length, setIsLoadingQRForPrint, printView]);

    if (printView && 0 === filteredImages.length) {
        return null;
    }

    if (filteredImages || filteredFiles) {
        return (
            <>
                {isDownloading && (
                    <LoadingWindow
                        isLoading={isDownloading}
                        simpleMessage
                        // completed={progress.percent}
                        linear
                        content="Downloading attachment..."
                    />
                )}
                <Labeled label="Attachments">
                    <>
                        <IconButton onClick={() => setIsOpen(!isOpen)} size="small">
                            {isOpen ? <RemoveIcon /> : <AddIcon />}
                        </IconButton>
                        <Collapse in={isOpen} timeout="auto">
                            {(filteredImages && filteredImages.length) || (filteredFiles && filteredFiles.length) ? (
                                <ul className={classes.attchmmentsList}>
                                    {filteredImages.map((image, index) => (
                                        <ImagePreview
                                            key={`image${index}`}
                                            image={image}
                                            className={classes.attachments}
                                            setIsDownloading={setIsDownloading}
                                            setIsLoadingQRForPrint={setIsLoadingQRForPrint}
                                        />
                                    ))}
                                    {filteredFiles.map((file, index) => (
                                        <FilePreview
                                            key={`file${index}`}
                                            file={file}
                                            className={classes.attachments}
                                            setIsDownloading={setIsDownloading}
                                        />
                                    ))}
                                </ul>
                            ) : (
                                <Typography variant="h6" gutterBottom align="center">
                                    No Attachments found
                                </Typography>
                            )}
                        </Collapse>
                    </>
                </Labeled>
            </>
        );
    }
    return null;
};

export default AttachmentPreviewField;
