import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    TopToolbar,
    CreateButton,
    RefreshButton,
    ShowButton,
    ListButton,
    sanitizeListRestProps,
    useListContext,
    useResourceContext,
    useResourceDefinition,
    usePermissions,
} from 'react-admin';
import ExportAllButton from './ExportAllButton';
import ExportSingleButton from './ExportSingleButton';
import { CustomEditButton as EditButton } from '../MarmeCustomComponents';
import { CustomizeColumnsButton, SaveViewButton, ExportSelectedButton } from '../customComponents/showComponents';
import { useViewContext } from '../contexts/ViewContext';
import { shouldRender } from '.';

const useStyles = makeStyles({
    mobileToolbar: {
        marginBottom: '1em',
    },
});

const sanitizeCustomActions = ({
    currentSort,
    total,
    hasEdit,
    hasShow,
    hasList,
    syncWithLocation,
    title,
    ...rest
}: any) => sanitizeListRestProps(rest);

const CustomActionsList: ({
    basePath,
    className,
    children,
    permanentFilter,
    exporter,
    filters,
    resourceOverride,
    hasExport,
    ...rest
}: {
    [x: string]: any;
    basePath?: any;
    className?: any;
    children?: any;
    permanentFilter?: any;
    exporter?: any;
    filters?: any;
    resourceOverride?: any;
    hasExport?: boolean;
}) => JSX.Element = ({
    basePath,
    className,
    children,
    permanentFilter,
    exporter,
    filters,
    resourceOverride,
    hasExport = true,
    ...rest
}) => {
    const classes = useStyles();
    const resource = useResourceContext();
    const { hasCreate } = useResourceDefinition(rest);
    const { showFilter, displayedFilters, filterValues } = useListContext(rest);
    const { permissions } = usePermissions();
    const isNotSmall = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const viewContext = useViewContext();

    const hasContext = viewContext && null !== viewContext.columns;
    const exportButton = hasContext ? (
        <ExportSelectedButton resourceOverride={resourceOverride} permanentFilter={permanentFilter} />
    ) : (
        <ExportAllButton resourceOverride={resourceOverride} permanentFilter={permanentFilter} />
    );

    const userAllowed = React.useMemo(() => shouldRender(permissions, resource, 'can_create'), [permissions, resource]);

    return (
        <TopToolbar className={isNotSmall ? className : classes.mobileToolbar} {...sanitizeCustomActions(rest)}>
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {children}
            {hasCreate && userAllowed && <CreateButton basePath={basePath} />}
            {hasExport && exporter && exportButton}
            <RefreshButton />
            {isNotSmall && hasContext && (
                <>
                    <CustomizeColumnsButton /> <SaveViewButton />
                </>
            )}
        </TopToolbar>
    );
};

const CustomActionsEditForm: ({
    basePath,
    data,
    children,
    ...props
}: {
    [x: string]: any;
    basePath?: string;
    data?: any;
    children?: any;
}) => JSX.Element = ({ basePath, data, children, ...props }) => {
    const { hasShow, hasList } = useResourceDefinition(props);

    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, {
            basePath,
            record: data,
        })
    );

    return (
        <TopToolbar>
            {childrenWithProps}
            {true === hasShow && <ShowButton basePath={basePath} record={data} />}
            {hasList && <ListButton basePath={basePath} />}
            <RefreshButton />
        </TopToolbar>
    );
};

const CustomActionsShowView: ({
    basePath,
    data,
    children,
    recordAllowed,
    userAllowed,
    print,
    isDrawer,
    redirectPath,
    ...props
}: {
    [x: string]: any;
    basePath?: any;
    data?: any;
    children?: any;
    recordAllowed?: any;
    userAllowed?: any;
    print?: any;
    isDrawer?: any;
    redirectPath?: any;
}) => JSX.Element = ({
    basePath,
    data,
    children,
    print,
    isDrawer,
    redirectPath,
    recordAllowed = true,
    userAllowed = (permissionsProp, resourceProp) => shouldRender(permissionsProp, resourceProp, 'can_update'),
    ...props
}) => {
    const resource = useResourceContext();
    const { hasEdit, hasList } = useResourceDefinition(props);
    const { permissions } = usePermissions();

    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, {
            basePath,
            record: data,
        })
    );

    const isAllowed = React.useMemo(() => {
        let actionsRecordAllowed = recordAllowed;

        if ('function' === typeof recordAllowed) {
            actionsRecordAllowed = true === recordAllowed(data);
        }

        return actionsRecordAllowed && true === userAllowed(permissions, resource);
    }, [data, permissions, recordAllowed, resource, userAllowed]);

    return (
        <TopToolbar>
            {childrenWithProps}
            {isDrawer && <ShowButton basePath={basePath} record={data} />}
            {hasEdit && isAllowed && <EditButton basePath={basePath} record={data} redirectPath={redirectPath} />}
            {null !== print && print}
            {data && (
                <ExportSingleButton
                    resource={resource}
                    sort={{ field: 'id', order: 'DESC' }}
                    filterValues={{ id: data.id }}
                />
            )}
            {hasList && <ListButton basePath={basePath} />}
            {!isDrawer && <RefreshButton />}
        </TopToolbar>
    );
};

export { CustomActionsEditForm, CustomActionsList, CustomActionsShowView };
