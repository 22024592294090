import React from 'react';
import { warning } from 'react-admin';
import lodash from 'lodash';
import { useViewContext } from '../../contexts/ViewContext';

const validateColumns = columnsList =>
    columnsList.filter(
        field =>
            field !== undefined &&
            field &&
            field.props &&
            field.props.source &&
            'undefined' !== typeof field.props.source
    );

const ColumnsWrapper = ({ children: displayComponent, ...props }) => {
    const [mergedColumns, setMergedColumns] = React.useState<Array<any> | null>(null);
    const { columns, setColumns } = useViewContext()!;

    warning(
        displayComponent === undefined,
        `You must provide a display component (e.g. Datagrid) inside a ColumnsWrapper`
    );

    const { children: displayedFields } = displayComponent.props;

    React.useEffect(() => {
        if (columns && columns.length && displayedFields && displayedFields.length && !mergedColumns) {
            // Fix: For buttons and other non-display children
            const validColumns = validateColumns(displayedFields);
            const columnsCopy = [...columns];

            validColumns.forEach(({ props: { source, label, type } }) => {
                const colIndex = columns.findIndex(i => i.source === source);

                columnsCopy.splice(colIndex, 1, {
                    source,
                    label: label || source,
                    active: true,
                });
            });

            setMergedColumns(columnsCopy);
        }
    }, [columns, displayedFields, mergedColumns]);

    React.useEffect(() => {
        if (mergedColumns && lodash.isEqual(mergedColumns, columns)) {
            setColumns(mergedColumns);
        }
    }, [columns, mergedColumns, setColumns]);

    if (displayComponent) {
        return React.cloneElement(displayComponent, {
            children: displayComponent.props.children,
        });
    }

    return null;
};

export default ColumnsWrapper;
