import axios from 'axios';
import { stringify } from 'query-string';
import setQuery from './setQuery';
import apiClient from './apiClient';

const apiUrl = process.env.XHR_URL;

const bypassFetchResources = [];

export const resourceMapping = {};

const removeAttachments = data => {
    const newPayload = data;

    delete newPayload.attachments;
    delete newPayload.deleted_attachments;

    return newPayload;
};

const dataProvider = {
    getList: (resource, params) => {
        if (bypassFetchResources.includes(resource)) {
            return Promise.resolve({
                data: [],
                total: 1,
            });
        }

        const mappedResource = resourceMapping[resource] || resource;

        const query = setQuery(params);

        const newURL = `${mappedResource}?${stringify(query)}`;
        return apiClient.get(newURL).then(({ headers, status, data }) => {
            if (205 === status) {
                return {
                    data: [],
                    total: 0,
                };
            }
            return {
                data: data.data || [],
                total: parseInt(headers['content-range'].split('/').pop(), 10),
            };
        });
    },

    getListWithPages: (resource, params) => {
        if (bypassFetchResources.includes(resource)) {
            return Promise.resolve({
                data: [],
                total: 1,
            });
        }

        const mappedResource = resourceMapping[resource] || resource;

        const query = setQuery(params);

        const newURL = `${mappedResource}?${stringify(query)}`;
        return apiClient.get(newURL).then(({ status, data }) => {
            if (205 === status) {
                return {
                    data: [],
                    total: 0,
                };
            }
            return data;
        });
    },

    getOne: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;

        if ('users' === mappedResource) {
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            const userID = user && user.id;
            if (userID && params.id && parseInt(params.id) === userID) {
                return apiClient.get('user').then(res => ({ data: res.data.data }));
            }
        }

        return apiClient(`${mappedResource}/${params.id}`).then(res => ({
            data: res.data.data,
        }));
    },

    getOneWithQuery: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;

        const { id, fields, relations, join } = params;
        const query = {
            fields: JSON.stringify(fields),
            relations: JSON.stringify(relations),
            join,
        };
        return apiClient(`${mappedResource}/${id}?${stringify(query)}`).then(res => ({
            data: res.data.data,
        }));
    },

    getMany: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;

        const query = {
            filters: JSON.stringify({
                logic: 'and',
                conditions: [{ field: 'id', operator: 'in', value: params.ids }],
            }),
            range: JSON.stringify([0, 499]),
            relations: JSON.stringify(params.relations),
            join: params.join,
        };

        return apiClient(`${mappedResource}?${stringify(query)}`).then(({ data, status }) => {
            if (205 === status) {
                return {
                    data: [],
                    total: 0,
                };
            }
            return {
                data: data.data,
            };
        });
    },

    getManyReference: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };

        return apiClient.get(`${mappedResource}?${stringify(query)}`).then(({ status, data }) => {
            if (205 === status) {
                return {
                    data: [],
                    total: 0,
                };
            }
            return data;
        });
    },

    getAudits: params => {
        const auditsQuery = setQuery(params);
        const auditsURL = `audits?${stringify(auditsQuery)}`;

        return apiClient.get(auditsURL).then(({ headers, status, data }) => {
            if (205 === status) {
                return {
                    data: [],
                    total: 0,
                };
            }
            return {
                data: data.data || [],
                total: parseInt(headers['content-range'].split('/').pop(), 10),
            };
        });
    },

    getNotificationsList: () =>
        apiClient.get('user/notifications').then(({ headers, status, data }) => {
            if (205 === status) {
                return {
                    data: [],
                    total: 0,
                };
            }
            return {
                data: data.data || [],
                total: parseInt(headers['content-range'].split('/').pop(), 10),
            };
        }),

    getCurrentUser: () => apiClient.get('user').then(res => res.data.data),

    updateCurrentUser: params => apiClient.put('user', params.data).then(({ data }) => data),

    create: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;
        const parsedData = removeAttachments(params.data);

        if (mappedResource.includes('export-to-csv') || mappedResource.includes('schedule-report')) {
            const reportConfig = {
                headers: {
                    Accept: 'text/csv',
                },
            };
            return apiClient.post(mappedResource, parsedData, reportConfig).then(({ data }) => {
                const { status } = data;
                if (202 === status) {
                    return {
                        data: { ...params.data, id: Infinity },
                    };
                }
                return data;
            });
        }

        return apiClient.post(mappedResource, parsedData).then(({ data }) => {
            const { status } = data;
            if (202 === status) {
                return {
                    data: { ...params.data, id: Infinity },
                };
            }
            return data;
        });
    },

    update: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;

        const parsedData = removeAttachments(params.data);

        return apiClient.put(`${mappedResource}${params.id ? `/${params.id}` : ''}`, parsedData).then(({ data }) => {
            const { status } = data;
            if (202 === status) {
                return {
                    data: params.data,
                    total: 1,
                };
            }
            return data;
        });
    },

    updateMany: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;

        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };

        return apiClient.put(`${mappedResource}?${stringify(query)}`).then(res => res.data);
    },

    setStatus: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;

        const { status, id, data } = params;

        return apiClient.put(`${mappedResource}/${id}/${status}`, data).then(res => res.data);
    },

    addAttachments: (resource, id, files) => {
        const bodyFormData = new FormData();

        files.forEach((file, index) => {
            bodyFormData.append(`files[${index}]`, file.rawFile);
        });

        bodyFormData.append('entity', resource);
        bodyFormData.append('entity_id', id);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        return apiClient.post('attachments', bodyFormData, config).then(({ data }) => data);
    },

    downloadAttachment: id =>
        apiClient.get(`attachments/${id}/download`, { responseType: 'blob' }).then(response => response.data),

    downloadArrayBufferImage: id => apiClient.get(`attachments/${id}/download`).then(response => response.data),

    downloadReport: id =>
        apiClient.get(`user/reports/lines/${id}/download`, { responseType: 'blob' }).then(response => response.data),

    deleteAttachments: (resource, id, filePaths) => {
        const query = {
            entity: resource,
            entity_id: id,
            paths: filePaths,
        };

        return apiClient.delete('attachments', { data: query }).then(response => response.data);
    },

    updatePassword: payload => apiClient.put('update-password', payload).then(({ data }) => data),

    forgotPassword: payload => axios.post(`${apiUrl}/auth/forgot-password`, payload).then(({ data }) => data),

    delete: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;

        return apiClient.delete(`/${mappedResource}/${params.id}`).then(({ data }) => data);
    },

    deleteMany: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;

        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };

        return apiClient.delete(`/${mappedResource}?${stringify(query)}`).then(({ data }) => data);
    },
};

export default dataProvider;
