import { createStyles } from '@material-ui/core/styles';

const styles = theme =>
    createStyles({
        columnShowView: {
            display: 'inline-block',
            [theme.breakpoints.down('sm')]: { width: '100%' },
            '@media print': { width: '50%', '& div': { maxWidth: '35vw' } },
            width: '50%',
            '& span': { width: '100%' },
            '& div': { [theme.breakpoints.up('sm')]: { maxWidth: '70vw' } },
        },
        columnForceWidth: {
            display: 'inline-block',
            [theme.breakpoints.down('sm')]: { width: '100%' },
            '@media print': { width: '50%', '& div': { maxWidth: '35vw', width: '256px' } },
            width: '50%',
            '& div': { [theme.breakpoints.up('sm')]: { maxWidth: '35vw' }, width: '256px' },
        },
        container: { display: 'grid', gridTemplateColumns: 'auto auto' },
        smallModal: { width: '16em' },
        fullScreen: { width: '100%', display: 'inline-block', '& div': { width: '100%' } },
        singleColumn: { width: '100%', display: 'inline-block' },
        attchmmentsList: { listStyleType: 'none', display: 'inline', maxWidth: '100%' },
        attachments: {
            maxHeight: '15rem',
            margin: '0.5rem',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        drawer: {
            '& span': {
                display: 'inline-block',
                minWidth: '25vw',
                maxWidth: '80vw',
            },
        },
        button: {
            background: 'none',
            border: 'none',
            color: 'blue',
            cursor: 'pointer',
            textDecoration: 'underline',
            fontFamily: 'Roboto',
        },
        largeDropDown: {
            minWidth: '9em',
        },
        mediumDropDown: {
            minWidth: '5em',
        },
    });

export default styles;
