import React, { useCallback } from 'react';
import { MenuItem } from '@material-ui/core';
import { OptionsMenu } from '../../../Components/lib/customComponents/showComponents';

interface IOptionsButton {
    handleNotificationClick: any;
    redirect: any;
    id: number;
    isRead: boolean;
}

const OptionsButton: React.FC<IOptionsButton> = ({ handleNotificationClick, isRead, id, redirect }) => {
    const handleClick = useCallback(
        (type: string) => {
            if ('redirect' === type) {
                return handleNotificationClick('read', id, redirect);
            }
            return handleNotificationClick(type, id);
        },
        [handleNotificationClick, id, redirect]
    );

    return (
        <OptionsMenu>
            <MenuItem onClick={() => handleClick(isRead ? 'unread' : 'read')}>{`Mark as ${
                isRead ? 'Unread' : 'Read'
            }`}</MenuItem>
            {redirect && <MenuItem onClick={() => handleClick('redirect')}>{redirect.label}</MenuItem>}
            <MenuItem onClick={() => handleClick('inactive')}>Remove this notification</MenuItem>
        </OptionsMenu>
    );
};

export default OptionsButton;
