import React from 'react';
import classnames from 'classnames';
import { Title, useTranslate } from 'react-admin';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import ReportIcon from '@material-ui/icons/Report';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    theme => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                padding: '1em',
            },
            fontFamily: 'Roboto, sans-serif',
            opacity: 0.5,
        },
        title: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            width: '2em',
            height: '2em',
            marginRight: '0.5em',
        },
        panel: {
            marginTop: '1em',
        },
        panelDetails: {
            whiteSpace: 'pre-wrap',
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            marginTop: '2em',
        },
    }),
    { name: 'RaError' }
);

function goBack() {
    window.history.go(-1);
}

function refresh() {
    window.location.reload();
}

const Error = ({ error, errorInfo, classes: classesOverride, className, title, ...rest }) => {
    const classes = useStyles({ classes: classesOverride });
    const translate = useTranslate();

    return (
        <>
            <Title defaultTitle={title} />
            <div className={classnames(classes.container, className)} {...rest}>
                <h1 className={classes.title} role="alert">
                    <ReportIcon className={classes.icon} />
                    {translate('ra.page.error')}
                </h1>
                <div>{translate('ra.message.error')}</div>
                {'production' !== process.env.NODE_ENV && (
                    <Accordion className={classes.panel}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {translate('ra.message.details')}
                        </AccordionSummary>
                        <AccordionDetails className={classes.panelDetails}>
                            <div>
                                <h2>{translate(error.toString())}</h2>
                                {errorInfo.componentStack}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )}
                <div className={classes.toolbar}>
                    <Button variant="contained" onClick={refresh}>
                        <HistoryIcon />
                        Refresh
                    </Button>
                    <Button variant="contained" onClick={goBack}>
                        <HistoryIcon />
                        {translate('ra.action.back')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Error;
