import React, { useState, useEffect, useCallback } from 'react';
import { BooleanInput, PasswordInput, SimpleForm, TextInput, useLogin, required, email } from 'react-admin';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Card, Avatar, Button, CircularProgress, Typography } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { useLocation } from 'react-router';
import LockIcon from '@material-ui/icons/Lock';
import ResetPasswordRequestForm from './ResetPasswordRequestForm';

const lightTheme = {
    palette: {
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
    },
};

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        background: 'none',
    },
    card: {
        justifyContent: 'center',
        minWidth: 300,
        marginTop: '6em',
    },
    smallForm: { width: '18em', margin: 'auto' },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

const LoginInputs = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [prevPath, setPrevPath] = useState('/');
    const [showErrMessage, setShowErrMessage] = useState(false);
    const [showPasswordResetMessage, setShowPasswordResetMessage] = useState(false);

    const classes = useStyles();
    const login = useLogin();
    const location = useLocation();
    const formState = useFormState();

    const { values, errors } = formState;
    const { email: emailValue, password: passwordValue, remember: rememberValue } = values;

    const maybeLoginFromURL = useCallback((loc, loginCallback) => {
        const provider = loc.search.replace('?provider=', '');

        if ('azure' === provider) {
            setIsLoading(true);
            return loginCallback({ provider });
        }
    }, []);

    const loginWithLaravel = useCallback(
        ({ username, password, remember, provider }) =>
            login({ username, password, remember, provider }, prevPath).catch(err => {
                setIsLoading(false);
                setShowErrMessage(true);
            }),
        [login, prevPath]
    );

    useEffect(() => {
        if (location.state && location.state.nextPathname) {
            setPrevPath(location.state.nextPathname);
        }

        maybeLoginFromURL(location, loginWithLaravel);
    }, [location, loginWithLaravel, maybeLoginFromURL]);

    const handleSubmit = useCallback(() => {
        setShowErrMessage(false);
        setIsLoading(true);

        const loginPayload = { username: emailValue, password: passwordValue, remember: rememberValue };

        loginWithLaravel(loginPayload, prevPath);
    }, [emailValue, loginWithLaravel, passwordValue, prevPath, rememberValue]);

    if (isLoading) {
        return <CircularProgress size={150} style={{ marginLeft: '3.5em', marginTop: '3em' }} />;
    }

    return (
        <div className={classes.smallForm}>
            <TextInput
                source="email"
                label="Email"
                type="email"
                style={{ width: '100%' }}
                validate={[required(), email()]}
            />
            <PasswordInput source="password" label="Password" style={{ width: '100%' }} validate={[required()]} />
            <BooleanInput source="remember" label="Remember Me" />
            {showErrMessage && (
                <Typography color="error" variant="body2" gutterBottom align="center">
                    Login failed
                </Typography>
            )}
            {showPasswordResetMessage && (
                <Typography color="primary" variant="body2" gutterBottom align="center">
                    Password reset email sent
                </Typography>
            )}
            <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!!(errors && (errors.email || errors.password))}
                size="medium"
                style={{ width: '100%' }}
            >
                Sign In
            </Button>
            <ResetPasswordRequestForm setShowPasswordResetMessage={setShowPasswordResetMessage} />
        </div>
    );
};

const CustomLogin = () => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <Card className={classes.card}>
                <div className={classes.avatar}>
                    <Avatar className={classes.icon}>
                        <LockIcon />
                    </Avatar>
                </div>
                <SimpleForm toolbar={false}>
                    <LoginInputs />
                </SimpleForm>
            </Card>
        </div>
    );
};

const LoginWithTheme = props => (
    <ThemeProvider theme={createTheme(lightTheme)}>
        <CustomLogin {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;
