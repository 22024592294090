import React from 'react';
import { Create, Edit, FormTab, SimpleForm, TabbedForm, useRedirect } from 'react-admin';
import { CustomToolbar, ResponsiveForm } from '../../lib/customComponents/formComponents';
import { CustomActionsEditForm } from '../../lib/helpers/customActions';
import { GeneralInputs, IntervalInputs, ScheduleInputs } from './UserReportFormInputs';
import { transform as transformReportInputs } from '../../Reports/CreateReportButton';

type TTabbedCreate = {
    [x: string]: any;
    analyticsReport?: boolean;
    fullWidth?: boolean;
};

const sanitizeRestProps = ({ transform, resourceOverride, ...rest }: any) => rest;

const sanitizeToolbarProps = ({ recordResource, hasList, hasEdit, hasShow, hasCreate, ...rest }: any) => rest;

export const TabbedCreate: React.FC<TTabbedCreate> = ({ analyticsReport, fullWidth, ...props }) => (
    <Create {...sanitizeRestProps(props)}>
        <TabbedForm toolbar={<CustomToolbar {...sanitizeToolbarProps(props)} />} redirect="list">
            <FormTab label="General">
                <GeneralInputs fullWidth={fullWidth} />
            </FormTab>
            <FormTab label="Interval">
                <IntervalInputs
                    recordResource={props.resource}
                    analyticsReport={analyticsReport}
                    fullWidth={fullWidth}
                />
            </FormTab>
            <FormTab label="Schedule">
                <ScheduleInputs fullWidth={fullWidth} />
            </FormTab>
        </TabbedForm>
    </Create>
);

const SimpleCreate = ({ analyticsReport, ...props }) => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar {...sanitizeToolbarProps(props)} />} redirect="list">
            <GeneralInputs />
            <IntervalInputs analyticsReport={analyticsReport} />
            <ScheduleInputs />
        </SimpleForm>
    </Create>
);

export const UserReportCreateForm = ({ analyticsReport = false, ...props }) => {
    const redirect = useRedirect();

    const { permissions } = props;

    React.useEffect(() => {
        if (!permissions) {
            redirect('/unauthorized');
        }
    }, [permissions, redirect]);

    return (
        <ResponsiveForm
            simpleForm={<SimpleCreate analyticsReport={analyticsReport} {...props} />}
            tabbedForm={<TabbedCreate analyticsReport={analyticsReport} {...props} />}
        />
    );
};

const TabbedEdit = props => (
    <Edit undoable={false} actions={<CustomActionsEditForm />} {...props}>
        <TabbedForm toolbar={<CustomToolbar {...sanitizeToolbarProps(props)} />} redirect="list">
            <FormTab label="General">
                <GeneralInputs edit />
            </FormTab>
            <FormTab label="Interval">
                <IntervalInputs edit />
            </FormTab>
            <FormTab label="Schedule">
                <ScheduleInputs edit />
            </FormTab>
        </TabbedForm>
    </Edit>
);

const SimpleEdit = props => (
    <Edit undoable={false} actions={<CustomActionsEditForm />} {...props}>
        <SimpleForm toolbar={<CustomToolbar {...sanitizeToolbarProps(props)} />} redirect="list">
            <GeneralInputs edit />
            <IntervalInputs edit />
            <ScheduleInputs edit />
        </SimpleForm>
    </Edit>
);

export const UserReportEditForm = (props: any) => {
    const redirect = useRedirect();

    const { permissions } = props;

    React.useEffect(() => {
        if (!permissions) {
            redirect('/unauthorized');
        }
    }, [permissions, redirect]);

    return (
        <ResponsiveForm
            simpleForm={<SimpleEdit transform={transformReportInputs} {...props} />}
            tabbedForm={<TabbedEdit transform={transformReportInputs} {...props} />}
        />
    );
};
