import React from 'react';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import { TextInput, required, email } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { DialogForm, SimpleFormWrapper } from '../Components/lib/customComponents/formComponents';
import { forgotPassword } from '../dataProvider/RestClient';

const useStyles = makeStyles(theme => ({
    form: {
        alignContent: 'center',
    },
}));

const ResetPasswordRequestInputs: ({
    setShowPasswordResetMessage,
    closeDialog,
    ...props
}: {
    [x: string]: any;
    setShowPasswordResetMessage?: React.Dispatch<React.SetStateAction<boolean>>;
    closeDialog?: () => void;
}) => JSX.Element = ({ setShowPasswordResetMessage, closeDialog, ...props }) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [showErrMessage, setShowErrMessage] = React.useState<boolean>(false);

    const classes = useStyles();
    const formState = useFormState();
    const form = useForm();
    const { values, invalid } = formState;
    const { reset_email: resetEmail } = values;

    const closeAndReset = React.useCallback(
        (close = false) => {
            if (close) {
                closeDialog();
            }
            setIsLoading(false);
            form.change('reset_email', null);
        },
        [closeDialog, form]
    );

    const handleResetClick = React.useCallback(() => {
        setShowErrMessage(false);
        setIsLoading(true);
        forgotPassword({ email: resetEmail })
            .then(res => {
                closeAndReset(true);
                setShowPasswordResetMessage(true);
            })
            .catch(err => {
                setShowErrMessage(true);
                closeAndReset(false);
            });
    }, [closeAndReset, resetEmail, setShowPasswordResetMessage]);

    return (
        <div className={classes.form}>
            {isLoading ? (
                <CircularProgress size={100} style={{ marginLeft: '8em' }} />
            ) : (
                <>
                    <Typography variant="body2" gutterBottom align="center">
                        Enter your email to receive a link to reset your password
                    </Typography>
                    <TextInput
                        label="Email"
                        source="reset_email"
                        type="email"
                        validate={[required(), email()]}
                        fullWidth
                    />
                    {showErrMessage && (
                        <Typography color="error" variant="body2" gutterBottom align="center">
                            Problem sending password reset email
                        </Typography>
                    )}
                </>
            )}
            <Button
                name="update"
                variant="contained"
                type="submit"
                color="primary"
                disabled={isLoading || invalid}
                fullWidth
                onClick={() => handleResetClick()}
            >
                Send Reset Link
            </Button>
        </div>
    );
};

const ResetPasswordRequestForm = ({ setShowPasswordResetMessage, ...props }) => (
    <DialogForm title="Send reset password link" label="Forgot Password?" maxWidth="sm">
        <SimpleFormWrapper>
            <ResetPasswordRequestInputs setShowPasswordResetMessage={setShowPasswordResetMessage} {...props} />
        </SimpleFormWrapper>
    </DialogForm>
);

export default ResetPasswordRequestForm;
