import axios from 'axios';
import authProvider from '../authProvider';

const clientConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    maxContentLength: Infinity,
};

if ('development' === process.env.REACT_APP_APP_ENV) {
    clientConfig.headers = {
        common: {
            Authorization: `Bearer ${process.env.REACT_APP_PERSONAL_TOKEN}`,
        },
    };
}

const apiClient = axios.create(clientConfig);

apiClient.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status) {
            const { status } = error.response;
            if (419 === status) {
                const { config } = error.response;
                const { url } = config;
                if ('logout' !== url) {
                    return authProvider.logout();
                }
                return Promise.reject(error.response);
            }
            if (403 === status || 401 === status) {
                return Promise.reject(error.response);
            }
        }
        return Promise.reject(error.response);
    }
);

export default apiClient;
