import React from 'react';
import { SimpleShowLayout, Tab, usePermissions, useRecordContext, useResourceContext } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import showViewStyles from '../../styles/showViewStyles';
import { shouldRender, sanitizeTabbedShowProps } from '../../helpers';
import { DrawerFeatures, PrintTitle } from '.';
import { useCreateFields } from '../../hooks';

const sanitizeFieldProps = ({ passProps, passClasses, ...rest }: any) => rest;

const useStyles = makeStyles(showViewStyles);

const FilteredFieldsRenderer: ({
    getFieldsFunction,
    isTabbedShow,
    label,
    children,
    ...props
}: {
    [x: string]: any;
    getFieldsFunction: any;
    isTabbedShow?: boolean;
    label?: string;
    children?: any;
}) => JSX.Element = ({ getFieldsFunction, isTabbedShow = false, label = 'Overview', children, ...props }) => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    const resource = useResourceContext(props);
    const record = useRecordContext(props);

    const { isDrawer, recordAllowed, userAllowed } = props;

    const canReadFields = React.useMemo(
        () => shouldRender(permissions, resource, 'can_read_fields') || null,
        [permissions, resource]
    );

    const dyanmicFilteredFields: React.ReactNode[] = useCreateFields(getFieldsFunction, isDrawer, canReadFields);

    const fields = dyanmicFilteredFields
        .map((field, index) => {
            if (React.isValidElement(field)) {
                if (field.props && field.props.render && 'function' === typeof field.props.render) {
                    if (!field.props.render(record)) {
                        return null;
                    }
                }
                const fieldProps = {
                    addLabel: true,
                    ...sanitizeFieldProps(field.props),
                    key: `${field.props.source}-${index}`,
                };
                if (field.props && field.props.passClasses) {
                    fieldProps.classes = classes;
                } else {
                    const fieldClassName = isDrawer ? classes.drawer : classes.columnShowView;
                    fieldProps.className = field.props.className ? classes[field.props.className] : fieldClassName;
                }
                return React.cloneElement(
                    field,
                    field.props.passProps ? { ...fieldProps, ...sanitizeFieldProps(props) } : { ...fieldProps }
                );
            }
            return null;
        })
        .filter(field => !!field);

    return isTabbedShow ? (
        <Tab label={label} {...sanitizeTabbedShowProps(props)}>
            {fields}
            {children}
        </Tab>
    ) : (
        <SimpleShowLayout {...props}>
            <PrintTitle {...props} />
            {fields}
            {children}
            <DrawerFeatures recordAllowed={recordAllowed} userAllowed={userAllowed} {...props} />
        </SimpleShowLayout>
    );
};

export default FilteredFieldsRenderer;
