import React from 'react';
import { Title, Button, useNotify, usePermissions } from 'react-admin';
import moment from 'moment-timezone';
import { Avatar, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import RefreshIcon from '@material-ui/icons/Refresh';
import { AccessGrid, FieldsCell, UserSettingsTable } from '../Components/Admin/Users';
import { ItemsListing } from '../Components/lib/customComponents/showComponents';
import { useGetSessionExpires } from '../Components/lib/hooks';
import authProvider, { setLocalStorage } from '../authProvider';
import { requestGetCurrentUser } from '../dataProvider/RestClient';

const useStyles = makeStyles(theme => ({
    main: {
        minHeight: '100vh',
        background: 'none',
    },
    card: {
        justifyContent: 'center',
        minWidth: '90%',
        marginTop: '1em',
        padding: '1em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.primary.main,
    },
}));

const UserTimeData = props => {
    const [timeData, setTimeData] = React.useState({ current: null, timeZone: null, expires: null, warning: null });
    const { sessionExpires, currentTime, warningTime } = useGetSessionExpires(false);

    const userTimeZone = moment.tz.guess();
    const userTimeZoneAbbr = moment.tz(userTimeZone).zoneAbbr();

    React.useEffect(() => {
        setTimeData(prev => ({
            ...prev,
            current: currentTime,
            expires: sessionExpires,
            timeZone: userTimeZoneAbbr,
            warning: warningTime,
        }));
    }, [currentTime, sessionExpires, userTimeZoneAbbr, warningTime]);

    return (
        <ItemsListing
            columns={{
                timeZone: 'User Timezone',
                current: 'Current Time (On Page Load)',
                expires: 'Session Expires',
                warning: 'Warn Session Expires At',
            }}
            dataOverride={[timeData]}
            title="User Time Data"
            formatToDate={[
                { name: 'current', format: 'date-time-seconds' },
                { name: 'expires', format: 'date-time-seconds' },
                { name: 'warning', format: 'date-time-seconds' },
            ]}
            {...props}
        />
    );
};

const LocalStoragePermissions = props => {
    const [permissionsData, setPermissionsData] = React.useState([]);

    const { permissions, loading } = usePermissions();

    React.useEffect(() => {
        if (permissions && !permissionsData.length) {
            const permsArr = [];

            const sortedPermissions = Object.keys(permissions).sort();
            sortedPermissions.forEach(slug => {
                const val = permissions[slug];
                const { can_read_fields: canReadFields } = val;
                return permsArr.push({
                    permission: slug,
                    capabilities: val,
                    can_read_fields: canReadFields || [],
                });
            });
            setPermissionsData(permsArr);
        }
    }, [permissions, permissionsData.length]);

    if (loading) {
        return null;
    }

    return (
        <ItemsListing
            columns={{
                permission: 'Permission',
                capabilities: 'Capabilities',
                can_read_fields: 'Fields',
            }}
            dataOverride={permissionsData}
            title="Permissions"
            formatToCustom={[
                {
                    name: 'capabilities',
                    formatter: record => record && record.capabilities && AccessGrid(record.capabilities),
                },
                {
                    name: 'can_read_fields',
                    formatter: record => record && FieldsCell(record.can_read_fields),
                },
            ]}
            {...props}
        />
    );
};

const DebugWrapper = ({ children, actions, ...props }) => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <Card className={classes.card}>
                <Title title="App Debug" />
                <div className={classes.avatar}>
                    <Avatar className={classes.icon}>
                        <SettingsIcon />
                    </Avatar>
                </div>
                <Typography align="center" variant="h6" gutterBottom>
                    App Debug
                </Typography>
                <CardActions>{actions}</CardActions>
                <CardContent>{children}</CardContent>
            </Card>
        </div>
    );
};

const DebugPage = props => {
    const settings = JSON.parse(localStorage.getItem('userSettings'));

    const notify = useNotify();

    const refreshUser = React.useCallback(() => {
        requestGetCurrentUser()
            .then(user => {
                if (false === user.is_active) {
                    return authProvider.logout();
                }
                setLocalStorage({ user });
                notify('User record refreshed!', { type: 'success' });
                window.location.reload();
            })
            .catch(err => {
                notify('No current user found', { type: 'warning' });
                authProvider.logout();
            });
    }, [notify]);

    return (
        <DebugWrapper
            actions={
                <Button
                    label="Refresh User Record"
                    variant="contained"
                    size="medium"
                    startIcon={<RefreshIcon />}
                    onClick={refreshUser}
                />
            }
        >
            <UserTimeData bypassFetch {...props} />
            <UserSettingsTable settingsOverrideData={settings} bypassFetch {...props} />
            <LocalStoragePermissions bypassFetch {...props} />
        </DebugWrapper>
    );
};

export default DebugPage;
