import React from 'react';
import { TextField, usePermissions, useNotify, useRecordContext } from 'react-admin';
import { LinearProgress, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PanTool as PanToolIcon } from '@material-ui/icons';
import DrawerPreview from './Drawers/DrawerPreview';
import { requestGetByID } from '../../../../dataProvider/RestClient';
import { useReferenceSource } from '../../hooks';
import { shouldRender } from '../../helpers';
import RecordShowFields from './Drawers/RecordShowFields';

const useStyles = makeStyles(theme => ({
    link: {
        padding: '0',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        textTransform: 'none',
        textAlign: 'left',
        justifyContent: 'flex-start',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    progress: { marginTop: theme.spacing(2) },
}));

interface IReferenceDrawerField {
    className?: string;
    idSource?: string;
    descSource?: string;
    reference?: string;
    label?: string;
    record?: any;
    drawerRecord?: any;
    source: string;
    onClick?: (ev: any) => Promise<void>;
}

const sanitizeRestProps = ({ addLabel, basePath, label, ...rest }: any) => rest;

const ReferenceDrawerField: React.FC<IReferenceDrawerField> = React.memo(
    ({
        className,
        idSource: idOverride,
        descSource: descOverride,
        reference: refOverride,
        drawerRecord = {},
        source,
        onClick,
        ...rest
    }) => {
        const [showPanel, setShowPanel] = React.useState<boolean>(false);
        const [previewRecord, setPreviewRecord] = React.useState(drawerRecord);
        const [noRecordFound, setNoRecordFound] = React.useState<boolean>(false);
        const [isErrored, setIsErrored] = React.useState<boolean>(false);
        const [errorMessage, setErrorMessage] = React.useState<string>('Error while trying to load drawer data!');

        const record = useRecordContext(rest);
        const classes = useStyles();
        const notify = useNotify();

        const { descriptionField, idField, refResource, recordID } = useReferenceSource({
            source,
            record,
            idOverride,
            descOverride,
            refOverride,
        });

        const { loading, permissions } = usePermissions();

        const getShowPanelRecord = React.useCallback(
            () =>
                requestGetByID(refResource, recordID)
                    .then(res => {
                        if (!res.data) {
                            setNoRecordFound(true);
                        }
                        setPreviewRecord(res.data);
                    })
                    .catch(error => {
                        const {
                            status,
                            data: { message },
                        } = error;
                        if (status && 403 === status) {
                            setErrorMessage(`Permissions required to view ${refResource || 'this resource.'} `);
                        }
                        if (message) {
                            setErrorMessage(prev => `${prev}: ${message}`);
                        }
                        setIsErrored(true);
                    }),
            [recordID, refResource]
        );

        const handleClick = React.useCallback(
            async ev => {
                const userAllowed = shouldRender(permissions, refResource, 'can_read');

                ev.stopPropagation();

                if (!userAllowed) {
                    return notify(`Read access for ${refResource} required! Please contact supervisor.`, 'warning');
                }

                setShowPanel(true);
                if (!drawerRecord.id) {
                    await getShowPanelRecord();
                }
            },
            [drawerRecord.id, getShowPanelRecord, notify, permissions, refResource]
        );

        const handleCloseClick = React.useCallback(async ev => setShowPanel(false), []);

        if (!record) {
            return null;
        }

        if (!recordID && !drawerRecord.id) {
            return <TextField source={descriptionField} record={record} />;
        }

        return (
            <>
                <Button disableRipple className={classes.link} onClick={handleClick}>
                    <TextField source={descriptionField} record={record} />
                </Button>
                <DrawerPreview
                    position="right"
                    showPanel={showPanel}
                    handleCloseClick={handleCloseClick}
                    title={`${refResource
                        .toLowerCase()
                        .split('-')
                        .map(word => word[0].toUpperCase() + word.substr(1))
                        .join(' ')} #${recordID}`}
                >
                    {isErrored ? (
                        <span style={{ display: 'flex', flexDirection: 'row' }}>
                            {errorMessage && <PanToolIcon style={{ fill: 'red' }} />}
                            <Typography style={{ paddingLeft: '1em' }} color="error" variant="h6" gutterBottom>
                                {errorMessage}
                            </Typography>
                        </span>
                    ) : (
                        <>
                            {noRecordFound && (
                                <Typography variant="h6" align="center">
                                    No {refResource} found
                                </Typography>
                            )}
                            {(!previewRecord.id && !noRecordFound) || (loading && !noRecordFound) ? (
                                <LinearProgress className={classes.progress} />
                            ) : (
                                !noRecordFound && (
                                    <RecordShowFields
                                        {...sanitizeRestProps(rest)}
                                        resource={refResource}
                                        record={previewRecord}
                                    />
                                )
                            )}
                        </>
                    )}
                </DrawerPreview>
            </>
        );
    }
);

export default ReferenceDrawerField;
