import React from 'react';
import Echo from 'laravel-echo';
import apiClient from '../../../dataProvider/apiClient';

const AnyWindow = Window as any;

AnyWindow.Pusher = require('pusher-js');

AnyWindow.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
    authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
    authorizer: (channel, options) => ({
        authorize: (socketId, callback) => {
            apiClient
                .post('broadcasting/auth', {
                    socket_id: socketId,
                    channel_name: channel.name,
                })
                .then(response => {
                    callback(false, response.data);
                })
                .catch(error => {
                    callback(true, error);
                });
        },
    }),
});

const usePusherEvent = (channel, eventName, callback) => {
    const isMountedRef = React.useRef(false);

    React.useEffect(() => {
        isMountedRef.current = true;

        if (isMountedRef.current) {
            AnyWindow.Echo.private(channel).listen(eventName, data => {
                callback(data);
            });
        }

        return () => {
            AnyWindow.Echo.leave(channel);
            isMountedRef.current = false;
        };
    }, [channel, eventName, callback]);

    return {};
};

export default usePusherEvent;
