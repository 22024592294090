import setCustomFilters from './setCustomFilters';
import getRelationsFromFilters from './getRelationsFromFilters';

const mergeFieldArrays = (fields, fieldsOnFils) => {
    const spreadArrs =
        // eslint-disable-next-line no-nested-ternary
        fields && fields.length
            ? fieldsOnFils && fieldsOnFils.length
                ? [...fields, ...fieldsOnFils]
                : fields
            : fieldsOnFils && fieldsOnFils.length
            ? [...fieldsOnFils]
            : null;

    if (spreadArrs) {
        const uniqueArray = spreadArrs.reduce((newArray, item) => {
            if (newArray.includes(item)) {
                return newArray;
            }
            return [...newArray, item];
        }, []);
        return uniqueArray;
    }

    return spreadArrs;
};

const setQuery = params => {
    const { fields, filter, relations, join } = params;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { secondarySort, fieldSelectOnFilterLine, relationsOnFilterLine: relsOnFils, joinRelation } = filter;

    const query = {
        sort: secondarySort ? JSON.stringify([{ field, order }, ...secondarySort]) : JSON.stringify([{ field, order }]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    };

    const { processedRelations, shouldJoin } = getRelationsFromFilters(filter, relations, relsOnFils, joinRelation);

    const mergedFields = mergeFieldArrays(fields, fieldSelectOnFilterLine);

    if (mergedFields && mergedFields.length) {
        query.fields = JSON.stringify(mergedFields);
    }

    const customFilters = filter ? setCustomFilters(filter) : {};

    query.filters = JSON.stringify(customFilters);

    if (processedRelations) {
        query.relations = JSON.stringify(processedRelations);
    }

    if (join) {
        query.join = join;
    } else if (shouldJoin) {
        query.join = true;
    }

    return query;
};

export default setQuery;
