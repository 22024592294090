import React, { useState, useCallback, useRef, useMemo, memo } from 'react';
import { Labeled, useNotify, useRecordContext, useResourceContext } from 'react-admin';
import { Collapse, IconButton, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    RemoveCircleOutline as RemoveIcon,
    AddCircleOutline as AddIcon,
    PanTool as PanToolIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { requestGetByID, requestGetList } from '../../../../dataProvider/RestClient';
import { DialogTable } from '../index';
import { PreviewDrawerWrapper } from './index';

const itemsListingstyles = theme => ({
    progress: { marginTop: theme.spacing(2) },
    root: {
        flexGrow: 1,
    },
});

const useStyles = makeStyles(itemsListingstyles);

const useRefreshData = ({ type: relation, resourcePath, bypassFetch, ...props }) => {
    const [lineItems, setLineItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const resource = useResourceContext(props);
    const record = useRecordContext(props);
    const notify = useNotify();

    const hasItems = useMemo(
        () =>
            lineItems && Array.isArray(lineItems)
                ? 0 < lineItems.length
                : lineItems && 'object' === typeof lineItems && 0 < Object.keys(lineItems).length,
        [lineItems]
    );

    const refreshData = useCallback(async () => {
        if (relation && resource && record && record.id) {
            await requestGetByID(resource, record.id)
                .then(response => {
                    setLineItems(response.data[relation]);
                    setIsLoading(false);
                })
                .catch(error => notify(`Relation ${relation} not found for ${resource} #${record.id}`, 'warning'));
        } else if (resourcePath) {
            await requestGetList(resourcePath, 'id')
                .then(response => {
                    setLineItems(response.data);
                    setIsLoading(false);
                })
                .catch(error => {
                    notify(`Relation data not found`, 'warning');
                });
        }
    }, [notify, record, relation, resource, resourcePath]);

    useMemo(() => {
        if (bypassFetch) {
            return;
        }
        if (!relation && record) {
            setLineItems(record);
            return;
        }
        if (relation && record && record[relation]) {
            setLineItems(record[relation]);
        } else {
            setIsLoading(true);
            refreshData();
        }
    }, [bypassFetch, record, refreshData, relation]);

    return {
        lineItems,
        isLoading,
        hasItems,
    };
};

export const ItemsListingView = ({
    title = 'Line Items',
    relation,
    isLoading,
    hasItems,
    dataOverride,
    lineItems,
    isErrored,
    errorMessage,
    ...props
}) => {
    const [dataToPass, setDataToPass] = useState([]);
    const [isOpen, setIsOpen] = useState(true);

    const isMountedRef = useRef(false);

    const data = dataOverride && dataOverride.length ? dataOverride : lineItems;

    const hasData = React.useMemo(() => dataToPass && 0 < dataToPass.length, [dataToPass]);

    const classes = useStyles();

    const formatTypeOfLineItems = React.useCallback(
        items => {
            if (items && relation && items[0] && Object.keys(items[0]).includes(relation)) {
                if (items[0][relation] instanceof Array && items[0][relation].length) {
                    return items[0][relation];
                }
                if ('object' === typeof items[0][relation] && !!items[0][relation] && 0 !== items[0][relation].length) {
                    return [items[0][relation]];
                }
            } else if (
                items &&
                'object' === typeof items &&
                !(items instanceof Array) &&
                Object.keys(items).includes('0')
            ) {
                const convertedData = [];
                Object.values(items).forEach(itemsItem => {
                    convertedData.push(itemsItem);
                });
                return convertedData;
            } else if (
                items &&
                'object' === typeof items &&
                !(items instanceof Array) &&
                !Object.keys(items).includes('0')
            ) {
                return [items];
            } else if (items && items.length) {
                return items;
            }
        },
        [relation]
    );

    React.useMemo(() => {
        isMountedRef.current = true;

        if (isMountedRef.current) {
            const formattedData = formatTypeOfLineItems(data);

            setDataToPass(formattedData);
        }

        return () => {
            isMountedRef.current = false;
        };
    }, [data, formatTypeOfLineItems]);

    if (isLoading) {
        return <LinearProgress className={classes.progress} />;
    }

    const Listing = memo(() =>
        isErrored ? (
            <span style={{ display: 'flex', flexDirection: 'row' }}>
                {errorMessage && <PanToolIcon style={{ fill: 'red' }} />}
                <Typography style={{ paddingLeft: '1em' }} color="error" variant="h6" gutterBottom>
                    {`Error while trying to load ${title || relation || 'records'}${`: ${errorMessage}` || ''}`}
                </Typography>
            </span>
        ) : (
            <>
                <IconButton onClick={() => setIsOpen(!isOpen)} size="small">
                    {isOpen ? <RemoveIcon /> : <AddIcon />}
                </IconButton>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    {(dataOverride && dataOverride.length) || (hasItems && hasData) ? (
                        <PreviewDrawerWrapper data={dataToPass} {...props}>
                            <DialogTable />
                        </PreviewDrawerWrapper>
                    ) : (
                        <Typography variant="h6" gutterBottom align="center">
                            {`No ${title || relation || 'records'} found`}
                        </Typography>
                    )}
                </Collapse>
            </>
        )
    );

    return (
        <div className={classes.root}>
            {title ? (
                <Labeled label={title}>
                    <Listing />
                </Labeled>
            ) : (
                <Listing />
            )}
        </div>
    );
};

const ItemsListing = ({ dataOverride = [], ...props }) => {
    const refreshProps = useRefreshData(props);

    return <ItemsListingView dataOverride={dataOverride} {...props} {...refreshProps} />;
};

ItemsListing.propTypes = {
    columns: PropTypes.object,
    columnGroups: PropTypes.object,
    CustomAction: PropTypes.func,
    formatToCustom: PropTypes.array,
    formatToDate: PropTypes.array,
    formatToMoney: PropTypes.array,
    permissions: PropTypes.object,
    resourcePath: PropTypes.string,
    size: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    version: PropTypes.number,
};

export default ItemsListing;
