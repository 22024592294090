import React, { useState, useMemo, useCallback, useEffect } from 'react';
import lodash from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { IconButton, Popover, Badge, Tooltip } from '@material-ui/core';
import { NotificationsOutlined as NotificationsIcon } from '@material-ui/icons';
import { useNotify, useRedirect } from 'react-admin';
import { requestUpdate, requestGetNotificationsList, requestDownloadReport } from '../../../dataProvider/RestClient';
import NotificationList from './NotificationList';
import getRedirectData from './getRedirectData';
import { Notification } from '../../../Components/lib/customComponents/showComponents';
import { saveFile } from '../../../Components/lib/customComponents/showComponents/AttachmentPreviewField/helpers';
import { usePusherEvent } from '../../../Components/lib/hooks';

type TNotification = {
    id: number;
    is_active: boolean;
    is_read: boolean;
};

export const sortNotifications = values => lodash.sortBy(values, ['sent_at', 'priority']).reverse();

const NotificationBell = () => {
    const [notifications, setNotifications] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [lastUpdatedNotification, setLastUpdatedNotification] = useState(null);

    const history = useHistory();
    const { pathname } = useLocation();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleMenu = event => {
        const permissions = localStorage.getItem('permissions');
        if (!permissions) {
            return notify(`You do not have permission to view notifications`, 'warning');
        }
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    const id = open ? 'menu-notifications' : undefined;
    const userID = useMemo(
        () => (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') || '{}').id) || 0,
        []
    );
    const unreadNotifications = useMemo(
        () => notifications && notifications.filter((note: TNotification) => !note.is_read && note.is_active).length,
        [notifications]
    );
    const activeNotifications = useMemo(
        () => notifications && notifications.filter((note: TNotification) => note.is_active),
        [notifications]
    );

    const sanitizeNotification = record => {
        const notificationType = record.type ? record.type.split('Notifications\\')[1] : null;
        record.notification_type = notificationType;
        record.redirect = getRedirectData(notificationType, record.data);

        return record;
    };

    const dataParser = useCallback(
        record => {
            const notificationType = record.type ? record.type.split('Notifications\\')[1] : null;
            record.notification_type = notificationType;
            record.redirect = getRedirectData(notificationType, record.data);
            setNotifications(prevVals => sortNotifications([...prevVals, record]));
            notify(`You received a new notification: ${record.subject}`);
        },
        [notify]
    );

    usePusherEvent(
        `api.notifications.${userID}`,
        'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
        dataParser
    );

    const handleNotificationClick = useCallback(
        (updateType, notificationID, redirectData = null) => {
            const payload = { list: Array.isArray(notificationID) ? notificationID : [notificationID] };

            requestUpdate('user/notifications/mark-as', updateType, payload)
                .then(res => {
                    if (Array.isArray(notificationID)) {
                        const sanitizedNotifactions = res.data.map(notif => sanitizeNotification(notif));
                        setNotifications(sortNotifications(sanitizedNotifactions));
                    } else {
                        const updatedNotification = sanitizeNotification(res.data[0]);
                        const otherNotifications = notifications.filter(
                            (val: TNotification) => notificationID !== val.id
                        );

                        setNotifications(sortNotifications([...otherNotifications, updatedNotification]));
                    }

                    if ('inactive' === updateType) {
                        handleClose();
                        setLastUpdatedNotification(notificationID);
                    }

                    if (redirectData) {
                        const {
                            redirectType,
                            pathname: redirectPathname,
                            state: redirectState,
                            message,
                            fileName,
                        } = redirectData;

                        handleClose();

                        if ('download' === redirectType) {
                            requestDownloadReport(redirectPathname)
                                .then(blob => {
                                    const url = window.URL.createObjectURL(new Blob([blob]));
                                    saveFile(url, fileName);
                                    return notify(message, 'success');
                                })
                                .catch(err => notify('Error downloading report!', 'warning'));
                            return;
                        }

                        if (pathname === redirectPathname) {
                            history.replace({
                                pathname: redirectPathname,
                                state: redirectState,
                            });
                            notify(message, 'success');
                        } else {
                            history.push({
                                pathname: redirectPathname,
                                state: redirectState,
                            });
                            notify(message, 'success');
                        }
                    }
                })
                .catch(error => {
                    notify('Problem updating notification!', 'warning');
                });
        },
        [history, notifications, notify, pathname]
    );

    const handleUndo = useCallback(() => {
        handleNotificationClick('active', lastUpdatedNotification);
        notify('Notification restored.');
        setLastUpdatedNotification(null);
    }, [handleNotificationClick, lastUpdatedNotification, notify]);

    useEffect(() => {
        requestGetNotificationsList()
            .then(res => res.data.map(notif => sanitizeNotification(notif)))
            .then(notifs => setNotifications(sortNotifications(notifs)))
            .catch(error => {
                const { status } = error;
                if (status && 401 === status) {
                    localStorage.clear();
                    return redirect('/login');
                }
                notify('Error getting user notifications!', 'warning');
            });
    }, [notify, redirect, userID]);

    return (
        <div>
            <Notification
                handleUndo={handleUndo}
                undoable
                message="Notification removed."
                notification={lastUpdatedNotification}
            />
            <Tooltip title={`${unreadNotifications || 'No'} new notifications`}>
                <span>
                    <IconButton
                        disabled={0 === activeNotifications.length}
                        aria-label={id}
                        color="inherit"
                        onClick={handleMenu}
                    >
                        <Badge badgeContent={unreadNotifications} color="primary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </span>
            </Tooltip>
            {0 < activeNotifications.length && (
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <NotificationList
                        notifications={activeNotifications}
                        handleNotificationClick={handleNotificationClick}
                    />
                </Popover>
            )}
        </div>
    );
};

export default NotificationBell;
