import * as React from 'react';
import { FC, ReactElement } from 'react';
import ContentCreate from '@material-ui/icons/Create';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { linkToRecord, Record, Button, ButtonProps, useRecordContext } from 'react-admin';

interface Props {
    basePath?: string;
    record?: Record;
    icon?: ReactElement;
    redirectPath?: string;
}

type TEditButtonProps = Props & ButtonProps & MuiButtonProps;

const defaultIcon = <ContentCreate />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

const CustomEditButton: FC<TEditButtonProps> = ({
    basePath = '',
    label = 'ra.action.edit',
    icon = defaultIcon,
    redirectPath,
    ...rest
}) => {
    const record = useRecordContext(rest);
    return (
        <Button
            component={Link}
            to={`${linkToRecord(basePath, record && record.id)}${redirectPath ? `?redirect=${redirectPath}` : ''}`}
            label={label}
            onClick={stopPropagation}
            {...(rest as any)}
        >
            {icon}
        </Button>
    );
};

export default CustomEditButton;
