import moment from 'moment';

const dayNumbers = { MO: 1, TU: 2, WE: 3, TH: 4, FR: 5, SA: 6, SU: 7 };

const getTargetWeekdays = dates => {
    const weekdayNumbers = [];

    dates.forEach(day => {
        weekdayNumbers.push(dayNumbers[day]);
    });
    return weekdayNumbers.sort();
};

const getPosition = (daysOfWeek, daysOfMonth, frequency) => {
    if (!daysOfWeek) {
        return;
    }
    if (daysOfWeek && 0 === daysOfWeek.length && daysOfMonth.length) {
        return `on the selected days`;
    }

    if (daysOfWeek && daysOfWeek.length && 7 > daysOfWeek.length) {
        const formattedDays = daysOfWeek
            .sort((x, y) => {
                if (dayNumbers[x] < dayNumbers[y]) {
                    return -1;
                }
                if (dayNumbers[x] > dayNumbers[y]) {
                    return 1;
                }
                return 0;
            })
            // eslint-disable-next-line no-param-reassign
            .reduce((days, current) => (days += `, ${current}`));

        return `on ${formattedDays}`;
    }

    if (daysOfWeek && 7 === daysOfWeek.length && 'Daily' !== frequency) {
        return 'every day of';
    }

    return '';
};

const getFrequency = frequency => {
    switch (frequency) {
        case 'Daily':
            return 'days';

        case 'Weekly':
            return 'weeks';

        case 'Monthly':
            return 'months';

        default:
            return 'days';
    }
};

const getFirstRunDate = (frequency, values) => {
    const {
        started_at: startedAt,
        recurrence_month_days: recurrenceMonthDays,
        recurrence_days: recurrenceDays,
        schedule: recurrenceSchedule,
    } = values;

    const startingDate = moment(startedAt) || moment().format();
    let firstRun = startingDate;

    const getNextDateWithDay = () => {
        if (!recurrenceDays) {
            return;
        }

        const nextDayInCurrentWeek = getTargetWeekdays(recurrenceDays).find(num => num > startingDate.isoWeekday());

        if (nextDayInCurrentWeek) {
            firstRun = firstRun.add(nextDayInCurrentWeek - startingDate.isoWeekday(), 'days');
        } else {
            const nextDayInNextWeek = getTargetWeekdays(recurrenceDays).find(num => num <= startingDate.isoWeekday());
            firstRun = firstRun.add(7 - startingDate.isoWeekday() + nextDayInNextWeek, 'days');
        }
    };

    const getNextDayWithDates = () => {
        const nextDateInMonth = recurrenceMonthDays.find(num => num > startingDate.date());
        if (nextDateInMonth) {
            firstRun = firstRun.set({ date: nextDateInMonth });
        } else {
            const nextDateInNextMonth = recurrenceMonthDays.find(num => num <= startingDate.date());
            firstRun = firstRun.add(1, 'months').set({ date: nextDateInNextMonth });
        }
    };

    switch (frequency) {
        case 'day':
        case 'week':
            getNextDateWithDay();
            break;
        case 'month':
            getNextDayWithDates();
            break;
        default:
            return firstRun;
    }

    if (recurrenceSchedule) {
        const scheduledHour = moment(recurrenceSchedule).get('hour');
        const scheduledMinute = moment(recurrenceSchedule).get('minute');
        firstRun = firstRun.set({ hour: scheduledHour, minute: scheduledMinute });
    }

    return firstRun.format('MM/DD/YYYY, hh:mm A');
};

const getRecurrenceHelperText = values => {
    const {
        recurrence_interval: recurrenceInterval,
        recurrence_frequency: recurrenceFrequency,
        recurrence_month_days: recurrenceMonthDays,
        recurrence_days: recurrenceDays,
        schedule: recurrenceSchedule,
    } = values;
    // Throw warning if no days selected
    if (recurrenceDays && recurrenceMonthDays && 0 === recurrenceDays.length && 0 === recurrenceMonthDays.length) {
        return 'No report will run with given settings';
    }

    const interval = 1 < recurrenceInterval ? recurrenceInterval : '';
    const time = recurrenceSchedule ? `at ${moment(recurrenceSchedule).format('hh:mm A')}` : '';

    const position = getPosition(recurrenceDays, recurrenceMonthDays, recurrenceFrequency);
    const frequency = getFrequency(recurrenceFrequency);
    const formattedFrequency = interval ? frequency : frequency.slice(0, -1);
    const firstRun = getFirstRunDate(frequency.slice(0, -1), values);

    return `Report will generate ${position} every ${interval} ${formattedFrequency} ${time} (first run: ${firstRun})`;
};

export default getRecurrenceHelperText;
