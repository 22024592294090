import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useRecordContext } from 'react-admin';
import AttachmentsInput from './AttachmentsInput';
import BulkFormSelect from './BulkFormSelect';
import BulkUpdateForm from './BulkUpdateForm';
import CustomToolbar from './CustomToolbar';
import DateTimeInput from './CustomDateTimeInput';
import DialogForm from './DialogForm';
import LinesFormAccordionWrapper from './LinesFormAccordionWrapper';
import MaskedNumberInput from './MaskedNumberInput';
import MaskedTextInput from './MaskedTextInput';
import Progress from './Progress';
import QueryWrapper from './QueryWrapper';
import SaveAndSendEmailButton from './SaveAndSendEmailButton';
import SimpleFormWrapper from './SimpleFormWrapper';
import TransferInput from './TransferInput';
import useLineItemsForm from './useLineItemsForm';
import { getUserSettings } from '../../helpers';

const ResponsiveForm = ({ simpleForm, tabbedForm }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const tabbedViewSelected = 'tabbed' === getUserSettings('form_format', 'simple');

    if (!simpleForm || isSmall || tabbedViewSelected) {
        return React.cloneElement(tabbedForm);
    }

    return React.cloneElement(simpleForm);
};

const LocationField = ({ source, ...props }) => {
    const record = useRecordContext(props);
    return (
        <span>
            <a
                target="_blank"
                href={`https://www.google.com/maps/search/?api=1&query=${record.latitude},${record.longitude}`}
                rel="noreferrer"
            >
                Click to show location on map
            </a>
        </span>
    );
};

const TestWrapper = ({ children, testID, ...props }) => {
    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, {
            ...props,
        })
    );
    return <div data-cy={testID || props.source}>{childrenWithProps}</div>;
};

export {
    LocationField,
    AttachmentsInput,
    BulkUpdateForm,
    BulkFormSelect,
    DateTimeInput,
    DialogForm,
    CustomToolbar,
    LinesFormAccordionWrapper,
    MaskedNumberInput,
    MaskedTextInput,
    Progress,
    QueryWrapper,
    SaveAndSendEmailButton,
    SimpleFormWrapper,
    TestWrapper,
    TransferInput,
    useLineItemsForm,
    ResponsiveForm,
};
