import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useInput, FieldTitle } from 'react-admin';
import MaskedInput from 'react-text-mask';

const sanitizeRestProps = ({
    addLabel,
    allowEmpty,
    alwaysOn,
    basePath,
    choices,
    classNamInputWithOptionsPropse,
    componenInputWithOptionsPropst,
    crudGetMInputWithOptionsPropsatching,
    crudGetOInputWithOptionsPropsne,
    defaultValue,
    filter,
    filterToQuery,
    formClassName,
    initializeForm,
    input,
    isRequired,
    label,
    limitChoicesToValue,
    loaded,
    locale,
    meta,
    onChange,
    options,
    optionValue,
    optionText,
    perPage,
    record,
    reference,
    resource,
    setFilter,
    setPagination,
    setSort,
    sort,
    source,
    textAlign,
    translate,
    translateChoice,
    validate,
    validation,
    ...rest
}: any) => rest;

const TextMaskCustom = ({ inputRef, mask, ...props }) => (
    <MaskedInput
        ref={ref => inputRef(ref ? ref.inputElement : null)}
        mask={mask}
        showMask
        keepCharPositions
        {...props}
    />
);

const MaskedTextInput = ({
    mask,
    className,
    disabled,
    label,
    options,
    source,
    suffix,
    prefix,
    step,
    resource,
    formatter,
    ...props
}) => {
    const {
        input: { onChange, onBlur, ...input },
        isRequired,
        meta: { touched, error },
    } = useInput({ ...props, source });

    const handleBlur = useCallback(event => onBlur(event.target.value), [onBlur]);
    const handleChange = useCallback(event => onChange(event.target.value), [onChange]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const MaskComponent = useCallback(rest => TextMaskCustom({ mask, ...rest }), []);

    return (
        <TextField
            disabled={disabled}
            error={!!(touched && error)}
            helperText={touched && error}
            step={step}
            label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
            className={className}
            {...options}
            {...sanitizeRestProps(props)}
            {...input}
            margin="dense"
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
                inputComponent: MaskComponent,
                startAdornment: <InputAdornment position="start">{prefix}</InputAdornment>,
                endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
            }}
        />
    );
};

MaskedTextInput.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    formatter: PropTypes.string,
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    meta: PropTypes.object,
    name: PropTypes.string,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textAlign: PropTypes.string,
    thousandSeparator: PropTypes.bool,
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
};

MaskedTextInput.defaultProps = {
    disabled: false,
    formatter: 'number',
    options: {},
    prefix: '',
    step: 'any',
    suffix: '',
    textAlign: 'right',
};

export default MaskedTextInput;
