import * as React from 'react';
import { memo, FC, ElementType } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { sanitizeFieldRestProps, useRecordContext, PublicFieldProps, InjectedFieldProps } from 'react-admin';
import { TableCellProps } from '@material-ui/core/TableCell';
import { phoneNumberFormat } from '../../helpers';

type TextAlign = TableCellProps['align'];
type SortOrder = 'ASC' | 'DESC';
export interface PhoneNumberFieldProps extends PublicFieldProps, InjectedFieldProps, TypographyProps {
    // TypographyProps do not expose the component props, see https://github.com/mui-org/material-ui/issues/19512
    component?: ElementType<any>;
}

const fieldPropTypes = {
    addLabel: PropTypes.bool,
    sortBy: PropTypes.string,
    sortByOrder: PropTypes.oneOf<SortOrder>(['ASC', 'DESC']),
    source: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    sortable: PropTypes.bool,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    textAlign: PropTypes.oneOf<TextAlign>(['inherit', 'left', 'center', 'right', 'justify']),
    emptyText: PropTypes.string,
};

const PhoneNumberField: FC<PhoneNumberFieldProps> = memo(props => {
    const { className, source, emptyText, ...rest } = props;
    const record = useRecordContext(props);
    const value = get(record, source);

    const phoneNumberFormattedValue = phoneNumberFormat(value);

    return (
        <Typography
            key={`phone.${value}`}
            component="span"
            variant="body2"
            className={className}
            {...sanitizeFieldRestProps(rest)}
        >
            {null != phoneNumberFormattedValue && 'string' !== typeof phoneNumberFormattedValue
                ? JSON.stringify(phoneNumberFormattedValue)
                : phoneNumberFormattedValue || emptyText}
        </Typography>
    );
});

// what? TypeScript loses the displayName if we don't set it explicitly
PhoneNumberField.displayName = 'PhoneNumberField';

PhoneNumberField.defaultProps = {
    addLabel: true,
};

PhoneNumberField.propTypes = {
    // @ts-ignore
    ...Typography.propTypes,
    ...fieldPropTypes,
};

export default PhoneNumberField;
