import React from 'react';
import { useNotify, useRedirect, useResourceContext } from 'react-admin';
import { requestGetByID, requestUpdateCurrentUser } from '../../../../dataProvider/RestClient';

type TView = {
    columns: string[];
    filters: object;
    sort?: object;
    favorite?: boolean;
};

export type TColumn = {
    name?: string;
    source: string;
    label?: string;
    type?: string;
    active?: boolean;
    enabled?: boolean;
};

type TgetColumnData = ({
    data,
    optionValue,
    optionText,
    initialCols,
    additionalColNames,
}: {
    data: any;
    optionValue?: string | undefined;
    optionText?: string | undefined;
    initialCols?: string[];
    additionalColNames?: string[] | null;
}) => any;

const sortByTemplateColumnIndex = (a, b, defaultArray) => {
    if (-1 === defaultArray.indexOf(a.source) || !a.enabled) {
        return 1;
    }
    if (-1 === defaultArray.indexOf(b.source) || !b.enabled) {
        return -1;
    }

    return defaultArray.indexOf(a.source) - defaultArray.indexOf(b.source);
};

const sortAndActivateColumnsByArray = (columnsToSort, templateArray) =>
    columnsToSort
        .map((col: TColumn) => ({ ...col, active: templateArray.includes(col.source) }))
        .sort((a, b) => sortByTemplateColumnIndex(a, b, templateArray));

const getColumnData: TgetColumnData = ({
    data,
    optionValue = 'name',
    optionText = 'label',
    initialCols,
    additionalColNames,
}) =>
    data.map((field: TColumn) => {
        const enabled = additionalColNames && additionalColNames.includes(field[optionValue]) ? true : field.enabled;
        return {
            source: field[optionValue],
            label: field[optionText] || field[optionValue],
            type: field.type,
            enabled,
            active: initialCols && initialCols.includes(field[optionValue]) && enabled,
        };
    });

const useViewController = props => {
    const resource = useResourceContext();

    const preferences = JSON.parse(localStorage.getItem('preferences') || '{}');
    const resourcePreferences = preferences && preferences[resource];
    const { additionalColumns, defaultColumns } = props;

    const [preferenceState, setPreferenceState] = React.useState(resourcePreferences);
    const [currentViewName, setCurrentViewName] = React.useState<string>('System Default');
    const [favoriteViewName, setFavoriteViewName] = React.useState<string | null>(null);
    const [defaultListViewColumns, setDefaultListViewColumns] = React.useState<Array<TColumn>>([]);
    const [currentViewColumns, setCurrentViewColumns] = React.useState<Array<TColumn>>([]);
    const [columns, setColumns] = React.useState<Array<TColumn>>([]);
    const [schema, setSchema] = React.useState({});

    const notify = useNotify();
    const redirect = useRedirect();

    React.useEffect(() => {
        requestGetByID(resource, 'schema')
            .then((res: any) => {
                const { fields } = res.data;

                const consolidatedFields =
                    additionalColumns && additionalColumns.length ? [...fields, ...additionalColumns] : fields;

                const additionalColNames = (additionalColumns && additionalColumns.map(col => col.name)) || null;

                let initialCols = defaultColumns;

                const listViewDefaultColumns = getColumnData({
                    data: consolidatedFields,
                    initialCols,
                    additionalColNames,
                });

                const sortedListViewDefaults = [...listViewDefaultColumns].sort((a, b) =>
                    sortByTemplateColumnIndex(a, b, initialCols)
                );

                setDefaultListViewColumns(sortedListViewDefaults);

                if (resourcePreferences) {
                    const favView = Object.entries(resourcePreferences).find(
                        ([viewName, view]: [string, any]) => view.favorite
                    );
                    if (favView) {
                        const [name, view]: [string, any] = favView;
                        setCurrentViewName(name);
                        setFavoriteViewName(name);
                        const { columns: favColumns } = view;
                        initialCols = favColumns;
                    }
                }

                const mappedColumns = [...listViewDefaultColumns]
                    .map(col => ({
                        ...col,
                        active: initialCols && initialCols.includes(col.source) && col.enabled,
                    }))
                    .sort((a, b) => sortByTemplateColumnIndex(a, b, initialCols));

                setCurrentViewColumns(mappedColumns);
                setColumns(mappedColumns);
                setSchema(res.data);
            })
            .catch((err: any) => {
                const { status } = err;
                if (403 === status) {
                    return redirect(
                        '/unauthorized',
                        '',
                        undefined,
                        {},
                        { additionalMessage: `You are not authorized to view ${resource}.` }
                    );
                }
                notify(`Problem getting schema for ${resource}`, 'warning');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additionalColumns, defaultColumns, notify, redirect, resource]);

    const selectView = React.useCallback(
        selectViewNameArg => {
            if (resourcePreferences && resourcePreferences[selectViewNameArg]) {
                const viewColumns = resourcePreferences[selectViewNameArg].columns;
                setCurrentViewName(selectViewNameArg);
                const sortedColumns = sortAndActivateColumnsByArray(columns, viewColumns);
                setCurrentViewColumns(sortedColumns);
                setColumns(sortedColumns);
            }
        },
        [columns, resourcePreferences]
    );

    const saveView = React.useCallback(
        (saveViewNameArg, filters, sort, isFavorite = false) => {
            const filteredColumnSources = [...columns].reduce(
                (newArr: string[], col: TColumn) => (col.active ? [...newArr, col.source] : newArr),
                []
            );

            const payloadObj: { filters: object; columns: string[]; sort: object; favorite?: boolean } = {
                filters,
                columns: filteredColumnSources,
                sort,
            };

            if (isFavorite) {
                payloadObj.favorite = isFavorite;
            }

            const getNewPreferences = () => {
                if (preferences) {
                    return resourcePreferences
                        ? {
                              ...preferences,
                              [resource]: {
                                  ...resourcePreferences,
                                  [saveViewNameArg]: payloadObj,
                              },
                          }
                        : {
                              ...preferences,
                              [resource]: {
                                  [saveViewNameArg]: payloadObj,
                              },
                          };
                }
                if (!preferences) {
                    return {
                        [resource]: {
                            [saveViewNameArg]: payloadObj,
                        },
                    };
                }
            };

            const newPreferences = getNewPreferences();

            const payload = {
                meta: {
                    preferences: getNewPreferences(),
                },
            };

            requestUpdateCurrentUser(payload)
                .then(res => {
                    localStorage.setItem('preferences', JSON.stringify(newPreferences));
                    setPreferenceState(newPreferences[resource]);
                    setCurrentViewName(saveViewNameArg);
                    const sortedColumns = sortAndActivateColumnsByArray(columns, filteredColumnSources);
                    setCurrentViewColumns(sortedColumns);
                    notify(`List view "${saveViewNameArg}" successfully saved`, 'success');
                })
                .catch(error => {
                    notify('Problem saving list view!', 'warning');
                });
        },
        [columns, notify, preferences, resource, resourcePreferences]
    );

    const favoriteView = React.useCallback(
        (favoriteViewNameArg, currentResource, unfavorite = false) => {
            const processedViews = {};
            Object.keys(resourcePreferences).map(item => {
                const newItem = { ...resourcePreferences[item] };
                delete newItem.favorite;
                if (item === favoriteViewNameArg && !unfavorite) {
                    newItem.favorite = true;
                }
                return (processedViews[item] = newItem);
            });

            const newPreferences = {
                ...preferences,
                [currentResource]: processedViews,
            };

            const payload = {
                meta: {
                    preferences: newPreferences,
                },
            };

            requestUpdateCurrentUser(payload)
                .then(res => {
                    localStorage.setItem('preferences', JSON.stringify(newPreferences));
                    setPreferenceState(processedViews);
                    if (unfavorite) {
                        setFavoriteViewName(null);
                    } else {
                        setFavoriteViewName(favoriteViewNameArg);
                    }
                    notify(
                        `List view "${favoriteViewNameArg}" ${unfavorite ? 'unfavorited' : 'favorited'}`,
                        unfavorite ? 'info' : 'success'
                    );
                })
                .catch(error => {
                    notify('Problem setting favorite list view!', 'warning');
                });
        },
        [notify, preferences, resourcePreferences]
    );

    const deleteView = React.useCallback(
        (deleteViewNameArg, currentResource) => {
            const filteredViews = {};
            if (preferences && resourcePreferences) {
                Object.keys(resourcePreferences).forEach(view => {
                    if (view !== deleteViewNameArg && preferences && resourcePreferences[view]) {
                        return (filteredViews[view] = resourcePreferences[view]);
                    }
                });
            }

            const newPreferences = { ...preferences, [currentResource]: filteredViews };

            const payload = {
                meta: {
                    preferences: newPreferences,
                },
            };

            requestUpdateCurrentUser(payload)
                .then(res => {
                    localStorage.setItem('preferences', JSON.stringify(newPreferences));
                    setPreferenceState(filteredViews);
                    if (deleteViewNameArg === currentViewName) {
                        setCurrentViewName('System Default');
                    }
                    notify(`List view "${deleteViewNameArg}" deleted`);
                })
                .catch(error => {
                    notify('Problem deleting list view!', 'warning');
                });
        },
        [currentViewName, notify, preferences, resourcePreferences]
    );

    return {
        preferences: preferenceState,
        currentViewName,
        favoriteViewName,
        selectView,
        favoriteView,
        saveView,
        deleteView,
        schema,
        columns,
        defaultListViewColumns,
        currentViewColumns,
        additionalColumns,
        defaultColumns,
        setColumns,
        getColumnData,
    };
};

type ViewContextType = {
    preferences: { [x: string]: TView }[] | null;
    currentViewName: string;
    favoriteViewName: string | null;
    selectView: (selectViewNameArg: string) => void;
    saveView: (saveViewNameArg: string, filters: object, sort?: object, isFavorite?: boolean) => void;
    favoriteView: (favoriteViewNameArg: string, resource: string, unfavorite?: boolean) => void;
    deleteView: (deleteViewNameArg: string, resource: string) => void;
    schema: {
        fields?: TColumn[];
        relations?: any[];
    };
    columns: TColumn[];
    defaultListViewColumns: TColumn[];
    currentViewColumns: TColumn[];
    additionalColumns: TColumn[] | null;
    defaultColumns: string[];
    setColumns: React.Dispatch<React.SetStateAction<TColumn[]>>;
    getColumnData: TgetColumnData;
};

const ViewContext = React.createContext<ViewContextType | undefined>(undefined);

ViewContext.displayName = 'ViewContext';

const ViewContextProvider = ({ children, value }) => (
    <ViewContext.Provider value={value}>{children}</ViewContext.Provider>
);

const useViewContext = (props?: any) => {
    const context = React.useContext(ViewContext);
    return context;
};

export { sortByTemplateColumnIndex, useViewController, ViewContext, useViewContext, ViewContextProvider };
