import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useInput, FieldTitle } from 'react-admin';
import NumberFormat from 'react-number-format';
import InputAdornment from '@material-ui/core/InputAdornment';

const sanitizeRestProps = ({
    addLabel,
    allowEmpty,
    alwaysOn,
    basePath,
    choices,
    classNamInputWithOptionsPropse,
    componenInputWithOptionsPropst,
    crudGetMInputWithOptionsPropsatching,
    crudGetOInputWithOptionsPropsne,
    defaultValue,
    filter,
    filterToQuery,
    format,
    formClassName,
    initializeForm,
    input,
    isRequired,
    label,
    limitChoicesToValue,
    loaded,
    locale,
    meta,
    onChange,
    options,
    optionValue,
    optionText,
    perPage,
    record,
    reference,
    resource,
    setFilter,
    setPagination,
    setSort,
    sort,
    source,
    textAlign,
    translate,
    translateChoice,
    validation,
    ...rest
}: any) => rest;

const MaskedNumberInput = ({
    className,
    disabled,
    label,
    options,
    step,
    source,
    resource,
    suffix,
    prefix,
    decimalScale,
    fixedDecimalScale,
    thousandSeparator,
    helperText: helperTextOverride,
    ...props
}) => {
    const {
        input: { onChange, onBlur, ...input },
        isRequired,
        meta: { touched, error },
        // id,
    } = useInput({ ...props, source });

    const handleBlur = event => onBlur(event.target.value);

    const handleChange = event => onChange(event.target.value);

    const CustomFormatter = useCallback(
        ({ inputRef, onChange: formatterOnChange, onBlur: formatterOnBlur, ...other }) => (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={values => {
                    if ('undefined' !== typeof values.floatValue) {
                        formatterOnChange({
                            target: {
                                value: values.floatValue,
                            },
                        });
                    }
                }}
                thousandSeparator={thousandSeparator}
                fixedDecimalScale={fixedDecimalScale}
                decimalScale={false === fixedDecimalScale ? 0 : decimalScale}
            />
        ),
        [decimalScale, fixedDecimalScale, thousandSeparator]
    );

    return (
        <TextField
            disabled={disabled}
            error={!!(touched && error)}
            helperText={helperTextOverride || (touched && error)}
            step={step}
            label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
            className={className}
            {...options}
            {...sanitizeRestProps(props)}
            {...input}
            onBlur={handleBlur}
            onChange={handleChange}
            margin="dense"
            variant="filled"
            InputProps={{
                inputComponent: CustomFormatter,
                startAdornment: <InputAdornment position="start">{prefix}</InputAdornment>,
                endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
            }}
        />
    );
};

MaskedNumberInput.propTypes = {
    className: PropTypes.string,
    decimalScale: PropTypes.number,
    disabled: PropTypes.bool,
    fixedDecimalScale: PropTypes.bool,
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    name: PropTypes.string,
    options: PropTypes.object,
    prefix: PropTypes.string,
    resource: PropTypes.string,
    source: PropTypes.string,
    suffix: PropTypes.string,
    step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textAlign: PropTypes.string,
    thousandSeparator: PropTypes.bool,
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
};

MaskedNumberInput.defaultProps = {
    decimalScale: 2,
    disabled: false,
    fixedDecimalScale: true,
    options: {},
    prefix: '',
    step: 'any',
    suffix: '',
    textAlign: 'right',
    thousandSeparator: true,
};

export default MaskedNumberInput;
