import React from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails,
    Typography,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles((theme: Theme) => ({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
        '&:nth-child(odd)': {
            '& .MuiAccordionSummary-root': {
                backgroundColor: red[500],
            },
        },
        '&:nth-child(even)': {
            '& .MuiAccordionSummary-root': {
                backgroundColor: red.A100,
            },
        },
    },
    expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
    root: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
}))(MuiAccordionSummary);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            // @ts-ignore
            fontWeight: theme.typography.fontWeightRegular,
        },
    })
);

const LinesFormAccordionWrapper = ({ children, ...props }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {React.Children.map(children, child => (
                <Accordion defaultExpanded style={child.props.style}>
                    <AccordionSummary color="primary" expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" gutterBottom>
                            {child.props.title || child.props.label}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {React.cloneElement(child, {
                            label: '',
                            ...props,
                        })}
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default LinesFormAccordionWrapper;
