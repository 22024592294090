import React from 'react';
import PropTypes from 'prop-types';
import { EditButton, useRecordContext, usePermissions } from 'react-admin';
import { shouldRender } from '../../helpers';

type TVariableEditButton = {
    userAllowed?: any;
    recordAllowed?: any;
    resourceOverride?: any;
    resource?: string;
};

const TypedEditButton = EditButton as any;

const VariableEditButton: React.FC<TVariableEditButton> = ({
    userAllowed,
    recordAllowed,
    resourceOverride,
    ...props
}) => {
    const record = useRecordContext(props);
    const { permissions } = usePermissions();
    const resource = resourceOverride || props.resource;
    const validRecord = 'function' === typeof recordAllowed ? true === recordAllowed(record) : recordAllowed;

    const isAllowed = React.useMemo(
        () => validRecord && true === userAllowed(permissions, resource),
        [permissions, resource, userAllowed, validRecord]
    );

    return isAllowed ? <TypedEditButton resource={resource} {...props} /> : null;
};

VariableEditButton.propTypes = {
    userAllowed: PropTypes.func,
    recordAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

VariableEditButton.defaultProps = {
    userAllowed: (permissions, resource) => shouldRender(permissions, resource, 'can_update'),
    recordAllowed: true,
};

export default VariableEditButton;
