import * as React from 'react';
import { useState, useCallback, useRef, ReactNode, HtmlHTMLAttributes, useContext } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import ContentFilter from '@material-ui/icons/FilterList';
import classnames from 'classnames';
import lodashGet from 'lodash/get';
import { Button, ClassesOverride, FilterContext, useListContext, useResourceContext } from 'react-admin';
import { FilterButtonMenuItem } from './CustomFilterButtonMenuItem';

// import { FilterButtonMenuItem } from './FilterButtonMenuItem';
// import Button from '../../button/Button';
// import { ClassesOverride } from '../../types';
// import { FilterContext } from '../FilterContext';

const sanitizeRestProps = ({ displayedFilters = null, filterValues = null, showFilter = null, ...rest }: any) => rest;

const useStyles = makeStyles(
    {
        root: { display: 'inline-block' },
    },
    { name: 'RaFilterButton' }
);

export interface FilterButtonProps extends HtmlHTMLAttributes<HTMLDivElement> {
    classes?: ClassesOverride<typeof useStyles>;
    className?: string;
    resource?: string;
    filterValues?: any;
    showFilter?: (filterName: string, defaultValue: any) => void;
    displayedFilters?: any;
    filters?: ReactNode[];
}

const FilterButton = (props: FilterButtonProps): JSX.Element | null => {
    const { filters: filtersProp, classes: classesOverride, className, ...rest } = props;
    const filters = useContext(FilterContext) || filtersProp;
    const resource = useResourceContext(props);
    const { displayedFilters = {}, filterValues, showFilter } = useListContext(props);
    const [open, setOpen] = useState(false);
    const anchorEl = useRef();
    const classes = useStyles(props);

    const hiddenFilters = filters.filter(
        (filterElement: JSX.Element) =>
            !filterElement.props.alwaysOn &&
            !displayedFilters[filterElement.props.source] &&
            'undefined' === typeof lodashGet(filterValues, filterElement.props.source)
    );

    const handleClickButton = useCallback(
        event => {
            // This prevents ghost click.
            event.preventDefault();
            setOpen(true);
            anchorEl.current = event.currentTarget;
        },
        [anchorEl, setOpen]
    );

    const handleRequestClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleShow = useCallback(
        ({ source, defaultValue }) => {
            showFilter(source, defaultValue);
            setOpen(false);
        },
        [showFilter, setOpen]
    );

    if (0 === hiddenFilters.length) return null;
    return (
        <div className={classnames(classes.root, className)} {...sanitizeRestProps(rest)}>
            <Button
                className="add-filter"
                label="ra.action.add_filter"
                aria-haspopup="true"
                onClick={handleClickButton}
            >
                <ContentFilter />
            </Button>
            <Menu open={open} anchorEl={anchorEl.current} onClose={handleRequestClose}>
                {hiddenFilters.map((filterElement: JSX.Element, index) => (
                    <FilterButtonMenuItem
                        key={filterElement.props.source}
                        filter={filterElement}
                        resource={resource}
                        onShow={handleShow}
                        autoFocus={0 === index}
                        disabled={filterElement.props.disabled}
                    />
                ))}
            </Menu>
        </div>
    );
};

FilterButton.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.node),
    displayedFilters: PropTypes.object,
    filterValues: PropTypes.object,
    showFilter: PropTypes.func,
    classes: PropTypes.object,
    className: PropTypes.string,
};

export default FilterButton;
