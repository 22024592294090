import React, { useState, useEffect, useCallback } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles(
    (theme: Theme) => ({
        error: {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.error.contrastText,
        },
        warning: {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.contrastText,
        },
        undo: {
            color: theme.palette.primary.light,
        },
    }),
    { name: 'RaNotification' }
);

const Notification: ({
    message,
    handleUndo,
    undoable,
    undoButtonText,
    type,
    autoHideDuration,
    notification,
    ...props
}: {
    [x: string]: any;
    message: any;
    handleUndo?: () => void;
    undoable?: boolean;
    undoButtonText?: string;
    type?: string;
    autoHideDuration?: number;
    notification: any;
}) => JSX.Element = ({
    message,
    handleUndo,
    undoable = false,
    undoButtonText = 'Undo',
    type = 'info',
    autoHideDuration = 4000,
    notification,
    ...props
}) => {
    const { classes: classesOverride, className, ...rest } = props;
    const [open, setOpen] = useState(true);
    const styles = useStyles(props);

    useEffect(() => {
        setOpen(!!notification);
    }, [notification]);

    const handleRequestClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <Snackbar
            open={open}
            message={message}
            autoHideDuration={autoHideDuration}
            disableWindowBlurListener={undoable}
            onClose={handleRequestClose}
            ContentProps={{
                className: classnames(styles[type], className),
            }}
            action={
                undoable ? (
                    <Button color="primary" className={styles.undo} size="small" onClick={handleUndo}>
                        {undoButtonText}
                    </Button>
                ) : null
            }
            {...rest}
        />
    );
};

export default Notification;
