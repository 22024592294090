import React from 'react';
import { Button, useMediaQuery } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        margin: theme.spacing(),
    },
    iconSmall: {
        marginRight: theme.spacing(),
        fontSize: 20,
    },
});

const PrintButton = withStyles(styles)(({ classes, onClick, onBeforePrint = null }) => {
    const isSmall = useMediaQuery('(max-width:600px)');
    return (
        <Button
            color="primary"
            size="small"
            onClick={async () => {
                if ('function' === typeof onBeforePrint) {
                    await onBeforePrint();
                }
                onClick();
            }}
        >
            <Print className={isSmall ? classes.button : classes.iconSmall} />
            {isSmall ? '' : 'Print'}
        </Button>
    );
});

export default PrintButton;
