import React from 'react';
import {
    TextField,
    EmailField,
    Show,
    BooleanField,
    TabbedShowLayout,
    Tab,
    usePermissions,
    useRedirect,
} from 'react-admin';
import { FilteredFieldsRenderer, ExpandableAuditsTable } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';
import SwitchToUserButton from './SwitchToUserButton';
import DebugLinkButton from './DebugLinkButton';
import { userAllowed, userCanRead, UserRoles, UserSettingsTable } from './index';

const getUserFields = () => [
    <TextField source="id" />,
    <TextField source="name" />,
    <EmailField source="email" />,
    <BooleanField label="Is Active" source="is_active" />,
    <BooleanField label="Is Super User" source="is_super_user" />,
];

export const UserShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getUserFields} {...props}>
        <UserSettingsTable {...props} />
        <UserRoles {...props} />
        <ExpandableAuditsTable {...props} />
    </FilteredFieldsRenderer>
);

const UsersTabbedShow = ({ actions, ...props }) => (
    <Show actions={actions} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getUserFields} isTabbedShow {...props} />
            <Tab label="Settings" path="settings">
                <UserSettingsTable {...props} />
            </Tab>
            <Tab label="Roles" path="roles">
                <UserRoles {...props} />
            </Tab>
            <Tab label="Audits" path="audits">
                <ExpandableAuditsTable {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const UsersSimpleShow = ({ actions, ...props }) => (
    <Show actions={actions} {...props}>
        <UserShowFields {...props} />
    </Show>
);

const UsersShow = props => {
    const redirect = useRedirect();
    const { permissions, loading } = usePermissions();

    const { id } = props;
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const isUserProfile = parseInt(id) === currentUser.id;
    const canRead = React.useMemo(() => userCanRead(permissions), [permissions]);

    const userAllowedOrOwnRecord = permissionsProp => {
        if (isUserProfile) {
            return true;
        }
        return userAllowed(permissionsProp);
    };

    React.useEffect(() => {
        if (!canRead && !isUserProfile && !loading) {
            redirect('/unauthorized');
        }
    }, [canRead, isUserProfile, loading, redirect]);

    const actions = (
        <CustomActionsShowView userAllowed={userAllowedOrOwnRecord} hasList={canRead} {...props}>
            <DebugLinkButton isUserProfile={isUserProfile} {...props} />
            {/* <SwitchToUserButton id={props.id} /> */}
        </CustomActionsShowView>
    );

    if ('simple' === getUserSettings('show_view_format', 'tabbed')) {
        return <UsersSimpleShow actions={actions} {...props} />;
    }

    return <UsersTabbedShow actions={actions} {...props} />;
};

export default UsersShow;
